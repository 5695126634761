import React from 'react';
import { toastSuccess, toastError } from '../services/toastService';
import logger from '../services/logService';
import Joi from 'joi-browser';
import Form from './common/form';
import PageTitle from './common/PageTitle';
import { getAdminReportmailing, updateAdminReportmailing, saveAdminReportmailing } from '../services/reportmailingService';
import Translation from '../services/translationService';

class AdminReportmailingForm extends Form {
    state = {
        data: { name:'', report_id: '', period:'', html_message:'', text_message:'', subject_message:'', filename:'', mail_addresses:'', send_weekdays:[], send_monthdays:[], branches: [], send_hour_tz: '', timezone: '', is_active: true },
        id: '',
        branchesall: [],
        weekdays: [],
        monthdays: [],
        reports: [],
        periods: [],
        timezones: [],
        hours: [],
        errors: {}
    }

    schema = {
        name: Joi.string().min(1).max(255).required().label('Naam'),
        report_id: Joi.string().required().label('Rapport'),
        period: Joi.string().min(1).max(255).required().label('Periode'),
        html_message: Joi.string().max(10000).label('HTML bericht'),
        text_message: Joi.string().max(10000).label('Tekst bericht'),
        subject_message: Joi.string().max(255).label('Onderwerp'),
        filename: Joi.string().max(255).label('Bestandsnaam'),
        mail_addresses: Joi.string().max(10000).label('E-mailadressen'),
        send_weekdays: Joi.array().items(Joi.number().integer()).label('Weekdagen'),
        send_monthdays: Joi.array().items(Joi.number().integer()).label('Dagen in de maand'),
        send_hour_tz: Joi.number().integer().label('Uur'),
        timezone: Joi.string().label('Timezone'),
        branches: Joi.array().items(Joi.string()).label('Branches'),
        is_active: Joi.boolean().required().label('Active')
    };
    
    async componentDidMount() {
        const rmId = window.location.href.split('managereportmailings/')[1];
        
        try {
            const { data } = await getAdminReportmailing(rmId);
            if (rmId === "new") {
                this.setState({ branchesall: data.branchesall, reports: data.reports, periods: data.periods, weekdays: data.weekdays, monthdays: data.monthdays, timezones: data.timezones, hours: data.hours });
            } else {
                this.setState({ id: rmId, branchesall: data.branchesall, reports: data.reports, periods: data.periods, weekdays: data.weekdays, monthdays: data.monthdays, timezones: data.timezones, hours: data.hours, data: { name:data.name, report_id: data.report_id, period: data.period, html_message: data.html_message, text_message: data.text_message, subject_message: data.subject_message, filename: data.filename, mail_addresses: data.mail_addresses, send_weekdays: data.send_weekdays, send_monthdays: data.send_monthdays, branches: data.branches, send_hour_tz: data.send_hour_tz, timezone: data.timezone, is_active: data.is_active } });
            }
        }
        catch (ex) {
            if (ex.response) toastError(ex.response.data);
        }
    }

    doSubmit = async () => {
        if (window.location.href.split('managereportmailings/')[1] === 'new') {
            try {
                await saveAdminReportmailing(this.state);
                window.location = "/managereportmailings";
            }
            catch (ex) {
                if (ex.response) toastError(ex.response.data);
                logger.log(ex);
            }
        } else {
            try {
                await updateAdminReportmailing(this.state);
                toastSuccess(<Translation>saved-success</Translation>);
            }
            catch (ex) {
                if (ex.response) toastError(ex.response.data);
                logger.log(ex);
            }
        }
    }

    render() {
        return (
            <main className="container-fluid">
                <PageTitle title="reportmail" saveButton={{label: 'save', div: "d-md-flex justify-content-md-end", validate: this.validate, submit: this.handleSubmit}} />
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-dp">
                            <div className="card-body">
                                <form onSubmit={this.handleSubmit} >
                                    {this.renderInput("name", "name", "text")}
                                    {this.renderSelect("report_id","report" , this.state.reports )}
                                    {this.renderSelect("period","period" , this.state.periods )}
                                    {this.renderInput("html_message", "html-message", "textarea")}
                                    {this.renderInput("text_message", "text-message", "textarea")}
                                    {this.renderInput("subject_message", "subject", "text")}
                                    {this.renderInput("filename", "filename", "text")}
                                    {this.renderInput("mail_addresses", "receivers-explanation", "text")}
                                    {this.renderCheckbox("is_active", "active")}
                                    {this.renderSelect("send_hour_tz","hour" , this.state.hours )}
                                    {this.renderSelect("timezone","timezone" , this.state.timezones )}
                                    {this.renderSelectForm("weekdays", "weekdays", "send_weekdays", "name", false)}
                                    {this.renderSelectForm("monthdays", "monthdays", "send_monthdays", "name", false)}
                                    {this.renderSelectForm("branchesall", "branch", "branches", "label", false)}
                                    {this.renderButton("save")}
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </main>
        );
    }
}

export default AdminReportmailingForm;
