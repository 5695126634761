import React from 'react';
import { BsPersonCircle } from 'react-icons/bs';

function Profile() {
    return (
        //<i className="fa fa-user-circle-o fa-lg icon-nav me-2"></i>
        <BsPersonCircle className="icon-nav me-2"/>
    );
}

export default Profile;
