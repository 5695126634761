import React from 'react';
import InputSelectArray from './inputSelectArray';
import _ from 'lodash';

const ProductRules = ({ data, addRule, deleteRule, changeRule, times }) => {
   let ruletypes = ["time","stock"];
   let discounttypes = ["amount","percentage"];
   let stocks = _.range(5,100,5).map(x => `${x}%`);

   return ( 
   <div>
      {data?.length && data.map((rule,rid) => {
         return (
            <div className="input-group" key={rid}>
               <InputSelectArray name="rule" id={`${rid}-rule_type`} value={rule.rule_type} options={ruletypes} onChange={changeRule} />
               {/* <input type="text" name="time" id={`${rid}-rule_number`} value={rule.rule_number} onChange={changeRule} className="form-control" /> */}
               <InputSelectArray name="rule" id={`${rid}-rule_number`} value={rule.rule_number} sort={false} options={rule.rule_type==='stock' ? stocks : rule.rule_type==='time' ? times : []} onChange={changeRule} />
               <InputSelectArray name="rule" id={`${rid}-discount_type`} value={rule.discount_type} options={discounttypes} onChange={changeRule} />
               <input type="number" min="0" name="discount" id={`${rid}-discount_number`} value={rule.discount_number} onChange={changeRule} className="form-control" />
               <button type="button" onClick={() => deleteRule(rid)} className="btn btn-outline-danger">-</button>
            </div>)
      })}
      <button type="button" onClick={addRule} className="btn btn-success float-end mw-33">+</button>
   </div>
   );
}

export default ProductRules;
