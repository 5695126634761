import React, { useState, useContext, useEffect } from 'react';
import DateContext from '../../context/dateContext';
import { range } from '../../services/calculateService';
import { t } from '../../services/translationService';
import LanguageContext from '../../context/languageContext';
import moment from 'moment';

const MonthPicker = () => {
    const dateContext = useContext(DateContext);
    let { lng } = useContext(LanguageContext);

    let years = range(moment().year()-5,moment().year());
    let startyear = moment().year();
    let startmonth = moment().format('MM');
    
    const [year, setYear] = useState(startyear);
    const [month, setMonth] = useState(startmonth);
    
    
    useEffect(() => {
      let formaty = 'YYYY-MM-DD';
      let start = `${year}-${month}-01`;
      let end = moment(start,formaty).endOf('month').format(formaty);

      dateContext.changeDaterange(new Date(start),new Date(end));
    }, [year,month]);

    useEffect(() => {
      let formaty = 'YYYY-MM-DD';
      let start = `${year}-${month}-01`;
      let end = moment(start,formaty).endOf('month').format(formaty);

      dateContext.changeDaterange(new Date(start),new Date(end));
    }, []);

    function correctMonth(x) {
      let y = `${x}`;
      if (y.length === 1) y = `0${y}`;
      return y;
    }

    function changeYear() {
      const y = document.getElementById("idYear");
      if (y) setYear(y.value);
    }

    function changeMonth() {
      const y = document.getElementById("idMonth");
      if (y) setMonth(y.value);
    }

    function arrowRight() {
      let y = parseInt(month) + 1;
      if (y >= 13 ) {
        y = 1;
        let y2 = parseInt(year) + 1;
        if (years.includes(y2)) setYear( y2 );
      }
      setMonth(correctMonth(y));
    }

    function arrowLeft() {
      let y = parseInt(month) - 1;
      if (y <= 0) {
        y = 12;
        let y2 = parseInt(year) - 1;
        if (years.includes(y2)) setYear( y2 );
      }
      setMonth(correctMonth(y));
    }

    return (
      <span className="rdrMonthAndYearPickers">
        <button type="button" className="rdrNextPrevButton rdrPprevButton" onClick={arrowLeft}><i></i></button>
        <span className="rdrMonthPicker">
          <select id="idMonth" value={month} onChange={changeMonth}>
            <option value="01">{t('january',lng)}</option><option value="02">{t('february',lng)}</option><option value="03">{t('march',lng)}</option><option value="04">{t('april',lng)}</option><option value="05">{t('may',lng)}</option><option value="06">{t('june',lng)}</option><option value="07">{t('july',lng)}</option><option value="08">{t('august',lng)}</option><option value="09">{t('september',lng)}</option><option value="10">{t('october',lng)}</option><option value="11">{t('november',lng)}</option><option value="12">{t('december',lng)}</option>
          </select>
        </span>
        <span></span>
        <span className="rdrYearPicker">
          <select id="idYear" value={year} onChange={changeYear}>
            {years.map( y => <option key={y} value={y}>{y}</option> )}
          </select>
        </span>
        <button type="button" className="rdrNextPrevButton rdrNextButton" onClick={arrowRight}><i></i></button>
      </span>
    );
}

export default MonthPicker;
