import React from 'react';
import image from '../../assets/spinner2.gif';

function Spinner({size}) {
    let classname = "spinner2";
    if (size === 'btn') classname = "spinner-btn";

    return (
        <div className="div-spinner2"><img src={image} alt="spinner" className={classname} /></div>
    );
}

export default Spinner;
