import http from './httpService';
import fileDownload from 'js-file-download';
import { toastError } from './toastService';
import Translation from './translationService';

export function getData(start,end,widgets=['revenue_day']) {
    // console.log('getData',start,end);
    return http.get('/data/1',{ params : {"startDate":start, "endDate":end, "widgets":widgets} });
}

export function getTrend(start,end,widgets=['revenue_day'],startdata) {
    // console.log('getData',start,end);
    return http.get('/data/2',{ params : {"startDate":start, "endDate":end, "widgets":widgets, "startData":startdata} });
}

export function getReport(id,name,start,end,branches,setLoading) {
    http.get(`/reports/${id}`, { responseType: 'blob', params : {"startDate":start, "endDate":end, "branches":branches }} )
    .then(res => {
        let length = branches.filter(x => x !== 'allbranches').length;
        let strbranch = `${length} branches`;
        if (length === 1) strbranch = '1 branch';
        if (length === 0) strbranch = 'all branches';
        fileDownload(res.data, `${new Date().toISOString().slice(0, 10)} ${name} ${strbranch}.xlsx`);
        setLoading(false);
    })
    .catch(err => {
        toastError(<Translation>unexpected-error</Translation>);
        setLoading(false);
    });
}

export function saveDataInput(data,field) {
    const body = {data: {...data }, field};
    return http.post('/data', body);
}

export function getNotes(start,end,idconfig) {
    return http.get('/notes',{ params : {"startDate":start, "endDate":end, "idconfig": idconfig} });
}

export function updateNote(id, data) {
    const body = {...data};
    
    return http.put(`/notes/${id}`, body);
}

export function saveNote(data) {
    const body = {...data};
    
    return http.post('/notes', body);
}
