import React, { useState } from 'react';
import { getReport } from '../../services/dataService';
import { dateToString } from '../../services/calculateService';
import Translation from '../../services/translationService';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import Spinner from '../common/spinner';

function Report({data, startDate, endDate, branches}) {
    
    const [loading, setLoading] = useState(false);
    
    return (
        <div className="col-lg-12">
                <div className="card card-dp">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-5">
                                <HiOutlineDocumentReport className="me-2 h-w-34 inline-block" />
                                <p className="p-report inline-block"> {data.name}</p>
                            </div>
                            <div className="col-sm-3"><p className="p-report-info"><Translation>{data.info}</Translation></p></div>
                            <div className="col-sm-4 text-end">
                                {/* <button className="btn btn-success" onClick={() => {
                                    getReport(data._id, data.name, dateToString(startDate),dateToString(endDate),branches, setLoading);
                                    setLoading(true);
                                }}>{ loading ? <div>Loading <Spinner size="btn" /></div> : 'Download'}</button> */}
                                { 
                                    loading ? <button className="btn btn-success" onClick={() => console.log('Loading')}>Loading <Spinner size="btn" /></button> : 
                                    <button className="btn btn-success" onClick={() => {
                                        getReport(data._id, data.name, dateToString(startDate),dateToString(endDate),branches, setLoading);
                                        setLoading(true);
                                    }}>Download</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    );
}

export default Report;
