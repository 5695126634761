import React, { Component } from 'react';
import Joi from 'joi-browser';
import _ from 'lodash';
import { RiEdit2Line } from 'react-icons/ri';
import Input from './input';
import InputPlaceholder from './inputPlaceholder';
import InputFloat from './inputFloat';
import InputSelect from './inputSelect';
import InputSelect2 from './inputSelect2';
import InputSelectDb from './inputSelectDb';
import InputDisabled from './charts/inputDisabled';
import InputCheckbox from './inputCheckbox';
import InputSelectForm from './inputSelectForm';
// import InputModal from './inputModal';
import ProductRules from './productRules';
import { changeCheckbox, formatText, timeRange } from '../../services/calculateService';
import Translation from '../../services/translationService';
import Table from './table';

class Form extends Component {
    state = { 
        data: {},
        errors: {}
    };

    validate = () => {
        const { error } = Joi.validate(this.state.data, this.schema, { abortEarly: false });
        if (!error) return null;

        const errors = {};
        for (let item of error.details) {
            errors[item.path[0]] = item.message;
        }
        return errors;
    };

    validateProperty = ({ name, value }) => {
        const obj = { [name]: value };
        const schema = { [name] : this.schema[name] };
        
        const { error } = Joi.validate(obj, schema);
        return error ? error.details[0].message : null;
    };

    handleSubmit = e => {
        e.preventDefault();
        
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) return;

        this.doSubmit();
    };

    handleChange = ({ currentTarget: input }) => {
        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        const data = {...this.state.data};
        data[input.name] = input.value;
        this.setState({ data, errors });
    };

    handleChangeCheck = (e, name) => {
        const data = {...this.state.data};
        data[name] = changeCheckbox(e);
        this.setState({ data });
    }

    handleChangeSelectForm = (e, id, listToAdd) => {
        const data = {...this.state.data};
        let list = data[listToAdd];
        if (changeCheckbox(e)) {
            list.push(id);
        } else {
            list = list.filter(function(item) { 
                return item !== id
            })
        }

        data[listToAdd] = list;
        this.setState({ data });
    }

    handleSelectFormAll = (e, listToAdd,ids) => {
        const data = {...this.state.data};
        if (data[listToAdd].length < ids.length) {
            data[listToAdd] = ids;
        } else {
            data[listToAdd] = [];
        }
        this.setState({ data });
    }

    renderDisabled(name, label, type = "text") {
        const { data } = this.state;
        return <InputDisabled name={name} value={data[name]} type={type} label={label} />
    }

    renderInput(name, label, type = "text") {
        const { data, errors } = this.state;
        return <Input name={name} value={data[name]} type={type} label={label} onChange={this.handleChange} error={errors[name]} />
    }

    renderInput2(name, label, type = "text") {
        const { data, errors } = this.state;
        return <InputPlaceholder name={name} value={data[name]} type={type} label={label} onChange={this.handleChange} error={errors[name]} />
    }

    renderInputFloat(name, label, type="text") {
        const { data, errors } = this.state;
        return <InputFloat name={name} value={data[name]} type={type} label={label} onChange={this.handleChange} error={errors[name]} />
    }

    renderButton(label, btn, div) {
        const btnclass = btn ? `btn btn-${btn}` : "btn btn-success mw-100";
        const divclass = div ? `d-grid gap-2 ${div}` : "d-grid gap-2";
        return <div className={divclass}><button disabled={this.validate()} type="submit" className={btnclass}><Translation>{label}</Translation></button></div>
    }

    renderSelect(name, label, options, disabled=false) {
        const { data, errors } = this.state;
        return <InputSelect name={name} value={data[name]} label={label} options={options} onChange={this.handleChange} error={errors[name]} disabled={disabled} />
    }

    renderSelectForm(name, label, listName, showName, activecolumn) {
        const list = this.state.data[listName];
        return <InputSelectForm name={name} data={this.state[name]} label={label} listName={listName} showName={showName} list={list} onChange={this.handleChangeSelectForm} allChange={this.handleSelectFormAll} activecolumn={activecolumn} />
    }

    renderCheckbox(name, label) {
        const { data } = this.state;
        return <InputCheckbox name={name} label={label} value={data[name]} onChange={this.handleChangeCheck} />
    }

    
    renderTable(name, label, columns) {
        const data = this.state[name];
        return (<div className="my-3 overflow-x-auto">
                    <label htmlFor={name} className="form-label"><Translation>{label}</Translation></label>
                    <Table data={data} keyid={"_id"} columns={columns} />
                </div>);
    }
    
    handleChangeWidget = ({ currentTarget: input }) => {
        const data = {...this.state.data};
        data.widgets[input.id][input.name] = input.value;
        if (input.name === 'kpi_id') data.widgets[input.id]['trend'] = false;
        this.setState({ data });
    };

    handleChangeWidgetCheckbox = (e, name, id) => {
        const data = {...this.state.data};
        data.widgets[id][name] = changeCheckbox(e);
        this.setState({ data });
    };

    addWidget = () => {
        const data = {...this.state.data};
        let maxid = 1;
        data.widgets.map(w => {if (parseInt(w.id) >= parseInt(maxid)) maxid=parseInt(w.id)+1 } );
        data.widgets.push({ id: maxid, kpi_id:'', title:'', width:'3', height:'3', max_n:''});
        this.setState({ data });
    };

    deleteWidget = (id) => {
        const data = {...this.state.data};
        data.widgets = data.widgets.filter(function(v, i){ return i !== id; });
        this.setState({ data });
    };

    checkTrendOption = (kpiid) => {
        let kpi = _.filter(this.state.kpis, {'kpi_id': kpiid});
        if (kpi.length && kpi[0].trend_option) return kpi[0].trend_option;
        return false;
    }

    checkTrendHourOption = (kpiid) => {
        let kpi = _.filter(this.state.kpis, {'kpi_id': kpiid});
        if (kpi.length && kpi[0].trend_hour_option) return kpi[0].trend_hour_option;
        return false;
    }

    renderWidgets(name, label) {
        let data = this.state.data[name];

        return (<div className="my-3 overflow-x-auto">
                    <label htmlFor={name} className="form-label"><Translation>{label}</Translation></label>
                    <div className="input-group mb-3">
                        <table className="table">
                            <thead>
                                <tr><th scope="col"><Translation>order</Translation></th><th scope="col"><Translation>kpi</Translation></th><th scope="col"><Translation>title</Translation></th><th scope="col"><Translation>width</Translation></th><th scope="col"><Translation>height</Translation></th><th scope="col"><Translation>max-display-n</Translation></th><th scope="col"><Translation>trend</Translation></th><th scope="col" className="p-sm-h"><Translation>trend-hour</Translation></th><th scope="col"><Translation>action</Translation></th></tr>
                            </thead>
                            <tbody>
                                {data.map((w,wid) => 
                                    <tr index={wid} key={wid}>
                                        <td><input type="text" name="id" id={wid} value={w.id} onChange={this.handleChangeWidget} className="form-control" /></td>
                                        <td><InputSelectDb name="kpi_id" label="kpi_id" id={wid} value={w.kpi_id} options={this.state.kpis} onChange={this.handleChangeWidget} /></td>
                                        <td><input type="text" name="title" id={wid} value={w.title} onChange={this.handleChangeWidget} className="form-control" /></td>
                                        {/* <td><input type="text" name="width" id={wid} value={w.width} onChange={this.handleChangeWidget} className="form-control" /></td> */}
                                        <td><InputSelectDb name="width" label="width" id={wid} value={w.width} options={this.state.widths} onChange={this.handleChangeWidget} /></td>
                                        <td><InputSelectDb name="height" label="height" id={wid} value={w.height} options={this.state.heights} onChange={this.handleChangeWidget} /></td>
                                        <td><input type="text" name="max_n" id={wid} value={w.max_n ? w.max_n : ''} onChange={this.handleChangeWidget} className="form-control" /></td>
                                        <td>{ this.checkTrendOption(w.kpi_id) ? <InputCheckbox name="trend" id={wid} value={w.trend ? w.trend : false} onChange={this.handleChangeWidgetCheckbox} /> : null }</td>
                                        <td>{ (this.checkTrendOption(w.kpi_id) && this.checkTrendHourOption(w.kpi_id)) ? <InputCheckbox name="trendhour" id={wid} value={w.trendhour ? w.trendhour : false} onChange={this.handleChangeWidgetCheckbox} /> : null }</td>
                                        <td><button type="button" onClick={() => this.deleteWidget(wid)} className="btn btn-danger">-</button></td>
                                    </tr>
                                )}
                                <tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td><button type="button" onClick={this.addWidget} className="btn btn-warning">+</button></td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>);
    }

    addVestaboard = () => {
        const data = {...this.state.data};
        let maxid = 1;
        data.vestaboards.map(w => {if (parseInt(w.id) >= parseInt(maxid)) maxid=parseInt(w.id)+1 } );
        data.vestaboards.push({ id: maxid, token:''});
        this.setState({ data });
    };

    deleteVestaboard = (id) => {
        const data = {...this.state.data};
        data.vestaboards = data.vestaboards.filter(function(v, i){ return i !== id; });
        for (let i = 0; i < data.vestaboards.length; i ++) { data.vestaboards[i]['id'] = i+1; }
        this.setState({ data });
    };

    handleChangeVestaboard = ({ currentTarget: input }) => {
        const data = {...this.state.data};
        data.vestaboards[input.id][input.name] = input.value;
        this.setState({ data });
    };

    renderVestaboards(name, label) {
        let data = this.state.data[name];

        return (<div className="my-3 overflow-x-auto">
                    <label htmlFor={name} className="form-label"><Translation>{label}</Translation></label>
                    <div className="input-group mb-3">
                        <table className="table">
                            <thead>
                                <tr><th scope="col"><Translation>id</Translation></th><th scope="col"><Translation>token</Translation></th><th scope="col"><Translation>action</Translation></th></tr>
                            </thead>
                            <tbody>
                                {data.map((w,wid) => 
                                    <tr index={wid} key={wid}>
                                        <td><input type="number" name="id" id={wid} value={w.id} className="form-control" disabled /></td>
                                        <td><input type="text" name="token" id={wid} value={w.token} onChange={this.handleChangeVestaboard} className="form-control" /></td>
                                        <td><button type="button" onClick={() => this.deleteVestaboard(wid)} className="btn btn-danger">-</button></td>
                                    </tr>
                                )}
                                <tr><td></td><td></td><td><button type="button" onClick={this.addVestaboard} className="btn btn-warning">+</button></td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>);
    }

    addVestaboardProduct = () => {
        const data = {...this.state.data};
        let newid = new Date().getTime();
        data.products.push({ id: newid, vestaboard_id: 1, tab:1, order:1, product_id:'', label:'', weigh:false, active:true, rules:[]});
        this.setState({ data, product_id: newid });
    };

    deleteVestaboardProduct = (id) => {
        const data = {...this.state.data};
        data.products = data.products.filter(function(v){ return v.id !== id; });
        this.setState({ data });
    };

    handleChangeVestaboardProduct = ({ currentTarget: input }) => {
        console.log('input:',input);
        const data = {...this.state.data};
        data.products.map(p => { if (p.id == input.id) p[input.name] = input.type === 'checkbox' ? input.checked : input.value } );
        this.setState({ data });
    };

    addVestaboardProductRule = () => {
        console.log('Add product for',this.state.product_id);
        const data = {...this.state.data};
        // data.products[this.state.product_id].rules.push({ rule_type: '', rule_number:'', discount_type:'', discount_number:0 });
        data.products.map(p => { if (p.id == this.state.product_id) p.rules.push({ rule_type: '', rule_number:'', discount_type:'', discount_number:0 }) } );
        this.setState({ data });
    };

    deleteVestaboardProductRule = (id) => {
        const data = {...this.state.data};
        // data.products[this.state.product_id].rules.splice(id,1);
        data.products.map(p => { if (p.id == this.state.product_id) p.rules.splice(id,1) } );
        this.setState({ data });
    };

    changeVestaboardProductRule = ({ currentTarget: input }) => {
        let [ruleid,ruletype] = input.id.split('-');
        console.log('change rule input:',input,ruleid,ruletype,input.value);

        const data = {...this.state.data};
        // data.products[this.state.product_id].rules[ruleid][ruletype] = input.value;
        data.products.map(p => { if (p.id == this.state.product_id) p.rules[ruleid][ruletype] = input.value } );
        // if (ruletype === 'rule_type') data.products[this.state.product_id].rules[ruleid]['rule_number'] = '';
        if (ruletype === 'rule_type') data.products.map(p => { if (p.id == this.state.product_id) p.rules[ruleid]['rule_number'] = '' } );
        this.setState({ data });
    };


    renderVestaboardproduct(name) {
        if (!this.state.id) return (<div className="my-3 overflow-x-auto"><label htmlFor={name} className="form-label"><Translation>{name}</Translation></label><p><Translation>added-first-save</Translation></p></div>);
        let data = this.state.data[name];
        let pid = this.state.product_id;
        let products = this.state.products;
        let product = _.find(data,{id:pid}) || { id: pid, vestaboard_id: 1, tab:1, order:1, product_id:'', label:'', weigh:false, active:true, rules:[]};
        let times = timeRange(this.state.data.openingtime,this.state.data.closingtime);

        let sorted = _.sortBy(data,['vestaboard_id','tab','order']);

        return (<div className="my-3 overflow-x-auto">
                    <label htmlFor={name} className="form-label"><Translation>{name}</Translation></label>
                    <div className="input-group mb-3">
                        <table className="table">
                            <thead>
                                <tr><th scope="col"><Translation>vestaboard-id</Translation></th><th scope="col"><Translation>tab</Translation></th><th scope="col"><Translation>order</Translation></th><th scope="col"><Translation>product</Translation></th><th scope="col"><Translation>display-name</Translation></th><th scope="col"><Translation>weigh</Translation></th><th scope="col"><Translation>display</Translation></th><th scope="col"><Translation>pricingrules</Translation></th><th scope="col"><Translation>action</Translation></th></tr>
                            </thead>
                            <tbody>
                                {sorted.map(w =>
                                    <tr index={w.id} key={w.id}>
                                        <td>{w.vestaboard_id}</td>
                                        <td>{w.tab}</td>
                                        <td>{w.order}</td>
                                        <td>{_.get(_.find(products, { id : w.product_id }), 'name')}</td>
                                        <td>{w.label}</td>
                                        <td>{formatText(w.weigh,'boolean-status')}</td>
                                        <td>{formatText(w.active,'boolean-status')}</td>
                                        <td>{w.rules?.length}</td>
                                        <td><button type="button" className="btn btn-warning m-r-5" data-bs-toggle="modal" data-bs-target="#modalAddProduct" onClick={()=> this.setState({ product_id: w.id }) }><RiEdit2Line /></button><button type="button" onClick={() => this.deleteVestaboardProduct(w.id)} className="btn btn-danger">-</button></td>
                                    </tr>
                                )}
                                <tr><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td><button type="button" onClick={this.addVestaboardProduct} className="btn btn-warning" data-bs-toggle="modal" data-bs-target="#modalAddProduct">+</button></td></tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <div className="modal fade" id="modalAddProduct" tabIndex="-1" aria-labelledby="addProduct" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Change product</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="vestaboard_id" className="form-label"><Translation>vestaboard-id</Translation></label>
                                    <input type="number" id={pid} name="vestaboard_id" value={product.vestaboard_id} className="form-control" onChange={this.handleChangeVestaboardProduct} min="1" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="tab" className="form-label"><Translation>tab</Translation></label>
                                    <input type="number" id={pid} name="tab" value={product.tab} className="form-control" onChange={this.handleChangeVestaboardProduct} min="1" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="order" className="form-label"><Translation>order</Translation></label>
                                    <input type="number" id={pid} name="order" value={product.order} className="form-control" onChange={this.handleChangeVestaboardProduct} min="1" />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="label" className="form-label"><Translation>product</Translation></label>
                                    <InputSelect2 id={pid} id_from="id" id_to="product_id" label="name" value={product.product_id} options={products} onChange={this.handleChangeVestaboardProduct} />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="label" className="form-label"><Translation>display-name</Translation></label>
                                    <input type="text" id={pid} name="label" value={product.label} onChange={this.handleChangeVestaboardProduct} className="form-control" />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="weigh" className="form-label"><Translation>weigh</Translation></label>
                                    <input type="checkbox" id={pid} name="weigh" checked={product.weigh} onChange={this.handleChangeVestaboardProduct} className="form-check-input form-control" />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="active" className="form-label"><Translation>display</Translation></label>
                                    <input type="checkbox" id={pid} name="active" checked={product.active} onChange={this.handleChangeVestaboardProduct} className="form-check-input form-control" />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="rules" className="form-label"><Translation>pricingrules</Translation></label>
                                    <ProductRules data={product.rules} addRule={this.addVestaboardProductRule} deleteRule={this.deleteVestaboardProductRule} changeRule={this.changeVestaboardProductRule} times={times} />
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    </div>
                </div>);
    }
}

export default Form;
