import React from 'react';
import _ from 'lodash';
import { classWidth } from '../../services/calculateService';
import { chart } from '../common/charts2/chartSelections';
import Info from './info';

function Widgets({ dashboard, data, branches }) {

    let dataf = {...data};
    if (branches && data) {
        for (const key in data) {
            // Filter data on selected branches
            dataf[key] = _.filter(dataf[key], o => { return branches.includes(o.branch) } );
        }
    }
    
    return (
        <div className="row" key='dashboards2'>
                {dashboard.widgets.map(w => 
                    <div className={classWidth(w.width)} key={w.id} >
                        <div className="card card-dp">
                            <div className="card-body">
                                <div className="row"><div className="col-10"><p className="card-dp-h2">{w.title} </p></div><div className="col-2 text-end"><Info title={w.information || w.title} /></div></div>
                                { chart(dataf, w)}
                            </div>
                        </div>
                    </div> )}
        </div>
    );
}

export default Widgets;
