import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: false,
      text: 'Chart.js Bar Chart',
    },
  },
  interaction: {
    intersect: false,
    mode: 'x'
  }
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data_origineel = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => Math.floor(Math.random() * 1000)),
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Dataset 2',
      data: labels.map(() => Math.floor(Math.random() * 1000)),
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

function formatHours(key) {
  return moment.utc(key*3600*1000).format('HH:mm');
}

function hourSortKey(key) {
  if (key < '05:00') {
    return 1;
  } else {
    return 0;
  }
}

export default function BarChartVertical({ data=[], labelx='net_sales_amount', labely='branchname', max_n=null }) {
  let data0 = _(data).groupBy(labely)
                      .map((value, key) => ({
                        label: labely==='business_hour' ? formatHours(key) : key,
                        extra_sort: labely==='business_hour' ? hourSortKey(formatHours(key)) : 1,
                        amount: _.sumBy(value, function(o) {
                          if(!o[labelx]) o[labelx]=0;
                          return parseFloat(o[labelx]); 
                        })
                      })).value();
  
  data0 = _.orderBy(data0, ['extra_sort','label'], ['asc','asc']);
  if (max_n) data0 = _.take(data0, max_n);
  
  let data1 = {
    labels: data0.map((x) => x['label']),
    datasets: [
      {
      data: data0.map((x) => parseFloat(x['amount'])),
      borderColor: `rgb(53, 162, 235)`,
      backgroundColor: `rgba(53, 162, 235, 0.7)`,
      // borderColor: `rgb(${color_sel})`,
      // backgroundColor: `rgba(${color_sel}, 0.5)`,
      }
    ]
  };

  return <Bar options={options} height={225} data={data1} />;
}
