import React from 'react';
import { useParams } from "react-router-dom";
import Dashboard1 from './dashboard1';
// import Dashboard2 from './dashboard2';
// import Dashboard3 from './dashboard3';
// import Dashboard4 from './dashboard4';
import DashboardMap from './dashboardMap';
import Demo1 from './demo1';
import Demo2 from './demo2';
import Develop1 from './develop1';
import Overview from '../dashboard_overview';
import LMsalesplanning from './lm_sales_planning';
import LMlosses from './lm_losses';
import LMrevopu from './lm_rev_opu';

const mapping = {
    '1': Dashboard1,
    // '2': Dashboard2,
    // '3': Dashboard3,
    // '4': Dashboard4,
    'map': DashboardMap,
    'overview': Overview,
    'demo1': Demo1,
    'demo2': Demo2,
    'develop1': Develop1,
    '6324354a2d309a63097cbea1': LMsalesplanning,
    '634975459a5768efae32c2ef': LMlosses,
    '634eb79d9a5768efae32ecd1': LMrevopu
};

function redirectDb(id,props) {
    return React.createElement(mapping[id], props);
}

function Dashboards(props) {
    let { id } = useParams();
    if (!(id in mapping)) id = 1;
    
    return (
        <div key='dashboards1'>
            {/* <Dashboard1 {...props} /> */}
            {redirectDb(id, props)}
        </div>
    );
}

export default Dashboards;
