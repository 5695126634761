import React from 'react';
import Translation from '../../services/translationService';

const Input = ({ name, label, error, type, ...rest }) => {
    return ( 
        <div className="mb-3">
            <label htmlFor={name} className="form-label"><Translation>{label}</Translation></label>
            { (type==="textarea") ? <textarea className="form-control" {...rest} name={name} id={name} rows="2"></textarea> : <input type={type} {...rest} name={name} id={name} className="form-control" /> }
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
     );
}
 
export default Input;
