import React from 'react';
import DashboardTitle from './common/dashboardTitle';
import BarChartHorizontal from './common/charts/barChartHorizontal.jsx';
import BarChartVertical from './common/charts/barChartVertical';
import BarChartStacked from './common/charts/barChartStacked';
import BarChartGrouped from './common/charts/barChartGrouped';
import LineChart from './common/charts/lineChart';
import LineChartMulti from './common/charts/lineChartMulti';
import PolarAreaChart from './common/charts/polarAreaChart';
import RadarChart from './common/charts/radarChart';
import ScatterChart from './common/charts/scatterChart';
import BubbleChart from './common/charts/bubbleChart';
import MultitypeChart from './common/charts/multitypeChart';
import { PieChartOrigineel } from './common/charts/pieChart';
import DoughnutChart from './common/charts/doughnutChart';

const example = [{"branch": "branch-1", "net_sales_amount": "292.54", "branchname": "Branch 1"}, { "branch": "branch-2", "net_sales_amount": "57.45", "branchname": "Branch 2" }, {"branch": "branch-3", "net_sales_amount": "53.02", "branchname": "Branch 3" }, { "branch": "branch-4", "net_sales_amount": "82.05", "branchname": "Branch 4" },
    {"branch": "branch-5", "net_sales_amount": "73.08", "branchname": "Branch 5" }, { "branch": "branch-6", "net_sales_amount": "77.85", "branchname": "Branch 6"}, {"branch": "branch-7", "net_sales_amount": "29.25", "branchname": "Branch 7"}, {"branch": "branch-8", "net_sales_amount": "12.94", "branchname": "Branch 8"},
    {"branch": "branch-9", "net_sales_amount": "55.20", "branchname": "Branch 9"}, {"branch": "branch-10", "net_sales_amount": "60.26", "branchname": "Branch10"}, {"branch": "branch-11", "net_sales_amount": "49.53", "branchname": "Branch11"}, {"branch": "branch-12", "net_sales_amount": "168.17", "branchname": "Branch12"},
    {"branch": "branch-13", "net_sales_amount": "32.56", "branchname": "Branch13"}, {"branch": "branch-14", "net_sales_amount": "46.23", "branchname": "Branch14"}, {"branch": "branch-15", "net_sales_amount": "42.83", "branchname": "Branch15"}, {"branch": "branch-16", "net_sales_amount": "42.73", "branchname": "Branch16"}, {"branch": "branch-17", "net_sales_amount": "75.03", "branchname": "Branch17" }];

function Dashboard({ data }) {
    if (!data) data = example;

    return (
        <main className="container-fluid">
            <DashboardTitle title="Grafieken overzicht"/>

            <div className="row">
                <div className="col-lg-6">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">BarChartHorizontal</p>
                            <BarChartHorizontal data={data} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">BarChartVertical</p>
                            <BarChartVertical data={data} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">BarChartStacked</p>
                            <BarChartStacked />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">LineChart</p>
                            <LineChart />
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row">
                <div className="col-lg-6">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">LineChartMulti</p>
                            <LineChartMulti />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">BarChartGrouped</p>
                            <BarChartGrouped />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">PolarAreaChart</p>
                            <PolarAreaChart />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">RadarChart</p>
                            <RadarChart />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">ScatterChart</p>
                            <ScatterChart />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">BubbleChart</p>
                            <BubbleChart />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">MultitypeChart</p>
                            <MultitypeChart />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">PieChart</p>
                            <PieChartOrigineel />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">DoughnutChart</p>
                            <DoughnutChart />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Dashboard;
