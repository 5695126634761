import { toast } from 'react-toastify';
import auth from './authService';

export function toastSuccess(m,s=1000) {
    toast.success(m, {
        position: "top-center",
        autoClose: s,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
    });
}

export function toastWarning(m,s=1000) {
    toast.warn(m, {
        position: "top-center",
        autoClose: s,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
    });
}

export function toastError(m,s=5000) {
    if (m==='Token expired.') {
        auth.logout();
        window.location = "/";
    }
    toast.error(m, {
        position: "top-center",
        autoClose: s,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
    });
}
