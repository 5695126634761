import React, { useContext } from 'react';
import { t } from '../../services/translationService';
import LanguageContext from '../../context/languageContext';

const InputSelect = ({ name, label, options, error, ...rest }) => {
    let { lng } = useContext(LanguageContext);
    return ( 
        <div className="mb-3">
            <label htmlFor={name} className="form-label">{t(label,lng)}</label>
            <select name={name} id={name} {...rest} className="form-select" aria-label="Default select">
                <option default value=""></option>
                {options.map(option => (
                    // <option key={option._id} value={option._id}>{option.name}</option>
                    <option key={option._id} value={option._id}>{t(option.name,lng)}</option>
                ))}
            </select>
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
     );
}
 
export default InputSelect;
