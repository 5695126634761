import React, { useContext } from 'react';
import _ from 'lodash';
import { t } from '../../services/translationService';
import LanguageContext from '../../context/languageContext';

const InputSelectDb = ({ name, label, options, ...rest }) => {
    let { lng } = useContext(LanguageContext);
    
    options.map(o => {
        o['order'] = t(o[label],lng);
        return o;
    });
    const sorted = _.orderBy(options, ['order'], ['asc']);

    return ( 
            <select name={name} id={name} {...rest} className="form-select" aria-label="Default select">
                <option default value=""></option>
                {sorted.map(option => (
                    <option key={option._id} value={option[name]}>{t(option[label],lng)}</option>
                ))}
            </select>
     );
}
 
export default InputSelectDb;
