import React from 'react';
import DashboardTitle from '../common/dashboardTitle';
import BarChartHorizontal from '../common/charts/barChartHorizontal.jsx';
import LineChart from '../common/charts/lineChart';
import TotalNumber from '../common/charts2/totalNumber';

const data1 = [{"x":8,"y":"Alma Romero"},{"x":10,"y":"Bryson Smith"},{"x":5,"y":"Olivia Stafford"},{"x":4,"y":"Alfredo Duran"},{"x":4,"y":"Willa Fernandez"},{"x":3,"y":"Bentley Hansen"},{"x":4.2,"y":"Hope Donovan"},{"x":3.2,"y":"Brayan Vang"},{"x":2.1,"y":"Madisyn Pollard"},{"x":2.2,"y":"Jad Hogan"}];
const data2 = {
    labels: ['monday','tuesday','wednesday','thursday','friday','saturday','sunday'],
    datasets: [
      {
        label: 'Check Ins',
        data: [50,40,45,40,50,60,70],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Check Outs',
        data: [40,43,38,48,40,35,30],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  }

function Dashboard({ data }) {
    return (
        <main className="container-fluid">
            <DashboardTitle title="PMS"/>

            <div className="row">
                <div className="col-lg-4">
                    <div className="card card-dp">
                        <div className="card-body card-widget">
                            <p className="card-dp-h2">Arrivals</p>
                            <TotalNumber data={"80"} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">Expected walk ins</p>
                            <TotalNumber data={"10"} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">Total arrivals</p>
                            <TotalNumber data={"90"} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-4">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">Average time arrival (min)</p>
                            <TotalNumber data={"5"} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">Average time walk in (min)</p>
                            <TotalNumber data={"10"} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">Total time (min)</p>
                            <TotalNumber data={"500"} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-5">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">Time per Check In per employee</p>
                            <BarChartHorizontal data={data1} labelx={"x"} labely={"y"} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="card card-dp">
                        <div className="card-body">
                            <p className="card-dp-h2">Check In / Check Out per weekday</p>
                            <LineChart datain={data2} />
                        </div>
                    </div>
                </div>
            </div>



        </main>
    );
}

export default Dashboard;
