import http from './httpService';
// const apiUrl = "/regions";

function objectUrl(apiUrl,id) {
    return `${apiUrl}/${id}`;
}

export function getObjects(apiUrl) {
    return http.get(apiUrl);
}

export function getObject(apiUrl,id) {
    return http.get(`${apiUrl}/${id}`);
}

export function updateObject(apiUrl, id, data) {
    const body = {...data};
    return http.put(objectUrl(apiUrl,id), body);
}

export function saveObject(apiUrl,data) {
    const body = {...data};
    return http.post(apiUrl, body);
}

export function deleteObject(apiUrl,id) {
    return http.delete(objectUrl(apiUrl,id) );
}
