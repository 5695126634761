import React, { useState, useEffect } from 'react';
import { toastSuccess, toastWarning, toastError } from '../../services/toastService';
import _ from 'lodash';
import DashboardTitle from '../common/dashboardTitle';
import InputText from '../common/inputText';
import { getData, saveDataInput, getNotes, updateNote, saveNote } from '../../services/dataService';
import { getBetweenDates, collectionSum, collectionSumInput, weekdayname, transformWeek, calcValuta } from '../../services/calculateService';
import logger from '../../services/logService';
import Translation from '../../services/translationService';
import Spinner from '../common/spinner';
import moment from 'moment';
// import TableInputForm from '../common/charts2/tableInputForm';
const format1 = 'YYYY-MM-DD';
const format2 = 'D-M-YYYY';

function Dashboard({locations, startDate, endDate}) {
    const [data, setData] = useState([]);
    const [notes, setNotes] = useState([]);
    const [branch, setBranch] = useState({});
    const [branchdata, setBranchdata] = useState([]);
    const [branchnotes, setBranchnotes] = useState([]);
    const [name] = useState('Omzet planning per locatie met toelichting (LM)');
    const [revenuegroups] = useState(['Restaurant','Banqueting','Hotel']);
    const [dates, setDates] = useState([]);
    const [updated, setUpdated] = useState(0);
    const [loading, setLoading] = useState(false);
    const [skip, setSkip] = useState(0);

    async function updateData() {
        setLoading(true);
        let startstr = moment(startDate).format(format1);
        let endstr = moment(endDate).format(format1);
        console.log('updateData', startstr,endstr);

        const { data } = await getData(startstr,endstr,['sales_planning_input']);
        const { data: datanotes } = await getNotes(startstr,endstr, '5e620b1f12203746008b4567');
        const dates = getBetweenDates(startstr,endstr);
        setData(data['sales_planning_input']);
        setNotes(datanotes);
        setDates(dates);
        setUpdated(updated+1);
        setLoading(false);
    }

    function selectBranch() {
        const first = _.sortBy(locations.filter(l => l.name !=='allbranches') , ['label'])[0];
        setBranch(first || {});

        let data1;
        if (first) data1 = _.filter(data, o => {
            o.planned_sales = parseFloat(o.planned_sales);
            return o.branch === first.name;
        } );
        setBranchdata( data1 ? _.cloneDeep(data1) : [] );

        let data2;
        if (first) data2 = _.filter(notes, n => { 
            if (n.frequency === 'bd') {
                n.date = moment(n.note_date).format('YYYY-MM-DD');
            } else if (n.frequency === 'w') {
                n.date = n.note_date;
            }
            return n.branch === first.name;
        } );
        setBranchnotes( data2 ? _.cloneDeep(data2) : [] );
    }

    useEffect(() => {
        // skip first because of the update of dateContext in opening new datepicker component
        if (skip) updateData();
        setSkip(skip+1);
    }, [endDate]);

    useEffect(() => {
        selectBranch();
    }, [locations,updated]);

    const handleChange = (e,rc,date) => {
        let x = e.target.value;
        let tempbranch = _.cloneDeep(branchdata);
        let bool = true;
        for (let i = 0; i < tempbranch.length; i++) {
            let td = tempbranch[i];
            if (td.business_date===date && td.revenue_group === rc) {
                tempbranch[i].planned_sales = x;
                bool = false;
            }
        }
        if (bool) tempbranch.push({branch: branch.name, branchname: branch.label, business_date: date, revenue_group: rc, planned_sales: x, planned_quantity: null});

        setBranchdata(tempbranch);
    }

    const saveChanges = async () => {
        if (branch.name) {
            setLoading(true);
            let noerrors = true;
    
    
            // Chenk data changes
            let checkbranch = data.filter(o => o.branch === branch.name);
            let changed = [];
            branchdata.map(bd => {
                let x = _.find(checkbranch, { branch: bd.branch, business_date: bd.business_date, revenue_group: bd.revenue_group });
                if ((!x && bd.planned_sales !== 0 && bd.branch) || (x && bd && x.planned_sales !== bd.planned_sales)) changed.push(bd);
            });
    
            // Chenk notes changes
            let checknotes = notes.filter(o => o.branch === branch.name);
            let changed2 = [];
            let newbranchnotes = [];
            branchnotes.map(bn => {
                let x1 = _.find(checknotes, { _id:bn._id });
                if ((!x1) || (bn.note !== x1.note)) {
                    changed2.push(bn);
                } else {
                    newbranchnotes.push(bn);
                }
            });
    
            // Notes
            if (changed2.length) {
                for (let i = 0; i < changed2.length; i++) {
                    let c = changed2[i];
                    try {
                        if (c._id) {
                            await updateNote(c._id,{ note: c.note });
                            newbranchnotes.push(c);
                        } else {
                            const { data: newnote } = await saveNote({ branch_id: branch._id, notes_configuration_id: '5e620b1f12203746008b4567', frequency: c.frequency, note_date: c.date, note: c.note });
                            c._id = newnote._id;
                            newbranchnotes.push(c);
                        }
                    }
                    catch (ex) {
                        if (ex.response) {
                            toastError(ex.response.data);
                        }
                        noerrors = false;
                        logger.log(ex);
                    }
                }
    
                // Update notes with updates
                let newnoteids = _.map(newbranchnotes, '_id');
                let newnotes = notes.filter(n => !newnoteids.includes(n._id));
                setNotes( newnotes.concat(newbranchnotes) );
    
                // Update branchnotes with updates
                setBranchnotes(newbranchnotes);
            }
    
            // Planned revenue / data
            if(changed.length) {
                let data2 = _.cloneDeep(data);
                let newdata = data2.filter(o => o.branch !== branch.name);
                newdata = newdata.concat(branchdata);
                setData(newdata);
                
                try {
                    await saveDataInput(changed, 'sales_planning');
                }
                catch (ex) {
                    if (ex.response) {
                        toastError(ex.response.data);
                    }
                    noerrors = false;
                    logger.log(ex);
                }
            }
            
            // Notifications
            if (changed.length + changed2.length < 1) {
                toastWarning(<Translation>no-changes</Translation>);
            } else if (noerrors){
                toastSuccess(<Translation>changes-made</Translation>);
            }
    
            setLoading(false);
        } else {
            toastWarning(<Translation>no-branch</Translation>);
        }
    }

    // const testen = async () => {
        
    //     let test = notes.filter(n => n.frequency==='w');
    //     let test1 = _.filter(branchnotes, {date: '2022-W39'});
    //     console.log('testen',branchnotes);
    // }

    function filterNote(date) {
        let notes1 = _.filter(branchnotes, {date: date});
        let y = '';
        if (notes1.length) y = notes1[0].note;
        return y;
    }

    function noteChange(event) {
        let value = event.target.value.substring(0, 250) || '';
        let date = event.target.name;
        let frequency = event.target.getAttribute('frequency');

        let tempbranch = _.cloneDeep(branchnotes);
        let bool = true;
        for (let i = 0; i < tempbranch.length; i++) {
            let td = tempbranch[i];
            if (td.date===date) {
                tempbranch[i].note = value;
                bool = false;
            }
        }
        if (bool) tempbranch.push({_id:'', branch: branch.name, date:date, frequency: frequency, note: value, note_date:date });

        setBranchnotes(tempbranch);
    }

    return (
        <main className="container-fluid">
            { loading ? <div className="loading center-dp "><Spinner /></div> : null}
            <DashboardTitle title={name} selections={['week']} />
            <div className="row">
                <div className="col-lg-12">
                    <div className="card card-dp">
                        <div className="card-body overflow-x-auto">
                            <p className="card-dp-h2 margin-b-15">{branch ? branch.label : ''}</p>
                            {/* <TableInputForm data={branchdata} columns={dates} rows={revenuegroups} handleChange={handleChange}/> */}                            
                            <table className="table">
                                <thead>
                                    <tr>
                                    <th scope="col"></th>
                                    {dates.map(c => <th scope="col" key={c}>{moment(c,format1).format(format2)}<br/><Translation>{weekdayname(c)}</Translation></th>)}
                                    <th scope="col">Totaal</th>
                                    </tr>
                                </thead>
                                <tbody className="table-input table-input-80">
                                    {revenuegroups.map(r =>
                                        <tr key={r}>
                                        <th scope="row">{r}</th>
                                        {/* {dates.map(c => <td key={`${r}-${c}`}>{getValue(data, {business_date:c, revenue_group: r}, 'planned_sales')}</td>)} */}
                                        {dates.map(c => 
                                            // <td key={`${r}-${c}`}>{collectionSum(data, 'planned_sales', {business_date:c, revenue_group: r})}</td>
                                            <td key={`${r}-${c}`}>€ <input type="number" value={collectionSumInput(branchdata, 'planned_sales', {business_date:c, revenue_group: r})} className="input-inputform-2" onChange={(e) => handleChange(e,r,c)}/></td>
                                        )}
                                        <td key={`${r}-total`}>{calcValuta(collectionSum(branchdata, 'planned_sales', {revenue_group: r},2))}</td>
                                        </tr>
                                    )}
                                    <tr className="table-light">
                                        <th scope="row">Totaal</th>
                                        {dates.map(c => <td key={`${'total'}-${c}`}>{calcValuta(collectionSum(branchdata, 'planned_sales', {business_date:c},2))}</td>)}
                                        <td key={`total-total`}>{calcValuta(collectionSum(branchdata, 'planned_sales',null,2))}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Toelichting LM (Dag)</th>
                                        {dates.map(c => <td key={`comment-d-${c}`}><InputText name={c} onChange={noteChange} value={filterNote(c)} frequency="bd" rows="3"/></td>)}
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Toelichting LM (Week)</th>
                                        <td colSpan="7"><InputText name={transformWeek(dates[0])} onChange={noteChange} value={filterNote(transformWeek(dates[0]))} frequency="w" rows="3"/></td>
                                    </tr>
                                </tbody>
                            </table>

                            <button className="btn btn-success float-end" onClick={() => saveChanges()}>Opslaan</button>
                            {/* <button className="btn btn-success float-end" onClick={() => testen()}>Testen</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Dashboard;
