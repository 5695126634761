import React, { useState, useContext, useEffect } from 'react';
import DateContext from '../../context/dateContext';
import { range } from '../../services/calculateService';
import moment from 'moment';

const YearPicker = () => {
    const dateContext = useContext(DateContext);
    
    let startyear = moment().year();
    let years = range(startyear-5,startyear);
    const [year, setYear] = useState(startyear);
    
    useEffect(() => {
      let start = new Date(`${year}-01-01`);
      let end = new Date(`${year}-12-31`);
      dateContext.changeDaterange(start,end);
    }, [year]);

    function changeYear() {
      const y = document.getElementById("idYear");
      if (y) setYear(y.value);
    }

    function arrowRight() {
      let y = parseInt(year) + 1;
      if (years.includes(y)) setYear( y );
    }

    function arrowLeft() {
      let y = parseInt(year) - 1;
      if (years.includes(y)) setYear( y );
    }

    return (
      <span className="rdrMonthAndYearPickers">
        <button type="button" className="rdrNextPrevButton rdrPprevButton" onClick={arrowLeft}><i></i></button>
        <span className="rdrYearPicker">
          <select id="idYear" value={year} onChange={changeYear}>
            {years.map( y => <option key={y} value={y}>{y}</option> )}
          </select>
        </span>
        <button type="button" className="rdrNextPrevButton rdrNextButton" onClick={arrowRight}><i></i></button>
      </span>
    );
}

export default YearPicker;
