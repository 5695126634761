import React from 'react';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import Translation from '../../services/translationService';

function changeSort(x, c) {
    if (x ==='_id') return c;

    if (x === c['column']) {
        if (c.order === 'asc') {
            c.order = 'desc';
        } else {
            c.order = 'asc'; 
        }
        return c;
    } else {
        return { column: x, order: 'asc' }
    }
}

function arrow(x, c) {
    if (c.column !== x) {
        return null;
    } else if (c.order ==='asc') {
        return <AiOutlineArrowDown />
    } else if (c.order ==='desc') {
        return <AiOutlineArrowUp />
    }
}

function TableHeader({ columns, sortColumn, onSort }) {
    return (
        <thead>
            <tr>
                {columns.map(c => <th scope="col" className={['boolean-status','link-user','link-reportmailing','link-adminreportmailing','link-usergroup','link-chain','link-region','link-dashboard','link-admindashboard','link-companydashboard'].includes(c.type) ? "clickable col-form-small" : "clickable col-form"} key={c.colname} onClick={() => onSort(changeSort(c.datalabel, sortColumn))} ><Translation>{c.colname}</Translation> {arrow(c.datalabel, sortColumn)}</th>)}
            </tr>
        </thead>
    );
}

export default TableHeader;
