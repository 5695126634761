import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { RiEdit2Line, RiDeleteBin6Line } from 'react-icons/ri'; //, RiFileCopyLine, RiCheckLine
import { ImCheckmark, ImCross} from 'react-icons/im';
const valuta = Intl.NumberFormat("nl-NL", { style: "currency",  currency: "EUR", });
const number = Intl.NumberFormat("nl-NL");
// const percentage = Intl.NumberFormat("nl-NL");
const moment = require('moment');
const format1 = 'YYYY-MM-DD';

export function calcValuta(x) {
    return valuta.format(x);
}

export function calcValutaNo0(x) {
    if (x) {
        return valuta.format(x);
    } else {
        return '-';
    }
}

export function calcNumber(x,no0=false) {
    if(no0 && !x) return '';
    return number.format(x);
}

export function formatPercentage(value, maxdec=2, locale="nl-NL") {
    if (!value) return '-';
    return Intl.NumberFormat(locale, {
        style: "percent",
        minimumFractionDigits: 0,
        maximumFractionDigits: maxdec
    }).format(value);
};

export function divideBy(x1,x2) {
    if (x2) {
        return x1/x2;
    } else {
        return 0;
    }
}

export function dateToString(d) {
    return moment(d).format(format1);
    // return `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}`;
}

export function changeCheckbox(e) {
    return (e.target.checked);
}

export function changeSelectionsNav(e, arr, group, name) {
    if (name===`all${group}`) {
        return arr.map(c => {
            c.checked = e.target.checked;
            return c;
        });
    } else {
        const arr2 = arr.map(c => {
            if (c.name === name) c.checked = e.target.checked;
            if (c.name === `all${group}`) c.checked = false;
            return c;
        });
        if (_.every(arr2.slice(1), 'checked')) arr2[0].checked = true;
        return arr2;
    }
}

export function changeFiltersNav2(e, arr, branches, group) {
    const filter = _.filter(arr, o => { return ((o.checked===e.target.checked) && (o.name !== `all${group}`)) });
    let filtered = [];
    filter.map(o => filtered = _.concat(filtered, o.branch_ids));
    return branches.map(b => {
        if (filtered.includes(b._id)){
            b[`show_${group}`] = e.target.checked;
        }
        return b;
    });
}

export function changeFiltersNav(e, arr, branches, group) {
    const filter = _.filter(arr, o => { return ((o.checked===true) && (o.name !== `all${group}`)) });
    let filtered = [];
    filter.map(o => filtered = _.concat(filtered, o.branch_ids));

    return branches.map(b => {
        if ((filtered.includes(b._id)) || (b.name === `allbranches`) ) { 
            b[`show_${group}`] = true; 
        } else {
            b[`show_${group}`] = false; 
        }
        return b;
    });
}

export function createOtherRegionChain(arr,branches) {
    let branchids = [];
    arr.map(o => branchids = _.concat(branchids, o.branch_ids));

    let otherids = [];
    branches.forEach(b => {
        if (!(branchids.includes(b._id))) { 
            otherids.push(b._id);
        }
    });

    return { _id:'other', name:'other', label:'Overig', branch_ids: otherids, checked:true, count:true };
}

export function capitalize(s)
{
    return s && s[0].toUpperCase() + s.slice(1);
}

export function formatText(x,type,f) {
    if (type === 'valuta') {
        return calcValuta(x);
    } else if (type === 'date') {
        return x ? x : <span className="badge bg-color-5 round"><ImCross/></span>;
    } else if (type === 'boolean') {
        return x.toString();
    } else if (type === 'boolean-status') {
        //const y = x.toString();
        return x ? <span className="badge bg-color-4 round"><ImCheckmark/></span> : <span className="badge bg-color-5 round"><ImCross/></span>;
    } else if (type === 'action') {
        return <button className="btn btn-sm btn-warning" onClick={() => console.log(x)}>Wijzig</button>;
    } else if (type === 'link-usergroup-old') {
        return <Link className="btn btn-sm btn-warning" to={`/manageusergroups/${x}`} >Wijzig</Link>;
    } else if (type === 'link-usergroup') {
        return <div className="div-flex">
                    <Link className="btn btn-sm btn-warning" to={`/manageusergroups/${x}`} title="Edit"><RiEdit2Line /></Link>
                    &ensp;
                    <button className="btn btn-sm btn-danger" onClick={() => f(x)} title="Delete"><RiDeleteBin6Line /></button>
            </div>;
    } else if (type === 'link-user') {
        return <div className="div-flex">
                    <Link className="btn btn-sm btn-warning" to={`/manageusers/${x}`} title="Edit"><RiEdit2Line /></Link>
                    &ensp;
                    <button className="btn btn-sm btn-danger" onClick={() => f(x)} title="Delete"><RiDeleteBin6Line /></button>
            </div>;
    } else if (type === 'link-user-1') {
        return <Link className="btn btn-sm btn-warning" to={`/manageusers/${x}`} title="Edit"><RiEdit2Line /></Link>;
    } else if (type === 'link-chain') {
        return <div className="div-flex">
                    <Link className="btn btn-sm btn-warning" to={`/managechains/${x}`} title="Edit"><RiEdit2Line /></Link>
                    &ensp;
                    <button className="btn btn-sm btn-danger" onClick={() => f(x)} title="Delete"><RiDeleteBin6Line /></button>
            </div>;
    } else if (type === 'link-region') {
        return <div className="div-flex">
                    <Link className="btn btn-sm btn-warning" to={`/manageregions/${x}`} title="Edit"><RiEdit2Line /></Link>
                    &ensp;
                    <button className="btn btn-sm btn-danger" onClick={() => f(x)} title="Delete"><RiDeleteBin6Line /></button>
            </div>;
    } else if (type === 'link-reportmailing') {
        return <div className="div-flex">
                    <Link className="btn btn-sm btn-warning" to={`/reportmailings/${x}`} title="Edit"><RiEdit2Line /></Link>
                    &ensp;
                    <button className="btn btn-sm btn-danger" onClick={() => f(x)} title="Delete"><RiDeleteBin6Line /></button>
            </div>;
    } else if (type === 'link-adminreportmailing') {
        return <div className="div-flex">
                    <Link className="btn btn-sm btn-warning" to={`/managereportmailings/${x}`} title="Edit"><RiEdit2Line /></Link>
                    &ensp;
                    <button className="btn btn-sm btn-danger" onClick={() => f(x)} title="Delete"><RiDeleteBin6Line /></button>
            </div>;
    } else if (type === 'link-dashboard') {
        return <div className="div-flex">
                    <Link className="btn btn-sm btn-warning" to={`/personaldashboards/${x}`} title="Edit"><RiEdit2Line /></Link>
                    &ensp;
                    <button className="btn btn-sm btn-danger" onClick={() => f(x)} title="Delete"><RiDeleteBin6Line /></button>
            </div>;
    } else if (type === 'link-admindashboard') {
        return <div className="div-flex">
                    <Link className="btn btn-sm btn-warning" to={`/managepersonaldashboards/${x}`} title="Edit"><RiEdit2Line /></Link>
                    &ensp;
                    <button className="btn btn-sm btn-danger" onClick={() => f(x)} title="Delete"><RiDeleteBin6Line /></button>
            </div>;
    } else if (type === 'link-companydashboard') {
        return <div className="div-flex">
                    <Link className="btn btn-sm btn-warning" to={`/managecompanydashboards/${x}`} title="Edit"><RiEdit2Line /></Link>
                    &ensp;
                    <button className="btn btn-sm btn-danger" onClick={() => f(x)} title="Delete"><RiDeleteBin6Line /></button>
            </div>;
    } else if (type === 'link-vestaboard') {
        return <div className="div-flex">
                    <Link className="btn btn-sm btn-warning" to={`/managevestaboards/${x}`} title="Edit"><RiEdit2Line /></Link>
                    &ensp;
                    <button className="btn btn-sm btn-danger" onClick={() => f(x)} title="Delete"><RiDeleteBin6Line /></button>
            </div>;
    } else if (type === 'link-vestaboardproducts') {
        return <div className="div-flex">
                    <Link className="btn btn-sm btn-warning" to={`/managevestaboardproducts/${x}`} title="Edit"><RiEdit2Line /></Link>
                    &ensp;
                    <button className="btn btn-sm btn-danger" onClick={() => f(x)} title="Delete"><RiDeleteBin6Line /></button>
            </div>;
    } else if (type === 'object-usergroups') {
        if (x.length === 1){
            return x[0]['name'];
        } else {
            return `${x.length} groups`;
        }
    } else if (type === 'object-count') {
        return x ? `${x.length}` : 0;
    } else {
        return x;
    }
}

export function classWidth(w) {
    return `col-lg-${w}`;
}

export function correctDatetime(date) {
    return moment(date,format1).format(format1);
}

export function getBetweenDates(startdate,enddate) {
    // Returns different date types with parameter 'date' as starting point.
    let formaty = 'YYYY-MM-DD';
    let arr = [];
    let start = moment(startdate,formaty);
    let end = moment(enddate,formaty);
    while( start.isSameOrBefore(end) ) {
        arr.push(moment(start,formaty).format(formaty));
        start.add(1, 'days');
    }
    
    return arr;
}

export function range(x,y) {
    let res = [];
    for (let i = x; i <= y; i++) {
        res.push(i);
    }
    return res;
}

export function collectionSum(data, field, filter=null, round=null) {
    let y;
    if (filter) {
        y = _.sumBy(_.filter(data, filter), function(o) { return parseFloat(o[field]) || 0; } );
    } else {
        y = _.sumBy(data, function(o) { return parseFloat(o[field]) || 0; });
    }
    if (round) y = parseFloat(y).toFixed(round);
    return y;
}

export function collectionSumInput(data, field, filter=null, round=null) {
    let y;
    if (filter) {
        y = _.sumBy(_.filter(data, filter), function(o) { return (o[field]==='0' || o[field]===0) ? 0 : parseFloat(o[field]) || ''; } );
    } else {
        y = _.sumBy(data, function(o) { return (o[field]==='0' || o[field]===0) ? 0 : parseFloat(o[field]) || ''; });
    }
    if (round) y = parseFloat(y).toFixed(round);
    return y;
}

export function weekdayname(date){
    const days = {1:'monday', 2:'tuesday', 3:'wednesday',4:'thursday',5:'friday',6:'saturday',0:'sunday'};
    return days[ moment(date).day() ];
}


export function transformWeek(date) {
    return moment(date).format('YYYY-WW').split('-').join('-W');
}

export function weekdayInOtherYear(date,yeardiff=0) {
    let yeardiff2 = yeardiff < 0 && moment(date).format('WW') === "01" && moment(date).format('DD') >= '15' ? yeardiff + 1 : yeardiff;
    let year = parseInt(moment(date).format('YYYY')) + yeardiff2;
    let week = moment(date).format('WW');
    let daynumber = parseInt(moment(date).format('E'));
    let newdate = moment(`${year}-${week}`, "YYYY-WW").isoWeekday(daynumber).format('YYYY-MM-DD');

    return newdate;
}

export function dayInOtherYear(date,yeardiff=0) {
    let newdate = moment(date).add(yeardiff,'years');
    return newdate.format('YYYY-MM-DD');
}

export function isNumeric(str) {
    if (typeof str === "number") {
        return true;
    } else if (typeof str !== "string") {
        return false;
    } else {
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
               !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }
}

export function get_selection(start,end) {
    let formaty = 'YYYY-MM-DD';

    if (start===end) {
        return 'day';
    } else if ( (moment(start,formaty).isoWeekday() === 1) && (moment(start,formaty).add(6,'days').format(formaty)===moment(end,formaty).format(formaty)) ) {
        return 'week';
    } else if ( (moment(start,formaty).startOf('month').format(formaty)===moment(start,formaty).format(formaty)) && (moment(start,formaty).endOf('month').format(formaty)===moment(end,formaty).format(formaty)) ) {
        return 'month';
    } else if ( (moment(start,formaty).startOf('year').format(formaty)===moment(start,formaty).format(formaty)) && (moment(start,formaty).endOf('year').format(formaty)===moment(end,formaty).format(formaty)) ) {
        return 'year' ;
    } else {
        return '';
    }
}

function pickOrOther0(x,other) {
    let y = {};
    for (let key in other) {
        y[key] = x[key]===false ? false : x[key] || other[key];
    }
    return y;
}

export function pickOrOther(x,other) {
    if (Array.isArray(x)) {
        return x.map((d) => pickOrOther0(d,other)) ;
    } else {
        return pickOrOther0(x,other);
    }
}

export function timeRange(start='13:00',end='16:00',step=5) {
    if (start.length !== 5 || start[2] !== ':' || end.length !== 5 || end[2] !== ':') return [];

    let y = [start];
    let time = moment(`2024-03-06 ${start}`);
    let endtime = end < start ? moment(`2024-03-07 ${end}`) : moment(`2024-03-06 ${end}`);

    while (time.isBefore(endtime)) {
        time.add(step,'minutes')
        y.push(time.format('HH:mm'));
    }
    
    return y;
}

export function timeRangeColl() {
    let times = timeRange('00:00','23:55');
    let y = times.map(t => { return { _id:t, name:t }});
    return y;
}
