import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  scales: {
    y: {
        ticks: { autoSkip: true},
    }
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'right',
    },
    title: {
      display: false,
      text: 'Chart.js Horizontal Bar Chart',
    },
  },
  interaction: {
    intersect: false,
    mode: 'y'
  }
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','September','October','November','December'];

export const data_origineel = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => Math.floor(Math.random() * 1000)),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    }
  ],
};

export default function BarChartHorizontal({ data=[], labelx='net_sales_amount', labely='branchname', max_n=null }) {
  
  // let data0 = data.map(x => {
  //   if(!x[labelx]) x[labelx]=0;
  //   return {...x, 'amount': parseFloat(x[labelx])};
  // });

  //console.log('data0:', data0);

  let data0 = _(data).groupBy(labely)
                      .map((value, key) => ({
                        label: key,
                        amount: _.sumBy(value, function(o) {
                          if(!o[labelx]) o[labelx]=0;
                          return parseFloat(o[labelx]); 
                        })
                      })).value();
  
  data0 = _.orderBy(data0, ['amount'], ['desc']);
  if (max_n) data0 = _.take(data0, max_n);
  //let colors = ['53, 162, 235','255, 92, 147','255, 194, 96', '60,212,160'];
  //let color_sel = colors[Math.floor(Math.random()*colors.length)];
  let data1 = {
    labels: data0.map((x) => x['label']),
    datasets: [
      {
        data: data0.map((x) => parseFloat(x['amount'])),
        borderColor: `rgb(53, 162, 235)`,
        backgroundColor: `rgba(53, 162, 235, 0.7)`,
        // borderColor: `rgb(${color_sel})`,
        // backgroundColor: `rgba(${color_sel}, 0.5)`,
      }
    ]
  };
  //console.log(data_origineel);

  return <Bar options={options} height={225} data={data1} />;
}

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: labels.map(() => Math.floor(Math.random() * 1000)),
//       borderColor: 'rgb(255, 99, 132)',
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },
//     {
//       label: 'Dataset 2',
//       data: labels.map(() => Math.floor(Math.random() * 1000)),
//       borderColor: 'rgb(53, 162, 235)',
//       backgroundColor: 'rgba(53, 162, 235, 0.5)',
//     },
//   ],
// };

