import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import _ from 'lodash';
import moment from 'moment';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data_origineel = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.7)',
        'rgba(54, 162, 235, 0.7)',
        'rgba(255, 206, 86, 0.7)',
        'rgba(75, 192, 192, 0.7)',
        'rgba(153, 102, 255, 0.7)',
        'rgba(255, 159, 64, 0.7)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export function PieChartOrigineel() {
  return <Pie data={data_origineel} />;
}

function formatHours(key) {
  return moment.utc(key*3600*1000).format('HH:mm');
}

function department(key) {
  if (key < '17:00') {
    return '1. Tot 17:00';
  } else {
    return '2. Na 17:00';
  }
}

export default function PieChart({ data=[], labelx='net_sales_amount', labely='branchname', max_n=null }) {
  
  if(labely==='business_hour') {
    data.map(x => x['part'] = department(formatHours(x[labely])) );
  } else {
    data.map(x => x['part'] = x[labely] );
  }

  let data0 = _(data).groupBy('part')
                      .map((value, key) => ({
                        label: key,
                        amount: _.sumBy(value, function(o) {
                          if(!o[labelx]) o[labelx]=0;
                          return parseFloat(o[labelx]); 
                        })
                      })).value();
  
  data0 = _.orderBy(data0, ['label'], ['asc']);
  if (max_n) data0 = _.take(data0, max_n);
  
  let data1 = {
    labels: data0.map((x) => x['label']),
    datasets: [
      {
      data: data0.map((x) => parseFloat(x['amount'])),
      // borderColor: `rgb(53, 162, 235)`,
      // backgroundColor: `rgba(53, 162, 235, 0.7)`,
      backgroundColor: [
        'rgba(54, 162, 235, 0.7)',
        'rgba(255, 99, 132, 0.7)',
        'rgba(255, 206, 86, 0.7)',
        'rgba(75, 192, 192, 0.7)',
        'rgba(153, 102, 255, 0.7)',
        'rgba(255, 159, 64, 0.7)',
      ],
      borderColor: [
        'rgba(255, 255, 255, 1)'
      ],
      borderWidth: 1,
      }
    ]
  };
  return <Pie data={data1} />;
}
