import red from './assets/marker-r.png';
import blue from './assets/marker-b.png';
import { Icon } from 'leaflet';
const icon = {
    'red':red,
    'blue':blue
};

function IconColor(color) {

    return new Icon({ 
        iconUrl: icon[color],
        iconSize: [40,40],
        iconAnchor: [20,40],
        popupAnchor:  [0, -40]
    });
}

export default IconColor;
