import http from './httpService';

const apiUrl = "/users";

function userUrl(id) {
    return `${apiUrl}/${id}`;
}

export function getUsers() {
    return http.get(apiUrl);
}

export function getUser(id) {
    return http.get(userUrl(id));
}

export function updateUser({id, data}) {
    const body = {...data};
    
    return http.put(userUrl(id), body);
}

export function saveUser({data}) {
    const body = {...data};
    
    return http.post(apiUrl,body);
}

export function deleteUser(id) {
    return http.delete(userUrl(id) );
}
