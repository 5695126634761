import React, { useContext } from 'react';
import { t } from '../../services/translationService';
import LanguageContext from '../../context/languageContext';

const SearchBox = ({ value, onChange, addButton }) => {
    let { lng } = useContext(LanguageContext);
    return ( 
        <div className={ addButton ? "input-group search-plus" : "input-group"}>
            <input 
                type="text" 
                name="query" 
                className="form-control" 
                placeholder={`${t('search',lng )}...`}
                value={value} 
                onChange={e => onChange(e.currentTarget.value)}
            />
            {/* <Link className="btn btn-sm btn-success" type="button" to={`${addButton}`} >+ Add</Link> */}
            {/* { addButton ? <Link className="btn btn-success" to={`${addButton}`} >+</Link> : null } */}
        </div>
     );
}

export default SearchBox;
