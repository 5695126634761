import React from 'react';
import _ from 'lodash';
import { formatText } from '../../services/calculateService';

// data = collection (array with objects)
// columns = collection, and the objects should contain keys 'colname','datalabel','type'
// keyid = keyid for every row

function TableBody({data, columns, keyid, sortColumn, search, onDelete}) {
    // Sort data by sortColumn
    const sorted = _.orderBy(data, [sortColumn.column], [sortColumn.order]);
    // Filter columns for search filter
    const filterColumns = _.map(_.filter(columns,{'type':'text'}),'datalabel');
    // Filter rows by search within above filterColumns
    const filtered = _.filter(sorted, o => _.values(_.pick(o,filterColumns)).join(' ').toLowerCase().includes(search.toLowerCase()));

    return (
        <tbody>
            {
                filtered.map(row =>
                    <tr key={row[keyid]}>
                        {/* {columns.map( col => <td key={row[col.datalabel]}>{formatText(row[col.datalabel],col.type)}</td> )} */}
                        {columns.map( col => <td key={col.datalabel}>{formatText(row[col.datalabel],col.type, onDelete)}</td> )}
                    </tr>
                )
            }
        </tbody>
    );
}

export default TableBody;
