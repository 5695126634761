import React from 'react';
import { Link } from "react-router-dom";
import Joi from 'joi-browser';
import Form from './common/form';
import auth from '../services/authService';
import logger from '../services/logService';
import Translation from '../services/translationService';

class ForgotForm extends Form {
    state = {
        data: { username: ''},
        errors: {}
    };

    schema = {
        username: Joi.string().required().label('E-mail')
    };

    doSubmit = async () => {
        try {
            const { data } = this.state;
            
            const res = await auth.forgotPassword(data.username);
            console.log(res);

            window.location = "/reset-password";
            //return <Navigate to="/reset-password" />;
        }
        catch (ex) {
            if (ex.response) { //&& ex.response.status === 400) {
                const errors = {...this.state.errors};
                errors.username = ex.response.data.replace("email","E-mail");
                this.setState({ errors });
            } else {
                console.log(ex);
                logger.log(ex);
            }
        }
    };

    render() {
        return (
            <main className="container-fluid">
                <div className="container">
                    <div className="row justify-content-center">
                            <div className="card card-dp card-login">
                                <div className="card-body center-dp">
                                    <h2><Translation>password-forgot</Translation></h2>
                                    <form onSubmit={this.handleSubmit} className="my-3">
                                        {this.renderInput2("username", "email", "e-mail")}
                                        {this.renderButton("send","primary mt-2")}
                                    </form>
                                        <Link id="p-forgot" to="/login"><Translation>back-login</Translation></Link>
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            </main>
        );
    }
}

export default ForgotForm;
