import React, { useState, useContext, useEffect } from 'react';
import DateRangePicker2 from './dateRangePicker2';
import DatePicker from './datepicker';
import YearPicker from './datepickerYear';
import MonthPicker from './datepickerMonth';
import WeekPicker from './datepickerWeek';
import DateContext from '../../context/dateContext';
import Translation from '../../services/translationService';
import moment from 'moment';
import { MdShowChart } from "react-icons/md";

const format1 = 'DD-MM-YYYY';

function DashboardTitle({ title, selections=['day','week','month','year','period'], trend2=false, showtrend2, trends2active }) {
    const date = useContext(DateContext);
    const [selection, setSelection] = useState(selections[0]);
    const [show, setShow] = useState(false);

    function date_to_str() {
        const start = moment(date.startDate).format(format1);
        const end = moment(date.endDate).format(format1);

        if (start === end) {
            return start;
        } else {
            return `${start} t/m ${end}`;
        }
    }

    useEffect(() => {
        setShow(false);
      }, [date.endDate]);

    function updateSelection(s) {
        setSelection(s);
    }

    function toggleShow() {
        setShow(!show);
    }

    function dontShow() {
        setShow(false);
    }

    return (
        <div className="card card-dp">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6">
                        <p className="card-dp-h1"><Translation>{title}</Translation></p>
                    </div>
                    <div className="col-md-6 text-end calendars">

                        { trend2 && <button className="btn btn-no-outline" onClick={showtrend2}><MdShowChart className={`icon-trend2 ${trends2active ? 'color-4' : '' }`} title="Add trend of last year"/></button> }
                        
                        <div className="btn-group">

                            { selection === 'week' ? <WeekPicker/> : null }
                            { selection === 'month' ? <MonthPicker/> : null }
                            { selection === 'year' ? <YearPicker/> : null }
                            { ['period','day'].includes(selection) ? <button type="button" className="btn btn-no-outline dropdown-toggle border-b-3 m-r-5" onClick={toggleShow}><p className="p-date-2">{date_to_str()}</p></button> : null }
                        </div>
                            
                        <div className="btn-group">
                            <button type="button" className="btn btn-no-outline dropdown-toggle border-b-3" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false" onClick={dontShow}><p className="p-date-2"><Translation>{selection}</Translation></p></button>
                            <ul className="dropdown-menu dropdown-menu-end">
                                { selections.map(s =>  <button className="dropdown-item" type="button" key={s} onClick={() => updateSelection(s)}><Translation>{s}</Translation></button> ) }
                            </ul>
                        </div>

                    { ['period','day'].includes(selection) ?
                        <div className={show ? "collapse card-picker show mw-100p" : "collapse card-picker"}>
                            <div className="card card-body">
                                { selection === 'period' ? <DateRangePicker2 /> : null }
                                { selection === 'day' ? <DatePicker/> : null }
                            </div>
                        </div> : null }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashboardTitle;
