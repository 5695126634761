import React from 'react';
import PageTitle from './common/PageTitle';

function ManageTemplate() {
    return (
        <main className="container-fluid">
            <PageTitle title="Manage Template"/>
        </main>
    );
}

export default ManageTemplate;
