import React from 'react';
import _ from 'lodash';
import { calcValuta, calcNumber } from '../../../services/calculateService';

function sum(data,labelx) {
    return _.sumBy(data, function(x) { return (x[labelx] != null) ? parseFloat(x[labelx]) : 0; })
}

function TotalNumber({ data, labelx='net_sales_amount', unit='valuta', formula }) {
    let total;
    let values = labelx.split(",");

    // Formulas
    if ((formula==='average') && (values.length>1)) {
        total = sum(data,values[0]) / sum(data,values[1]);
    } else {
        //total = _.sumBy(data, function(x) { return (x[labelx] != null) ? parseFloat(x[labelx]) : 0; });
        total = sum(data,values[0]);
    }

    // Number formatting
    if (unit==='valuta') {
        total = calcValuta(total);
    } else if (unit==='number') {
        total = calcNumber(total);
    } else if (unit==='integer') {
        total = calcNumber(Math.round(total));
    }

    return (
        <div className="widget-total">
            {/* { unit==='valuta' ? calcValuta(total) : total } */}
            { total }
        </div>
    );
}

export default TotalNumber;
