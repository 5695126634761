import React, { useState, useEffect, useContext } from 'react';
import { DateRange } from 'react-date-range';
import { nl } from 'react-date-range/src/locale/index';
import DateContext from '../../context/dateContext';

const DateRangePicker2 = () => {
    const dateContext = useContext(DateContext);
    const [startDate, setStartDate] = useState( new Date() );
    const [endDate, setEndDate] = useState( new Date() );
    const [bool, setBool] = useState( true );
    
    useEffect(() => {
        // bool for only updating when the enddate is given (so not when startdate is given);
        if (bool) {
            dateContext.changeDaterange(startDate,endDate);
        }
    }, [bool]);

    function changeRange(range) {
        setStartDate(range.startDate);
        setEndDate(range.endDate); 
        setBool( !bool );
    }
 
    return (
        <DateRange
            onChange={item => {
                changeRange(item.selection);
            }}
            showSelectionPreview={false}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={[{startDate: startDate, endDate: endDate, key:'selection'}]}
            direction="horizontal"
            locale={nl}
            preventSnapRefocus={false}
            // weekStartsOn={1}
        />
    );
}

export default DateRangePicker2;
