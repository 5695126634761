import React from 'react';
import { toastSuccess, toastError } from '../services/toastService';
import Joi from 'joi-browser';
import Form from './common/form';
import PageTitle from './common/PageTitle';
import { getUser, updateUser, saveUser } from '../services/userService';
import logger from '../services/logService';
import Translation from '../services/translationService';

class UserForm extends Form {
    state = {
        data: { email: '', full_name:'', role_id: '', is_active: true, usergroups: []},
        id: '',
        c_usergroups: [],
        c_roles: [],
        errors: {}
    }

    schema = {
        email: Joi.string().min(5).max(255).email().required().label('E-mail'),
        full_name: Joi.string().min(1).max(255).required().label('Name'),
        role_id: Joi.string().required().label('Role'),
        is_active: Joi.boolean().required().label('Active'),
        usergroups: Joi.array().items(Joi.string())
    };
    
    async componentDidMount() {
        const userId = window.location.href.split('manageusers/')[1];
        const { data } = await getUser(userId);
        
        if (userId === "new") {
            this.setState({ c_usergroups: data.c_usergroups, c_roles: data.c_roles });
        } else {
            this.setState({ id: userId, c_usergroups: data.c_usergroups, c_roles: data.c_roles, data: { email: data.email, full_name: data.full_name, role_id: data.role_id, is_active: data.is_active, usergroups:data.usergroups } });
            //this.setState({ id: userId, data: { name: 'test name', description: 'test description', is_active: true } });
        }
    }

    doSubmit = async () => {
        if (window.location.href.split('manageusers/')[1] === 'new') {
            try {
                await saveUser(this.state);
                window.location = "/manageusers";
            }
            catch (ex) {
                if (ex.response) {
                    toastError(ex.response.data);
                }
                logger.log(ex);
            }
        } else {
            try {
                await updateUser(this.state);
                toastSuccess(<Translation>saved-success</Translation>);
            }
            catch (ex) {
                if (ex.response) {
                    toastError(ex.response.data);
                }
                logger.log(ex);
            }

        }
    }

    render() {
        const boolnew = window.location.href.split('manageusers/')[1] === "new";
        return (
            <main className="container-fluid">
                {/* <PageTitle title="User"/> */}
                <PageTitle title="user" saveButton={{label: 'save', div: "d-md-flex justify-content-md-end", validate: this.validate, submit: this.handleSubmit}} />
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-dp">
                            <div className="card-body">
                                <form onSubmit={this.handleSubmit} >
                                    { boolnew ? this.renderInput("email", "email", "text") : this.renderDisabled("email", "email", "text")}
                                    {this.renderInput("full_name", "name", "text")}
                                    {this.renderSelect("role_id","role" , this.state.c_roles )}
                                    {this.renderCheckbox("is_active", "active")}
                                    {this.renderSelectForm("c_usergroups", "usergroup", "usergroups", "name")}
                                    {this.renderButton("save")}
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </main>
        );
    }
}

export default UserForm;
