import React from 'react';
import TableHeader from './tableHeader';
import TableBody from './tableBody';

const Table = ({ data, columns, keyid, sortBy }) => {
    return ( 
        <table className="table table-hover-3">
            <TableHeader columns={columns}/>
            <TableBody data={data} columns={columns} keyid={keyid} sortBy={sortBy} />
        </table>
    );
}
 
export default Table;
