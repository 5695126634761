import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { BsInfo } from 'react-icons/bs';

const IconInfo = React.forwardRef(function IconInfo(props, ref) {
    return <div {...props} ref={ref}><BsInfo className="icon-info"/></div>
});

function Info({title}) {
    return (
        <Tooltip title={title} placement="top">
            <IconInfo/>
        </Tooltip>
    );
}

export default Info;
