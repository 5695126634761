import React from 'react';
import _ from 'lodash';
import Translation from '../../services/translationService';

function InputSelectNav({ data, name, changeSelection }) {
    if (!data) return null;
    if (!(Array.isArray(data) && data.length)) return null;

    const sorted = _.orderBy(data, ['order','label'], ['asc','asc']);
    
    let count = _.filter(sorted, function(o) { return o.checked && !["allchains","allregions","allbranches"].includes(o._id); });
    let name1 = <Translation>{name}</Translation>;
    if (count.length === 1) {
        name1 = count[0].label;
    } else if (count.length > 1) {
        name1 = `${count[0].label} + ${count.length-1}`;
    }
    
    return ( 
        <div className="input-group border-b-3" key={name}>
            <button className="btn color-w dropdown-toggle btn-no-outline" type="button" id="dropdownMenuClickable" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">{name1}</button>
            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-nav">
                {sorted.map(x =>
                    <label className="dropdown-item form-check form-check-dp ps-nav" key={x._id}>
                        <input className="form-check-input" type="checkbox" checked={x.checked} value={x.name} id={`${name}-${x.name}`} onChange={(e) => changeSelection(e, name, x.name)} />
                        <label className="form-check-label" key={x._id} htmlFor={`${name}-${x.name}`} value={x.name}>{x.label}</label>
                    </label> )}
            </ul>
        </div>
     );
}

export default InputSelectNav;
