import React from 'react';
import { Link } from 'react-router-dom';
import SearchBox from './searchBox';
import SaveButton from './saveButton';
import Translation from '../../services/translationService';

function PageTitle({ title, search, onSearch, addButton, saveButton }) {
    return (
        <div className="card card-dp">
            <div className="card-body">
                <div className="row">
                    <div className="col-sm-8">
                        <p className="card-dp-h1"><Translation>{title}</Translation></p>
                    </div>
                    <div className="col-sm-4">
                    { search !== undefined ? <SearchBox onChange={onSearch} addButton={addButton} /> : null}
                    { addButton ? <Link className="btn btn-success button-add" to={`${addButton}`} >+</Link> : null }
                    { saveButton ? <SaveButton saveButton={saveButton} /> : null }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageTitle;
