import React from 'react';
import { toastError } from '../services/toastService';
import logger from '../services/logService';
import Joi from 'joi-browser';
import Form from './common/form';
import auth from '../services/authService';
import Translation from '../services/translationService';

class ResetForm extends Form {
    state = {
        data: { username: '', code: '', password1: '', password2: ''},
        errors: {}
    };

    schema = {
        username: Joi.string().required().label('E-mail'),
        code: Joi.string().min(6).max(6).required().label('Verificatie code'),
        password1: Joi.string().min(8).max(55).required().label('Wachtwoord'),
        password2: Joi.string().required().label('Herhaal wachtwoord')
    };

    doSubmit = async () => {
        try {
            const { data } = this.state;

            if (data.password1 !== data.password2) {
                const errors = {...this.state.errors};
                errors.password2 = "Wachtwoorden zijn niet hetzelfde!";
                this.setState({ errors });
            } else {
                //console.log(data.username, data.code, data.password1, data.password2);
                const res = await auth.resetPassword(data.username, data.code, data.password1);
                console.log(res);
                // window.location = "/dashboards1/1";
                window.location = "/";
            }
        }
        catch (ex) {
            if (ex.response) { //&& ex.response.status === 400) {
                const errors = {...this.state.errors};
                errors.username = ex.response.data.replace("email","E-mail");
                this.setState({ errors });
            } else {
                toastError(ex);
                logger.log(ex);
            }
        }
    };

    render() {
        //if (auth.getCurrentUser()) return <Navigate to="/" />;    // return <Navigate to="/home" replace state={state} />;
        // src={logo}

        return (
            <main className="container-fluid">
                <div className="container">
                    <div className="row justify-content-center">
                            <div className="card card-dp card-login">
                                <div className="card-body center-dp">
                                    <h2><Translation>password-change</Translation></h2>
                                    <form onSubmit={this.handleSubmit} className="my-3 text-left">
                                        <p className="p-14"><Translation>password-change-info</Translation></p>
                                        {this.renderInput2("username", "email", "e-mail")}
                                        {this.renderInput2("code", "verification-code", "text")}
                                        {this.renderInput2("password1", "passwordnew", "password")}
                                        {this.renderInput2("password2", "passwordrepeat", "password")}
                                        <p className="p-14"><Translation>password-req</Translation>:<br/>- 8 <Translation>characters</Translation><br/>- 1 <Translation>number</Translation><br/>- 1 <Translation>s-characters</Translation><br/>- 1 <Translation>uppercase</Translation><br/>- 1 <Translation>lowercase</Translation></p>
                                        {this.renderButton("change","primary mt-2")}
                                    </form>
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            </main>
        );
    }
}

export default ResetForm;
