import React, { Component } from 'react';
import PageTitle from './common/PageTitle';
import { getAdminReportmailings, deleteAdminReportmailing } from '../services/reportmailingService';
import { toastSuccess, toastError } from '../services/toastService';
import logger from '../services/logService';
import Translation from '../services/translationService';
import TableForm from './common/tableForm'; 

class ManageAdminReportmailings extends Component {
    state = {
        reportmailings: [],
        sortColumn: { column: 'name', order: 'asc' },
        search: ''
    }

    async componentDidMount() {
        const { data: reportmailings } = await getAdminReportmailings();
        reportmailings.forEach(rm => rm['mail_addresses']=rm['mail_addresses'].replaceAll(",",", "));
        this.setState({ reportmailings });
    }

    handleSort = sortColumn => {
        this.setState({ sortColumn });
    }

    onSearch = search => {
        this.setState({ search });
    }

    handleDelete = async (id) => {
        const original = this.state.reportmailings;
        const reportmailing = original.filter(x => x._id === id)[0];
        
        if (window.confirm(`'${reportmailing.name}' verwijderen?`)) {
            // OK
            this.setState({ reportmailings: original.filter(x => x._id !== id) });
            try {
                await deleteAdminReportmailing(id);
                toastSuccess(<Translation>delete-success</Translation>,1500);
            }
            catch (ex) {
                if (ex.response && ex.response.status === 404) {
                    toastError(<Translation>delete-error</Translation>);
                } else if (ex.response && ex.response.status === 400) {
                    toastError(ex.response.data);
                    logger.log(ex);
                } else {
                    logger.log(ex);
                }
                this.setState({ reportmailings: original });
            }
            return;
        } else {
            // CANCEL
            return;
        }
    }

    render() {
        const { reportmailings, sortColumn, search } = this.state;
        // let columns = [{'datalabel':'email','colname':'user', 'type':'text'},{'datalabel':'full_name','colname':'username', 'type':'text'},{'datalabel':'name','colname':'name', 'type':'text'},{'datalabel':'mail_addresses','colname':'receivers', 'type':'text'},{'datalabel':'is_active','colname':'status', 'type':'boolean-status'},{'datalabel':'_id','colname':'action', 'type':'link-adminreportmailing'}];
        let columns = [{'datalabel':'name','colname':'name', 'type':'text'},{'datalabel':'mail_addresses','colname':'receivers', 'type':'text'},{'datalabel':'is_active','colname':'status', 'type':'boolean-status'},{'datalabel':'_id','colname':'action', 'type':'link-adminreportmailing'}];
        return (
            <main className="container-fluid overflow-auto">
                <PageTitle title="manage-reportmail" search={search} onSearch={this.onSearch} addButton={'new'} />
                <TableForm data={reportmailings} keyid={"_id"} columns={columns} sortColumn={sortColumn} onSort={this.handleSort} onDelete={this.handleDelete} search={search} />
            </main>
        );
    }
}

export default ManageAdminReportmailings;
