import React, { Component } from 'react';
import _ from 'lodash';
import Gismap from '../common/gis/gismap';

class Dashboards extends Component {
    state = {};

    render() {
        const locations = _.filter(this.props.locations, o => { return this.props.branches.includes(o.name) } );
        // const locations= this.props.locations;

        return (
            <main className="container-fluid nul">
                <div className="row gis-div nul">
                    <Gismap locations={locations} />
                </div>
            </main>
        );
    }
}

export default Dashboards;
