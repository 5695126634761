import React from 'react';
import _ from 'lodash';
import { formatText } from '../../services/calculateService';
import Translation from '../../services/translationService';
import { t } from '../../services/translationService';

function InputSelectForm({ name, data, label, listName, list, showName, onChange, allChange, activecolumn=true }) {
    if (!data) return null;
    if (!(Array.isArray(data) && data.length)) return null;
    
    data.map(d => {
        if (!d['order']) d['order'] = t(d[showName]);
        return d;
    });
    const sorted = _.orderBy(data, ['order'], ['asc']);

    return (
        <div className="input-group mb-3">
            <table className="table">
                <thead>
                    <tr><th scope="col" className="col-form-15">
                        <input className="form-check-input" type="checkbox" checked={list.length >= sorted.length ? true : false} id="flexCheckChecked" onChange={(e) => allChange(e,listName,data.map(d => d._id))} />&ensp;<span className="th-selectall">(<Translation>{list.length >= sorted.length ? 'deselectall' : 'selectall'}</Translation>)</span>    
                        </th><th scope="col"><Translation>{label}</Translation>{` (${list.length}/${sorted.length})`}</th>{ activecolumn ? <th scope="col" className="col-form-15"><Translation>active</Translation></th> : null}</tr>
                </thead>
                <tbody>
                    {sorted.map(x => <tr key={x._id} value={x._id}><td><input className="form-check-input" type="checkbox" checked={list.includes(x._id)} id="flexCheckChecked" onChange={(e) => onChange(e, x._id, listName)} /></td><td><Translation>{x[showName]}</Translation></td>{ activecolumn ? <td>{formatText(x.is_active,'boolean-status')}</td> : null}</tr> )}
                </tbody>
            </table>
        </div>
    );
}

export default InputSelectForm;
