import React from 'react';
import Translation from '../../services/translationService';

function TableHeader({ columns }) {
    return (
        <thead>
            <tr>
                {columns.map(c => <th scope="col" key={c.colname} ><Translation>{c.colname}</Translation></th>)}
            </tr>
        </thead>
    );
}

export default TableHeader;
