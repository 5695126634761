import React from 'react';
import { toastSuccess, toastError } from '../services/toastService';
import logger from '../services/logService';
import Joi from 'joi-browser';
import Form from './common/form';
import PageTitle from './common/PageTitle';
import { getRegion, updateRegion, saveRegion } from '../services/regionService';
import Translation from '../services/translationService';

class RegionForm extends Form {
    state = {
        data: { label:'', description: '', is_active: true, branch_ids:[] },
        id: '',
        branches: [],
        errors: {}
    }

    schema = {
        label: Joi.string().required().min(1).label('Naam'),
        description: Joi.string().empty('').max(255).label('Description'),
        is_active: Joi.boolean().required().label('Active'),
        branch_ids: Joi.array().items(Joi.string())
    };
    
    async componentDidMount() {
        const regionId = window.location.href.split('manageregions/')[1];
        const { data } = await getRegion(regionId);

        if (regionId === "new") {
            this.setState({ branches: data.branches });
        } else {
            this.setState({ id: regionId, branches: data.branches, data: { label:data.label, description: data.description, is_active: data.is_active, branch_ids: data.branch_ids } });
        }
    }

    doSubmit = async () => {
        if (window.location.href.split('manageregions/')[1] === 'new') {
            try {
                await saveRegion(this.state);
                window.location = "/manageregions";
            }
            catch (ex) {
                if (ex.response) {
                    toastError(ex.response.data);
                }
                logger.log(ex);
            }
        } else {
            try {
                await updateRegion(this.state);
                toastSuccess(<Translation>saved-success</Translation>);
            }
            catch (ex) {
                if (ex.response) {
                    toastError(ex.response.data);
                }
                logger.log(ex);
            }
        }
    }

    render() {
        // const boolnew = window.location.href.split('manageregions/')[1] === "new";
        return (
            <main className="container-fluid">
                <PageTitle title="region" saveButton={{label: 'save', div: "d-md-flex justify-content-md-end", validate: this.validate, submit: this.handleSubmit}} />
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-dp">
                            <div className="card-body">
                                <form onSubmit={this.handleSubmit} >
                                    {this.renderInput("label", "name", "text")}
                                    {this.renderInput("description", "description", "text")}
                                    {this.renderCheckbox("is_active", "active")}
                                    {this.renderSelectForm("branches", "branch", "branch_ids", "label")}
                                    {this.renderButton("save")}
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </main>
        );
    }
}

export default RegionForm;
