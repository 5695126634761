import React from 'react';
// import _ from 'lodash';
// import { t } from '../../services/translationService';
// import LanguageContext from '../../context/languageContext';

const InputSelectArray = ({ name, options, sort=true, ...rest }) => {
    // let { lng } = useContext(LanguageContext);
    
    // let arr = options.map(o => t(o,lng) );
    let arr = [...options];
    if (sort) arr.sort();

    return ( 
            <select name={name} id={name} {...rest} className="form-select" aria-label="Default select">
                <option default value=""></option>
                {arr.map(option => (
                    <option key={option} value={option}>{option}</option>
                ))}
            </select>
     );
}
 
export default InputSelectArray;
