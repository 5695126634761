import http from './httpService';

// PERSONAL DASHBOARDS NORMAL USERS
const apiUrl = "/dashboards";

function userUrl(id) {
    return `${apiUrl}/${id}`;
}

export function getDashboards() {
    return http.get(apiUrl);
}

export function getDashboard(id) {
    return http.get(`${apiUrl}/${id}`);
}

export function updateDashboard({id, data}) {
    const body = {...data};
    
    return http.put(userUrl(id), body);
}

export function saveDashboard({data}) {
    const body = {...data};
    
    return http.post(apiUrl, body);
}

export function deleteDashboard(id) {
    return http.delete(userUrl(id) );
}

// PERSONAL DASHBOARDS ADMIN USERS
const apiAdminUrl = "admin/dashboards";

export function getAdminDashboards() {
    return http.get(apiAdminUrl);
}

export function getAdminDashboard(id) {
    return http.get(`${apiAdminUrl}/${id}`);
}

export function updateAdminDashboard({id, data}) {
    const body = {...data};
    return http.put(`${apiAdminUrl}/${id}`, body);
}

export function saveAdminDashboard({data}) {
    const body = {...data};
    return http.post(apiAdminUrl, body);
}

export function deleteAdminDashboard(id) {
    return http.delete(`${apiAdminUrl}/${id}`);
}

// COMPANY DASHBOARDS ADMIN USERS
const apiCompanyUrl = "admin/companydashboards";

export function getCompanyDashboards() {
    return http.get(apiCompanyUrl);
}

export function getCompanyDashboard(id) {
    return http.get(`${apiCompanyUrl}/${id}`);
}

export function updateCompanyDashboard({id, data}) {
    const body = {...data};
    return http.put(`${apiCompanyUrl}/${id}`, body);
}

export function saveCompanyDashboard({data}) {
    const body = {...data};
    return http.post(apiCompanyUrl, body);
}

export function deleteCompanyDashboard(id) {
    return http.delete(`${apiCompanyUrl}/${id}`);
}
