import _ from 'lodash';
import { useState, useEffect } from 'react';
import { calcBarHorizontal } from './calculateKPI';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChartHorizontalClick = ({ data: data0, options }) => {
  const [filter, setFilter] = useState('');
  const [filters, setFilters] = useState([]);
  const [data, setData] = useState([]);
  let ysplit = options.y.split(',');
  let optionsy = (filter && ysplit.length > 1) ? ysplit[1] : ysplit[0];

  useEffect(() => {
    setFilter('');
    let filterslist = _.uniq( _.map(data0,optionsy) );
    setFilters(filterslist);
    setData(data0);
  }, [data0]);

  if (!data) return null;
  
  let data1 = _.filter(data, function(o) { return o.product_name && o.product_group_name; });
  let filtered = (filter && ysplit.length > 1) ? data1?.filter(d => d.product_group_name === filter) : data1;
  let calcdata = calcBarHorizontal(filtered, options.x, optionsy, options.max_n, true);
  
  let datain = {
    labels: calcdata.map((x) => x['label']),
    datasets: [
      {
        data: calcdata.map((x) => parseFloat(x['amount'])),
        show: calcdata.map((x) => x['show'] ),
        borderColor: `rgb(53, 162, 235)`,
        backgroundColor: `rgba(53, 162, 235, 0.7)`
      }
    ]
  };

  let options1 = {
    indexAxis: 'y',
    elements: { bar: {cborderWidth: 1 } },
    scales: { y: { ticks: { autoSkip: true} }},
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {display: false,position: 'right',},
      title: {display: false,text: 'Chart'},
      tooltip: {
        callbacks: {
          label: function(context) {
            let show = context.dataset.show[context.dataIndex];
            return show ? show : context.formattedValue; 
          }
        }}
    },
    interaction: {intersect: false,mode: 'y'},
    onClick: (e) => {
      let newfilter = e.chart.tooltip.title[0];
      filter === '' && filters.includes(newfilter) ? setFilter(newfilter) : setFilter('');
    }
  };

  return (
      <Bar className="chart" options={options1} height={225} data={datain} redraw={filter ? true : false} /> // redraw={true}
  );
}

export default BarChartHorizontalClick;
