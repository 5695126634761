import jwtDecode from 'jwt-decode';
import http from './httpService';

//const apiUrl = "/auth";
const tokenKey = "dp-token";
const dataKey = "dp-userdata"
http.setJwt(getJwt());

export async function login(email, password) {
    const { data } = await http.post('/auth/login', { email, password });
    localStorage.setItem(tokenKey, data.token);
    //localStorage.setItem(dataKey, JSON.stringify(data));
}

export async function forgotPassword(email) {
    const { data } = await http.post('/auth/forgot-password', { email });
    return data;
}

export async function resetPassword(email,code,password) {
    const { data } = await http.post('/auth/reset-password', { email, code, password });
    localStorage.setItem(tokenKey, data.token);
}

export function loginWithJwt(jwt) {
    localStorage.setItem(tokenKey, jwt);
}

export function logout() {
    localStorage.removeItem(tokenKey);
    //localStorage.removeItem(dataKey);
}

export function getCurrentUser() {
    try {
        const jwt = localStorage.getItem(tokenKey);
        return jwtDecode(jwt);
    }
    catch (ex) {
        //console.log('Niet ingelogd!');
        return null;
    }
}

export function getUserDataLocal() {
    try {
        return localStorage.getItem(dataKey);
    }
    catch (ex) {
        console.log('getUserData niet gelukt!');
    }
}

export function getUserData() {
    return http.get('/auth/me');
}

export function saveProfile(user) {
    const body = {...user};
    
    return http.put('/auth/me', body);
}

export function getJwt() {
    return localStorage.getItem(tokenKey);
}

export function isManagerOrAdmin() {
    try {
        const user = getCurrentUser();

        return ["5620dd57df4e1f031a7b23cb","62874017a8f8f265e99de593"].includes(user.role);
    }
    catch (ex) {
        //console.log('Niet ingelogd!');
        return null;
    }
}

const exports = {
    login,
    loginWithJwt,
    logout,
    getCurrentUser,
    getUserData,
    saveProfile,
    isManagerOrAdmin,
    getJwt,
    forgotPassword,
    resetPassword
}

export default exports;
