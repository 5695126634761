import React, { useState, useEffect } from 'react';
import { toastSuccess, toastWarning, toastError } from '../../services/toastService';
import _, { set } from 'lodash';
import DashboardTitle from '../common/dashboardTitle';
import InputText from '../common/inputText';
import { getData, getNotes, updateNote, saveNote } from '../../services/dataService';
import { getBetweenDates, collectionSum, weekdayname, calcValutaNo0, transformWeek, calcNumber, weekdayInOtherYear, formatPercentage, divideBy } from '../../services/calculateService';
import logger from '../../services/logService';
import Translation from '../../services/translationService';
import Spinner from '../common/spinner';
import moment from 'moment';
// import TableInputForm from '../common/charts2/tableInputForm';
const format1 = 'YYYY-MM-DD';
const format2 = 'D-M-YYYY';
const note_config_opu = '5fbcca4db31b0e9e018b4567';
const note_config_rev = '5fbcca91b31b0e9d018b4567';
const dbname = 'Toelichting LM op OMZET en OPU';

function Dashboard({locations, startDate, endDate}) {
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [data4, setData4] = useState([]);
    const [data5, setData5] = useState([]);
    const [data6, setData6] = useState([]);
    const [data7, setData7] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [branch, setBranch] = useState({});
    const [notes, setNotes] = useState([]);
    const [branchnotes, setBranchnotes] = useState([]);
    const [notes2, setNotes2] = useState([]);
    const [branchnotes2, setBranchnotes2] = useState([]);
    const [dates, setDates] = useState([]);
    const [datesprev, setDatesprev] = useState([]);
    const [updated, setUpdated] = useState(0);
    const [loading, setLoading] = useState(false);
    const [skip, setSkip] = useState(0);

    async function updateData() {
        setLoading(true);
        let startstr = moment(startDate).format(format1);
        let endstr = moment(endDate).format(format1);
        console.log('updateData', startstr,endstr);

        let startprevstr =  weekdayInOtherYear(startstr,-1);
        let endprevstr =  weekdayInOtherYear(endstr,-1);

        const { data } = await getData(startstr,endstr,['planned_hours','clocked_hours','sales_planning_input','revenue_per_day','labour_emp_day']);
        const { data: data2 } = await getData(startprevstr,endprevstr,['clocked_hours','revenue_per_day']);

        let udepartments = _.uniq([..._.map(data['planned_hours'], 'department' ),..._.map(data['clocked_hours'], 'department' )]);
        udepartments.sort();
        setDepartments(udepartments.filter(x => x));

        const { data: datanotes } = await getNotes(startstr,endstr, note_config_opu);
        const { data: datanotes2 } = await getNotes(startstr,endstr, note_config_rev);
        const dates = getBetweenDates(startstr,endstr);
        const datesprev = getBetweenDates(startprevstr,endprevstr);
        setData(data['planned_hours']);
        setData2(data['clocked_hours']);
        setData3(data['sales_planning_input']);
        setData4(data['revenue_per_day']);
        setData5(data2['clocked_hours']);
        setData6(data2['revenue_per_day']);
        setData7(data['labour_emp_day']);
        setNotes(datanotes);
        setNotes2(datanotes2);
        setDates(dates);
        setDatesprev(datesprev);
        setUpdated(updated+1);
        setLoading(false);
    }

    function selectBranch() {
        const first = _.sortBy(locations.filter(l => l.name !=='allbranches') , ['label'])[0];
        setBranch(first || {});

        let data2;
        if (first) data2 = _.filter(notes, n => { 
            if (n.frequency === 'bd') {
                n.date = moment(n.note_date).format('YYYY-MM-DD');
            } else if (n.frequency === 'w') {
                n.date = n.note_date;
            }
            return n.branch === first.name;
        } );
        setBranchnotes( data2 ? _.cloneDeep(data2) : [] );

        let data3;
        if (first) data3 = _.filter(notes2, n => { 
            if (n.frequency === 'bd') {
                n.date = moment(n.note_date).format('YYYY-MM-DD');
            } else if (n.frequency === 'w') {
                n.date = n.note_date;
            }
            return n.branch === first.name;
        } );
        setBranchnotes2( data3 ? _.cloneDeep(data3) : [] );
    }

    useEffect(() => {
        // skip first because of the update of dateContext in opening new datepicker component
        if (skip) updateData();
        setSkip(skip+1);
    }, [endDate]);

    useEffect(() => {
        selectBranch();
    }, [locations,updated]);

    const saveChanges = async () => {
        if (branch.name) {
            setLoading(true);
            let noerrors = true;
    
            // Chenk notes changed
            let checknotes = notes.filter(o => o.branch === branch.name);
            let changed1 = [];
            let newbranchnotes = [];
            branchnotes.map(bn => {
                let x1 = _.find(checknotes, { _id:bn._id });
                if ((!x1) || (bn.note !== x1.note)) {
                    changed1.push(bn);
                } else {
                    newbranchnotes.push(bn);
                }
            });
    
            // Notes
            if (changed1.length) {
                for (let i = 0; i < changed1.length; i++) {
                    let c = changed1[i];
                    try {
                        if (c._id) {
                            await updateNote(c._id,{ note: c.note });
                            newbranchnotes.push(c);
                        } else {
                            const { data: newnote } = await saveNote({ branch_id: branch._id, notes_configuration_id: note_config_opu, frequency: c.frequency, note_date: c.date, note: c.note });
                            c._id = newnote._id;
                            newbranchnotes.push(c);
                        }
                    }
                    catch (ex) {
                        if (ex.response) {
                            toastError(ex.response.data);
                        }
                        noerrors = false;
                        logger.log(ex);
                    }
                }
    
                // Update notes with updates
                let newnoteids = _.map(newbranchnotes, '_id');
                let newnotes = notes.filter(n => !newnoteids.includes(n._id));
                setNotes( newnotes.concat(newbranchnotes) );
    
                // Update branchnotes with updates
                setBranchnotes(newbranchnotes);
            }

            // Chenk notes2 changed
            let checknotes2 = notes2.filter(o => o.branch === branch.name);
            let changed2 = [];
            let newbranchnotes2 = [];
            branchnotes2.map(bn => {
                let x1 = _.find(checknotes2, { _id:bn._id });
                if ((!x1) || (bn.note !== x1.note)) {
                    changed2.push(bn);
                } else {
                    newbranchnotes2.push(bn);
                }
            });
    
            // Notes
            if (changed2.length) {
                for (let i = 0; i < changed2.length; i++) {
                    let c = changed2[i];
                    try {
                        if (c._id) {
                            await updateNote(c._id,{ note: c.note });
                            newbranchnotes2.push(c);
                        } else {
                            const { data: newnote } = await saveNote({ branch_id: branch._id, notes_configuration_id: note_config_rev, frequency: c.frequency, note_date: c.date, note: c.note });
                            c._id = newnote._id;
                            newbranchnotes2.push(c);
                        }
                    }
                    catch (ex) {
                        if (ex.response) {
                            toastError(ex.response.data);
                        }
                        noerrors = false;
                        logger.log(ex);
                    }
                }
    
                // Update notes with updates
                let newnoteids2 = _.map(newbranchnotes2, '_id');
                let newnotes2 = notes2.filter(n => !newnoteids2.includes(n._id));
                setNotes2( newnotes2.concat(newbranchnotes2) );
    
                // Update branchnotes with updates
                setBranchnotes2(newbranchnotes2);
            }

            // Notifications
            if (changed1.length + changed2.length < 1) {
                toastWarning(<Translation>no-changes</Translation>);
            } else if (noerrors){
                toastSuccess(<Translation>changes-made</Translation>);
            }
    
            setLoading(false);
        } else {
            toastWarning(<Translation>no-branch</Translation>);
        }
    }

    function filterNote(date) {
        let notes1 = _.filter(branchnotes, {date: date});
        let y = '';
        if (notes1.length) y = notes1[0].note;
        return y;
    }

    function filterNote2(date) {
        let notes2t = _.filter(branchnotes2, {date: date});
        let y = '';
        if (notes2t.length) y = notes2t[0].note;
        return y;
    }

    function noteChange(event) {
        let value = event.target.value.substring(0, 250) || '';
        let date = event.target.name;
        let frequency = event.target.getAttribute('frequency');

        let tempbranch = _.cloneDeep(branchnotes);
        let bool = true;
        for (let i = 0; i < tempbranch.length; i++) {
            let td = tempbranch[i];
            if (td.date===date) {
                tempbranch[i].note = value;
                bool = false;
            }
        }
        if (bool) tempbranch.push({_id:'', branch: branch.name, date:date, frequency: frequency, note: value, note_date:date });

        setBranchnotes(tempbranch);
    }

    function noteChange2(event) {
        let value = event.target.value.substring(0, 250) || '';
        let date = event.target.name;
        let frequency = event.target.getAttribute('frequency');

        let tempbranch = _.cloneDeep(branchnotes2);
        let bool = true;
        for (let i = 0; i < tempbranch.length; i++) {
            let td = tempbranch[i];
            if (td.date===date) {
                tempbranch[i].note = value;
                bool = false;
            }
        }
        if (bool) tempbranch.push({_id:'', branch: branch.name, date:date, frequency: frequency, note: value, note_date:date });

        setBranchnotes2(tempbranch);
    }

    return (
        <main className="container-fluid">
            { loading ? <div className="loading center-dp "><Spinner /></div> : null}
            <DashboardTitle title={dbname} selections={['week']} />
            <div className="row">
                <div className="col-lg-12">
                    <div className="card card-dp">
                        <div className="card-body overflow-x-auto">
                            <p className="card-dp-h2 margin-b-15">{branch ? branch.label : ''}</p>
                            <table className="table font-size-14">
                                <thead>
                                    <tr>
                                    <th scope="col"></th>
                                    {dates.map(c => <th scope="col" key={`col-${c}`}>{moment(c,format1).format(format2)}<br/><Translation>{weekdayname(c)}</Translation></th>)}
                                    <th scope="col">Totaal</th>
                                    </tr>
                                </thead>
                                <tbody className="table-input">
                                    <tr><th>Geplande uren</th><td colSpan={dates.length+1}></td></tr>
                                    {departments.map(l => 
                                        collectionSum(data, 'planned_hours', {branch: branch.name, department: l}) ? 
                                        <tr key={l}><th scope="row" className="p-font-n">{l}</th>
                                        {dates.map(c => <td key={`1-${l}-${c}`}>{calcNumber(collectionSum(data, 'planned_hours', {business_date:c, branch: branch.name, department: l}),true)}</td>)}
                                        <td key={`1-${l}-total`}>{calcNumber(collectionSum(data, 'planned_hours', {branch: branch.name, department: l}))}</td>
                                        </tr> : null
                                    )}

                                    <tr key={"1-tr-total-planned"}>
                                        <th scope="row">Totaal</th>
                                        {dates.map(c =><td key={`1-td-total-planned-${c}`}>{calcNumber(collectionSum(data, 'planned_hours', {business_date:c, branch: branch.name}),true)}</td>)}
                                        <td key={`1-td-total-total-planned`}>{calcNumber(collectionSum(data, 'planned_hours', {branch: branch.name}))}</td>
                                    </tr>
                                    <tr key={"1-tr-sickness"}>
                                        <th scope="row" className="p-font-n">Ziekte uren</th>
                                        {dates.map(c =><td key={`1-td-total-planned-${c}`}>{calcNumber(collectionSum(data7, 'sickness_hours', {business_date:c, branch: branch.name}),true)}</td>)}
                                        <td key={`1-td-total-total-planned`}>{calcNumber(collectionSum(data7, 'sickness_hours', {branch: branch.name}))} ({formatPercentage(collectionSum(data7, 'sickness_hours', {branch: branch.name}) / collectionSum(data2, 'clocked_hours', {branch: branch.name}) ,1)} Ger. uren)</td>
                                    </tr>
                                    <tr key={"1-tr-total-planned-corr"}>
                                        <th scope="row">Totaal (Gecorr. ziekte uren)</th>
                                        {dates.map(c =><td key={`1-td-total-planned-${c}`}>{calcNumber(collectionSum(data, 'planned_hours', {business_date:c, branch: branch.name}) - collectionSum(data7, 'sickness_hours', {business_date:c, branch: branch.name}),true) }</td>)}
                                        <td key={`1-td-total-total-planned`}>{calcNumber(collectionSum(data, 'planned_hours', {branch: branch.name}) - collectionSum(data7, 'sickness_hours', {branch: branch.name}))}</td>
                                    </tr>
                                    <tr><th></th><td colSpan={dates.length+1}></td></tr>

                                    <tr><th>Gerealiseerde uren</th><td colSpan={dates.length+1}></td></tr>
                                    {departments.map(l =>
                                        collectionSum(data2, 'clocked_hours', {branch: branch.name, department: l}) ? 
                                        <tr key={`2-${l}`}><th scope="row" className="p-font-n">{l}</th>
                                        {dates.map(c => {
                                            let x = collectionSum(data2, 'clocked_hours', {business_date:c, branch: branch.name, department: l});
                                            let y = collectionSum(data, 'planned_hours', {business_date:c, branch: branch.name, department: l});
                                            return (x && y) ? <td key={`2-${l}-${c}`}>{calcNumber(x,true)} <span className="p-font-p">({formatPercentage(divideBy(x,y),0)})</span></td> : <td key={`2-${l}-${c}`}>{calcNumber(x,true)}</td>;
                                        }
                                        )}
                                        <td key={`2-${l}-total`}>{calcNumber(collectionSum(data2, 'clocked_hours', {branch: branch.name, department: l}))} <span className="p-font-p">({formatPercentage(divideBy(collectionSum(data2, 'clocked_hours', {branch: branch.name, department: l}),collectionSum(data, 'planned_hours', {branch: branch.name, department: l})),0)})</span></td>
                                        </tr> : null
                                    )}
                                    <tr key={"2-tr-total-planned"}><th scope="row">Totaal</th>{dates.map(c => {
                                        let x = collectionSum(data2, 'clocked_hours', {business_date:c, branch: branch.name});
                                        let y = collectionSum(data, 'planned_hours', {business_date:c, branch: branch.name});
                                        return <td key={`2-td-total-planned-${c}`}>{calcNumber(x,true)} <span className="p-font-p">({formatPercentage(divideBy(x,y),0)})</span></td>
                                    }
                                    )}
                                    <td key={`2-td-total-total-planned`}>{calcNumber(collectionSum(data2, 'clocked_hours', {branch: branch.name}))} <span className="p-font-p">({formatPercentage(divideBy(collectionSum(data2, 'clocked_hours', {branch: branch.name}),collectionSum(data, 'planned_hours', {branch: branch.name})),0)})</span></td></tr>
                                    <tr><th></th><td colSpan={dates.length+1}></td></tr>

                                    <tr><th scope="row">Gerealiseerde Omzet vorig jaar</th>{datesprev.map(c =><td key={`2-td-total-planned-${c}`}>{calcValutaNo0(collectionSum(data6, 'net_sales_amount', {business_date:c, branch: branch.name}),true)}</td>)}
                                    <td key={`2-td-total-total-planned`}>{calcValutaNo0(collectionSum(data6, 'net_sales_amount', {branch: branch.name}))}</td></tr>
                                    <tr className="p-font-p"><th scope="row" className="p-font-p">% dit jaar / vorig jaar</th>{dates.map(c => <td key={`2-td-total-planned-${c}`}>{formatPercentage(divideBy(collectionSum(data4, 'net_sales_amount', {business_date:c, branch: branch.name}),collectionSum(data6, 'net_sales_amount', {business_date:weekdayInOtherYear(c,-1), branch: branch.name})),0)}</td> )}<td key={`2-td-total-total-planned`}>{formatPercentage(divideBy(collectionSum(data4, 'net_sales_amount', {branch: branch.name}),collectionSum(data6, 'net_sales_amount', {branch: branch.name})),0)}</td></tr>
                                    <tr><th scope="row">Geplande Omzet Totaal</th>{dates.map(c => <td key={`2-td-total-planned-${c}`}>{calcValutaNo0(collectionSum(data3, 'planned_sales', {business_date:c, branch: branch.name}),true)}</td>)}<td key={`2-td-total-total-planned`}>{calcValutaNo0(collectionSum(data3, 'planned_sales', {branch: branch.name}))}</td></tr>
                                    <tr className="p-font-p"><th scope="row" className="p-font-p">% gerealiseerd / gepland</th>{dates.map(c => <td key={`2-td-total-planned-${c}`}>{formatPercentage(divideBy(collectionSum(data4, 'net_sales_amount', {business_date:c, branch: branch.name}),collectionSum(data3, 'planned_sales', {business_date:c, branch: branch.name})),0)}</td> )}<td key={`2-td-total-total-planned`}>{formatPercentage(divideBy(collectionSum(data4, 'net_sales_amount', {branch: branch.name}),collectionSum(data3, 'planned_sales', {branch: branch.name})),0)}</td></tr>
                                    <tr><th scope="row">Gerealiseerde Omzet Totaal</th>{dates.map(c => <td key={`2-td-total-planned-${c}`}>{calcValutaNo0(collectionSum(data4, 'net_sales_amount', {business_date:c, branch: branch.name}),true)}</td>)}<td key={`2-td-total-total-planned`}>{calcValutaNo0(collectionSum(data4, 'net_sales_amount', {branch: branch.name}))}</td></tr>
                                    <tr><th></th><td colSpan={dates.length+1}></td></tr>

                                    <tr><th scope="row">Gerealiseerde OPU vorig jaar</th>{datesprev.map(c => <td key={`2-td-total-planned-${c}`}>{calcValutaNo0(divideBy(collectionSum(data6, 'net_sales_amount', {business_date:c, branch: branch.name}) , collectionSum(data5, 'clocked_hours', {business_date:c, branch: branch.name})))}</td>)}<td key={`2-td-total-total-planned`}>{calcValutaNo0(divideBy(collectionSum(data6, 'net_sales_amount', {branch: branch.name}) , collectionSum(data5, 'clocked_hours', {branch: branch.name})) )}</td></tr>
                                    <tr className="p-font-p"><th scope="row" className="p-font-p">% dit jaar / vorig jaar</th>{dates.map(c => {
                                        let clastyear = weekdayInOtherYear(c,-1);
                                        let x = divideBy( collectionSum(data4, 'net_sales_amount', {business_date:c, branch: branch.name}),collectionSum(data2, 'clocked_hours', {business_date:c, branch: branch.name}) );
                                        let y = divideBy( collectionSum(data6, 'net_sales_amount', {business_date:clastyear, branch: branch.name}),collectionSum(data5, 'clocked_hours', {business_date:clastyear, branch: branch.name}) );
                                        return <td key={`3-td-this-vs-last-year-${c}`}>{formatPercentage(divideBy(x,y),0)}</td>
                                    })}
                                    <td key={`3-td-this-vs-last-year`}>{formatPercentage(divideBy( divideBy(collectionSum(data4, 'net_sales_amount', {branch: branch.name}) , collectionSum(data2, 'clocked_hours', {branch: branch.name})) , divideBy( collectionSum(data6, 'net_sales_amount', {branch: branch.name}) , collectionSum(data5, 'clocked_hours', {branch: branch.name}) ) ),0)}</td></tr>
                                    
                                    <tr><th scope="row">Geplande OPU</th>{dates.map(c => <td key={`2-td-total-planned-${c}`}>{calcValutaNo0(divideBy(collectionSum(data3, 'planned_sales', {business_date:c, branch: branch.name}) , collectionSum(data, 'planned_hours', {business_date:c, branch: branch.name})))}</td>)}<td key={`2-td-total-total-planned`}>{calcValutaNo0(divideBy(collectionSum(data3, 'planned_sales', {branch: branch.name}) , collectionSum(data, 'planned_hours', {branch: branch.name})) )}</td></tr>
                                    <tr className="p-font-p"><th scope="row" className="p-font-p">% gerealiseerd / gepland</th>{dates.map(c => {
                                        let x = divideBy( collectionSum(data4, 'net_sales_amount', {business_date:c, branch: branch.name}),collectionSum(data2, 'clocked_hours', {business_date:c, branch: branch.name}) );
                                        let y = divideBy( collectionSum(data3, 'planned_sales', {business_date:c, branch: branch.name}),collectionSum(data, 'planned_hours', {business_date:c, branch: branch.name}) );
                                        return <td key={`4-td-realised-planned-${c}`}>{formatPercentage(divideBy(x,y),0)}</td>
                                    })}
                                    <td key={`4-td-realised-planned`}>{formatPercentage(divideBy( divideBy(collectionSum(data4, 'net_sales_amount', {branch: branch.name}) , collectionSum(data2, 'clocked_hours', {branch: branch.name})) , divideBy( collectionSum(data3, 'planned_sales', {branch: branch.name}) , collectionSum(data, 'planned_hours', {branch: branch.name}) ) ),0)}</td></tr>
                                    
                                    <tr><th scope="row">Gerealiseerde OPU</th>{dates.map(c => 
                                        <td key={`2-td-total-planned-${c}`}>{calcValutaNo0(collectionSum(data4, 'net_sales_amount', {business_date:c, branch: branch.name}) / collectionSum(data2, 'clocked_hours', {business_date:c, branch: branch.name}))}</td>
                                    )}
                                    <td key={`2-td-total-total-planned`}>{calcValutaNo0(collectionSum(data4, 'net_sales_amount', {branch: branch.name}) / collectionSum(data2, 'clocked_hours', {branch: branch.name}) )}</td></tr>
                                    <tr><th></th><td colSpan={dates.length+1}></td></tr>
                                    
                                    <tr>
                                        <th scope="row">Toelichting gerealiseerde OPU door LM:</th>
                                        {dates.map(c => <td key={`td-comment-opu-${c}`}><InputText name={c} onChange={noteChange} value={filterNote(c)} frequency="bd" rows="3"/></td>)}
                                        <td><InputText name={transformWeek(dates[0])} onChange={noteChange} value={filterNote(transformWeek(dates[0]))} frequency="w" rows="3"/></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Toelichting gerealiseerde Omzet door LM:</th>
                                        {dates.map(c => <td key={`td-comment-rev-${c}`}><InputText name={c} onChange={noteChange2} value={filterNote2(c)} frequency="bd" rows="3"/></td>)}
                                        <td><InputText name={transformWeek(dates[0])} onChange={noteChange2} value={filterNote2(transformWeek(dates[0]))} frequency="w" rows="3"/></td>
                                    </tr>
                                </tbody>
                            </table>

                            <button className="btn btn-success float-end" onClick={() => saveChanges()}>Opslaan</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Dashboard;
