import React from 'react';
import BarChartHorizontalClick from './barChartHorizontalClick';
import BarChartHorizontal from './barChartHorizontal';
import BarChartVertical from './barChartVertical';
import BarChartStacked from './barChartStacked';
import BarChartGrouped from './barChartGrouped';
import LineChart from './lineChart';
import LineChartMulti from './lineChartMulti';
import PolarAreaChart from './polarAreaChart';
import RadarChart from './radarChart';
import ScatterChart from './scatterChart';
import BubbleChart from './bubbleChart';
import MultitypeChart from './multitypeChart';
import PieChart from './pieChart';
import DoughnutChart from './doughnutChart';
import TotalNumber from './totalNumber';
import * as Kpi from './calculateKPI';

const charts = {
    'BarChartHorizontal':BarChartHorizontal,
    'BarChartVertical':BarChartVertical,
    'BarChartStacked':BarChartStacked,
    'BarChartGrouped':BarChartGrouped,
    'LineChart':LineChart,
    'LineChartMulti':LineChartMulti,
    'PolarAreaChart':PolarAreaChart,
    'RadarChart':RadarChart,
    'ScatterChart':ScatterChart,
    'BubbleChart':BubbleChart,
    'MultitypeChart':MultitypeChart,
    'PieChart':PieChart,
    'DoughnutChart':DoughnutChart,
    'TotalNumber': TotalNumber,
    'BarChartHorizontalClick':BarChartHorizontalClick
};

function createChart(c,o,d) {
    return React.createElement(charts[c], {data: d, options: o});
}

export function chart(data, options={}) {

    let graph = options.graph;
    if (!(graph in charts)) graph = 'TotalNumber';

    // if (!(options['kpi_id'] in Kpi)) return null;
    // let datakpi = Kpi[options['kpi_id']](data, options);
    let datakpi = (options['kpi_id'] in Kpi) ? Kpi[options['kpi_id']](data, options) : Kpi['calculateKpi'](data, options);

    return createChart(graph, options, datakpi);
}
