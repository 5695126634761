import React, { useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { BsInfo } from 'react-icons/bs';
import { t } from '../../services/translationService';
import LanguageContext from '../../context/languageContext';

const IconInfo = React.forwardRef(function IconInfo(props, ref) {
    return <div {...props} className="div-icon-info" ref={ref}><BsInfo className="icon-info"/></div>
});

function Info({title}) {
    let { lng } = useContext(LanguageContext);
    let x = t(`i-${title}`,lng );
    
    return (
        <Tooltip title={x} placement="top">
            <IconInfo/>
        </Tooltip>
    );
}

export default Info;
