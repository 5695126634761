import React, { useContext } from 'react';
import LineChart from './lineChart';
import TrendPrev from './trendPrev';
import { sum, roundNumber2Dec } from './calculateKPI';
import { t } from '../../../services/translationService';
import LanguageContext from '../../../context/languageContext';
import Spinner from '../../common/spinner2';
import _ from 'lodash';
const moment = require('moment');

function new_label(x,selection,lng) {
    let formaty = 'YYYY-MM-DD';
    if (selection === 'day') {
        let thisday = moment(x,formaty).format('dddd D MMMM YYYY').split(' ');
        return thisday[2] ? `${t(thisday[0],lng)} ${t(thisday[1],lng)} ${t(thisday[2],lng).toLowerCase()} ${thisday[3]}` : '';
    } else if (selection === 'week') {
        let startofweek = moment(x,'YYYY-WW').format('D MMMM YYYY').split(' ');
        return startofweek[1] ? `${t('week')} ${moment(x,'YYYY-WW').format('W')} ${startofweek[2]} (${startofweek[0]} ${t(startofweek[1],lng).toLowerCase()})` : '';
    } else if (selection === 'month') {
        return `${t(moment(x,'YYYY-MM').format('MMMM'),lng)} ${moment(x,'YYYY-MM').format('YYYY')}`;
    } else {
        return x;
    }
}

function filter_data(data,option) {
    let options = option.split(":");
    if (options.length === 2) {
        let optionsin = {};
        optionsin[options[0]] = options[1];
        data = _.filter(data, optionsin );
        return data;
    }
    else {
        return data;
    }
}

function calc_amount(value, options) {
    if (!options.formula) {
        return _.sumBy(value, function(o) {
            if(!o[options.x]) o[options.x]=0;
            return parseFloat(o[options.x]);
        })
    } else if (options.formula==='average') {
        let x = options.x.split(",");
        let total = sum(value,x[0]) / sum(value,x[1]);
        if (!total) return 0;
        return roundNumber2Dec(total) ; 
    } else if (options.formula==='percentage') {
        let x = options.x.split(",");
        let total = sum(value,x[0]) / sum(value,x[1]) * 100;
        return roundNumber2Dec(total) ; 
    } else { 
        return null;
    }
}

function create_trend_data(data,options,selection,lng) {
    let queryid = options.query_id;
    if (typeof(options.query_id)==="string" && options.trend_hour_option && options.trendhour) queryid = `${options.query_id}_now`;

    if (Array.isArray(queryid) && queryid.length) {
        queryid = (options.trend_hour_option && options.trendhour) ? `${options.kpi_id}-2` : `${options.kpi_id}-1`;
        data[queryid] = [];
        if (options.trend_hour_option && options.trendhour) {
            let qid1 = `${options.query_id[0]}_now`;
            let qid2 = `${options.query_id[1]}_now`;
            if (data[qid1] && data[qid2]) data[queryid] = data[qid1].concat( data[qid2] );
        } else if (data[options.query_id[0]] && data[options.query_id[1]]) {
            data[queryid] = data[options.query_id[0]].concat( data[options.query_id[1]] );
        }
    }
    
    let datain;
    if (options.filter) {
        datain = filter_data(data[queryid], options.filter);
    } else if (data.hasOwnProperty(queryid)) {
        datain = data[queryid];
    }

    let datay = [];
    if (data.hasOwnProperty('trend_dates')) {
        data['trend_dates'].map(d => datay.push( { order: d.trend_date, label: new_label(d.trend_date,selection,lng), amount: calc_amount(_.filter(datain,{trend_date:d.trend_date}),options)} ))
        datay = _.orderBy(datay, ['order'], ['asc']);
    }
    return datay;
}

function Trend({data, data2, options, selection, loading}) {
    let { lng } = useContext(LanguageContext);

    if (!selection) return (<p>{t('no-trend',lng)}</p>);
    
    let datay = create_trend_data(data,options,selection,lng);
    let datay2 = data2 && create_trend_data(data2,options,selection,lng);

    return (
        <div>
            <TrendPrev data={datay} options={options} />
            <div className={`row ${options.height ? `row-trend-${options.height}` : "row-trend"}`}>
                { datay.length ? <LineChart data={datay} data2={datay2} /> : loading ? null : <Spinner /> }
            </div>
        </div>
    );
}

export default Trend;
