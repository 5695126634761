import React from 'react';
import { Link } from 'react-router-dom';
import Notification from './common/notification';
import Profile from './common/profile';
import InputSelectNav from './common/inputSelectNav';
import auth from '../services/authService';
import { IoMenuSharp, IoCog } from 'react-icons/io5';
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import Translation from '../services/translationService';

function NavBar({ client, regions, chains, branches, count, changeSelection, bellClick}) {
    let user = auth.getCurrentUser();
    if (!user || (user.exp < (Date.now() / 1000) && user.email !== 'demo@dataprofit.com')) return (
        <nav className="navbar fixed-top navbar-expand-lg navbar-dark navbar-dp py-nav">
            <div className="container-fluid">
                <h4 className="my-2 ms-5">Dataprofit</h4>
            </div>
        </nav>
    );
    
    return (
        <nav className="navbar fixed-top navbar-expand-lg navbar-dark navbar-dp py-nav">
            <div className="container-fluid">
                <button className="btn btn-no-outline" type="button shadow-none" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop"><IoMenuSharp className="icon-nav-db" /></button>
                <h5 id="nav-hd" className="margin-0">{client}</h5>
                <button
                    className="navbar-toggler btn btn-no-outline"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-label="Toggle navigation"
                >
                    <IoEllipsisVerticalSharp className="icon-nav-db-grey" />
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item mx-1 align-self-md-center"><InputSelectNav data={chains} changeSelection={changeSelection} name="chains"/></li>
                        <li className="nav-item mx-1 align-self-md-center"><InputSelectNav data={regions} changeSelection={changeSelection} name="regions"/></li>
                        <li className="nav-item mx-1 align-self-md-center"><InputSelectNav data={branches} changeSelection={changeSelection} name="branches"/></li>
                        
                        <li className="nav-item dropdown nav-noti nav-btn">
                            <a className="nav-link" href="#" id="navbarDropdown" onClick={() => bellClick()} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <Notification count={count}/>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href="#"><Translation>no-notifications</Translation></a></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown nav-btn me-1">
                            <a className="nav-link" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <Profile />
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                <li><Link className="dropdown-item" to="/profile"><Translation>profile</Translation></Link></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><Link className="dropdown-item" to="/personaldashboards"><Translation>p-dbs</Translation></Link></li>
                                {auth.isManagerOrAdmin() && <React.Fragment>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><Link className="dropdown-item" to="/manageusergroups"><Translation>manage-groups</Translation></Link></li>
                                    <li><Link className="dropdown-item" to="/manageusers"><Translation>manage-users</Translation></Link></li>
                                    <li><Link className="dropdown-item" to="/managechains"><Translation>manage-chains</Translation></Link></li>
                                    <li><Link className="dropdown-item" to="/manageregions"><Translation>manage-regions</Translation></Link></li>
                                    <li><Link className="dropdown-item" to="/managepersonaldashboards"><Translation>manage-p-dbs</Translation></Link></li>
                                    <li><Link className="dropdown-item" to="/managecompanydashboards"><Translation>manage-c-dbs</Translation></Link></li>
                                    <li><Link className="dropdown-item" to="/managereportmailings"><Translation>manage-reportmail</Translation></Link></li>
                                    {["65ca0884538ff359960c2314","65e1cc35d1f0b7b5670f0c9d"].includes(user.cid) && <li><Link className="dropdown-item" to="/managevestaboards"><Translation>manage-vestaboards</Translation></Link></li>}
                                    {/* {user.cid === "65ca0884538ff359960c2314" && <li><Link className="dropdown-item" to="/managevestaboardproducts"><Translation>manage-vestaboardproducts</Translation></Link></li>} */}
                                </React.Fragment> }                            
                                <li><hr className="dropdown-divider" /></li>
                                <li><Link className="dropdown-item" to="/logout"><Translation>logout</Translation></Link></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
