import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import DashboardTitle from '../common/dashboardTitle';
import { getData } from '../../services/dataService';
import { dateToString } from '../../services/calculateService';
import { toastError } from '../../services/toastService';
import logger from '../../services/logService';
import Spinner from '../common/spinner';
import Widgets from './widgets';

function Dashboard(props) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dashboard] = useState({
        title:'Dashboard overzicht', 
        widgets:[
            { id:1,     kpi_id:'revenue_total',             query_id:'revenue_day', title: 'Omzet totaal', height: '700px', width: '2',         graph:'TotalNumber', x:'net_sales_amount', unit: 'valuta', information: "Dit is een test bericht!"},
            { id:8,     kpi_id:'corrections',               query_id:'revenue_day', title: 'Correcties', height: '700px', width: '2',           graph:'TotalNumber', x:'corrected', unit: 'valuta' },
            { id:9,     kpi_id:'checks',                    query_id:'revenue_day', title: 'Checks', height: '700px', width: '2',               graph:'TotalNumber', x:'checks', unit: 'number' },
            { id:16,    kpi_id:'check_avg',                 query_id:'revenue_day', title: 'Check average', height: '700px', width: '2',        graph:'TotalNumber', x:'net_sales_amount,checks', formula:'average', unit: 'valuta' },
            { id:10,    kpi_id:'covers',                    query_id:'covers', title: 'Covers', height: '700px', width: '2',                    graph:'TotalNumber', x:'covers', unit: 'integer' },
            { id:17,    kpi_id:'cover_avg',                 query_id:'covers', title: 'Cover average', height: '700px', width: '2',             graph:'TotalNumber', x:'net_sales_amount,covers', formula:'average', unit: 'valuta' },
            { id:13,    kpi_id:'labour_clocked',            query_id:'labour', title: 'Medewerkers ingeklokt', height: '700px', width: '4',         graph:'TotalNumber', x:'clocked_in', unit: 'integer'},
            { id:14,    kpi_id:'labour_hours',              query_id:'labour', title: 'Gewerkte uren', height: '700px', width: '2',             graph:'TotalNumber', x:'total_hours', unit: 'integer' },
            { id:19,    kpi_id:'productivity',              query_id:'labour', title: 'Productiviteit', height: '700px', width: '2',            graph:'TotalNumber', x:'total_hours', unit: 'valuta' },
            { id:15,    kpi_id:'labour_costs',              query_id:'labour', title: 'Personeelskosten', height: '700px', width: '2',          graph:'TotalNumber', x:'timecard_cost', unit: 'valuta' },
            { id:20,    kpi_id:'labour_costs_p',            query_id:'labour', title: 'Personeelskosten (%)', height: '700px', width: '2',        graph:'TotalNumber', x:'timecard_cost', unit: 'percentage' },
            { id:4,     kpi_id:'revenue_branches',          query_id:'revenue_day', title: 'Omzet per locatie', height: '700px', width: '6',    graph:'BarChartHorizontal', x:'net_sales_amount', y:'branchname', max_n:20 },
            { id:11,    kpi_id:'revenue_revenue_centers',   query_id:'revenue_center', title: 'Omzet per Revenue Center', height: '700px', width: '6', graph:'BarChartHorizontal', x:'net_sales_amount', y:'revenue_center', max_n:20 },
            { id:6,     kpi_id:'revenue_product_group',     query_id:'product_day', title: 'Omzet per product groep', height: '700px', width: '6', graph:'BarChartHorizontal', x:'net_sales_amount', y:'product_group_name', max_n:20},
            { id:7,     kpi_id:'revenue_product',           query_id:'product_day', title: 'Omzet per product', height: '700px', width: '6',    graph:'BarChartHorizontal', x:'net_sales_amount', y:'product_name', max_n:20},
            // { id:2,     kpi_id:'revenue_product_total',     query_id:'product_day', title: 'Product omzet totaal', height: '700px', width: '6', graph:'TotalNumber', x:'net_sales_amount', unit: 'valuta' },
            // { id:3,     kpi_id:'revenue_group_total',       query_id:'revenue_group', title: 'Revenue Group omzet totaal', height: '700px', width: '6', graph:'TotalNumber', x:'net_sales_amount', unit: 'valuta' },
            { id:12,    kpi_id:'revenue_hours',             query_id:'revenue_hour', title: 'Omzet per uur', height: '700px', width: '6',       graph:'BarChartVertical', x:'net_sales_amount', y:'business_hour' },
            { id:18,    kpi_id:'revenue_dayparts',          query_id:'revenue_hour', title: 'Omzet per dagdeel', height: '700px', width: '6',   graph:'PieChart', x:'net_sales_amount', y:'business_hour' },
            // { id:5, query_id:'revenue_group', title: 'Omzet per Revenue Group', height: '700px', width: '6', graph:'BarChartHorizontal', x:'net_sales_amount', y:'revenue_group', max_n:20 },
        ]
    });

    async function updateData() {
        setLoading(true);
        const widgets = _.uniq(_.map(dashboard.widgets, 'query_id'));
        
        try {
            const { data } = await getData(dateToString(props.startDate),dateToString(props.endDate), widgets);
            setLoading(false);
            setData( data );
        }
        catch (ex) {
            setLoading(false);
            if (ex.response) {
                toastError(ex.response.data);
            }
            logger.log(ex);
        }
    }

    useEffect(() => {
        updateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <main className="container-fluid">
            { loading ? <div className="loading center-dp "><Spinner /></div> : null}
            <DashboardTitle title={dashboard.title} updateData={updateData}/>
            <Widgets dashboard={dashboard} data={data} branches={props.branches} />          
        </main>
    );
}

export default Dashboard;
