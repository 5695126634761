import React from 'react';
import { Link } from 'react-router-dom';
import background from '../assets/4042.jpg';

function NotFound() {
    return (
        <div className="container-fluid">
                    
            <div className="row">
                <div className="col-md-6 notfound-404 nul">
                    {/* <div className="row"> */}
                        <div className="d-flex height-100 justify-content-center align-items-center">
                            <div>
                                <h1 className="text-5xl font-1">404</h1>
                                <p className="notfound-p">Oeps, deze pagina bestaat niet.</p>
                                <Link to="/"><button className="btn btn-warning">Ga naar home</button></Link>
                            </div>
                        </div>

                    {/* </div> */}
                    
                </div>
                <div className="col-md-6 nul">
                    <img src={background} alt="404" className="notfound-img" />
                </div>
            </div>
        </div>
    );
}

export default NotFound;
