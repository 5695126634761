import React from 'react';

function TotalNumber({ data }) {
    return (
        <div className="widget-total">
            { data }
        </div>
    );
}

export default TotalNumber;
