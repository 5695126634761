import React from 'react';
import PageTitle from './common/PageTitle';
// import { TwitterPicker } from 'react-color';
// import { getNotes } from '../services/dataService';

function Home() {

    // function handleChange(color, event) {
    //     console.log(color);
    // }

    // const test = async () => {
    //     // console.log('testen');
    //     try {
    //         const { data } = await getNotes('2022-09-26','2022-10-02', '5e620b1f12203746008b4567');
    //         console.log('result:',data);
    //     }
    //     catch (ex) {
    //         if (ex) {
    //             console.log(ex);
    //         }
    //     }

    // }

    return (
        <main className="container-fluid">
            <PageTitle title="Home"/>
            {/* <TwitterPicker onChange={ handleChange } triangle={'hide'} colors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF']} /> */}
            {/* <button className="btn btn-success float-end" onClick={() => test()}>Test</button> */}
        </main>
    );
}

export default Home;
