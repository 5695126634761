import React, { useContext } from 'react';
import _ from 'lodash';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { formatHours, formatUnit } from './calculateKPI';
import LanguageContext from '../../../context/languageContext';
import { collectionSum, range } from '../../../services/calculateService';

ChartJS.register(ArcElement, Tooltip, Legend);

const graphoptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'top',
    },
    title: {
      display: false,
      text: 'Chart.js Pie Chart'
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          let show = context.dataset.show[context.dataIndex];
          return show ? show : context.formattedValue;
        }
      }
    }
  }
};

function department(key,dayparts) {
  let y = '';

  dayparts.map(d => {
    if ((key >= d.start && key < d.end) || ((d.start > d.end) && (key >= d.start || key < d.end))) y = d.name;
  });

  return y;
}

function calcPie(data=[], labelx='net_sales_amount', labely='branchname', formula='', unit='valuta', dayparts=[]) {
  if (formula==='dayparts') {
    data.map(x => x['part'] = department(formatHours(x[labely]),dayparts) );

    let data0 = dayparts.map(d => {
      let v1 = collectionSum(data,labelx,{'part': d.name});
      let vtot = collectionSum(data,labelx);
      d['label'] = d['name'];
      d['amount'] = v1;
      d['show'] = `${d.name}: ${formatUnit(v1,unit)} (${formatUnit(v1/vtot*100,'percentage1')})`;
      return d;
    });
  
    return data0;

  } else if (formula==='multiple') {
    let values = labelx.split(",");
    let labels = labely.split(",");
    
    let total = 0;
    values.map(v => total += collectionSum(data,v));

    return range(0,values.length-1).map(i => { return {label: labels[i], amount: collectionSum(data,values[i]), show: `${formatUnit(collectionSum(data,values[i]),unit)} (${formatUnit(collectionSum(data,values[i])/total*100,'percentage1')})` } });
  } else if (formula==='groupby') {
    let columnx = labelx.split(";")[0];
    let columny = labelx.split(";")[1];
    let values = labelx.split(";")[2].split(",").map(x => parseInt(x));
    let labels = labely.split(",");
    
    let total = collectionSum(data,columnx);
    return range(0,values.length-1).map(i => { return {label: labels[i], amount: collectionSum(data,columnx,[columny,values[i]]), show: `${formatUnit(collectionSum(data,columnx,[columny,values[i]]),unit)} (${formatUnit(collectionSum(data,columnx,[columny,values[i]])/total*100,'percentage1')})` } });
  } else if (formula==='categories') {
    let columnx = labelx.split(";")[0];
    let columny = labelx.split(";")[1];
    let values = labelx.split(";")[2].split(",").map(x => parseInt(x));
    let labels = labely.split(";");

    let total = collectionSum(data,columny);
    let arry = [];
    for (let i = 0;i<labels.length;i++) {
      // group by value ranges
      let cond = i===0 ? function(o) { return o[columnx] <= values[i]; } : i===labels.length-1 ? function(o) { return o[columnx] > values[i-1]; } : function(o) { return o[columnx] > values[i-1] && o[columnx] <= values[i]; };
      let amounti = collectionSum(data,columny,cond);
      arry.push({ label: labels[i], amount: amounti, show: `${formatUnit(amounti,unit)} (${formatUnit(amounti/total*100,'percentage1')})` });
    }
    return arry;
  } else {
    return data.map(x => x['part'] = x[labely] );
  }
}

export default function PieChart({ data, options }) {  
  let { dayparts } = useContext(LanguageContext);
  let data0 = calcPie(_.cloneDeep(data), options.x, options.y, options.formula, options.unit, _.sortBy(dayparts.filter(p => p.active),['order','label']));

  let data1 = {
    labels: data0.map((x) => x['label']),
    datasets: [
      {
      data: data0.map((x) => parseFloat(x['amount'])),
      show: data0.map((x) => x['show'] ),
      backgroundColor: [
        'rgba(54, 162, 235, 0.7)',
        'rgba(255, 99, 132, 0.7)',
        'rgba(75, 192, 192, 0.7)',
        'rgba(255, 206, 86, 0.7)',
        'rgba(153, 102, 255, 0.7)',
        'rgba(255, 159, 64, 0.7)',
      ],
      borderColor: [
        'rgba(255, 255, 255, 1)'
      ],
      borderWidth: 1,
      }
    ]
  };

  return <Pie className="chart" data={data1} options={graphoptions} />;
}



export const data_origineel = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.7)',
        'rgba(54, 162, 235, 0.7)',
        'rgba(255, 206, 86, 0.7)',
        'rgba(75, 192, 192, 0.7)',
        'rgba(153, 102, 255, 0.7)',
        'rgba(255, 159, 64, 0.7)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export function PieChartOrigineel() {
  return <Pie data={data_origineel} />;
}
