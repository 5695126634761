import React from 'react';

const InputFloat = ({ name, label, error, ...rest }) => {
    return ( 
        <div className="form-floating mb-3">
            <input {...rest} className="form-control inputfloat-dp btn-no-outline" name={name} id={name} placeholder="name@example.com" />
            <label htmlFor={name}>{label}</label>
            {error && <p className="p-login">{error}</p>}
        </div>
     );
}
 
export default InputFloat;
