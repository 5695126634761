import React from 'react';
import _ from 'lodash';

const InputSelect2 = ({ id, id_from, id_to, label, options, ...rest }) => {
    const sorted = _.orderBy(options, [label], ['asc']);
    
    return ( 
            <select name={id_to} id={id} {...rest} className="form-select" aria-label="Default select">
                <option default value=""></option>
                {sorted.map(option => (
                    <option key={option[id_from]} value={option[id_from]}>{option[label]}</option>
                ))}
            </select>
     );
}
 
export default InputSelect2;
