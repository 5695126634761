import React from 'react';
import { FaBell } from 'react-icons/fa';

function Notification({ count }) {
    return (
        // <i className="fa fa-bell-o fa-lg icon-nav ms-3"></i>
        <React.Fragment>
            <FaBell className="icon-nav" />
            {count ? <span className="badge rounded-pill bg-danger badge-dp">{count}</span>: null}
        </React.Fragment>

    );
}

export default Notification;
