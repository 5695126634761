import http from './httpService';

// NORMAL USERS
const apiUrl = "/reportmailings";

function userUrl(id) {
    return `${apiUrl}/${id}`;
}

export function getReportmailings() {
    return http.get(apiUrl);
}

export function getReportmailing(id) {
    return http.get(`${apiUrl}/${id}`);
}

export function updateReportmailing({id, data}) {
    const body = {...data};
    
    return http.put(userUrl(id), body);
}

export function saveReportmailing({data}) {
    const body = {...data};
    
    return http.post(apiUrl, body);
}

export function deleteReportmailing(id) {
    return http.delete(userUrl(id) );
}

// ADMIN USERS
const apiAdminUrl = "admin/reportmailings";

export function getAdminReportmailings() {
    return http.get(apiAdminUrl);
}

export function getAdminReportmailing(id) {
    return http.get(`${apiAdminUrl}/${id}`);
}

export function updateAdminReportmailing({id, data}) {
    const body = {...data};
    return http.put(`${apiAdminUrl}/${id}`, body);
}

export function saveAdminReportmailing({data}) {
    const body = {...data};
    return http.post(apiAdminUrl, body);
}

export function deleteAdminReportmailing(id) {
    return http.delete(`${apiAdminUrl}/${id}`);
}
