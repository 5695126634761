import React from 'react';
import image from '../../assets/spinner.gif';

function Spinner({size}) {
    let classname = "spinner";
    if (size === 'btn') classname = "spinner-btn";

    return (
        <img src={image} alt="spinner" className={classname} />
    );
}

export default Spinner;
