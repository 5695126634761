import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { formatUnit } from './calculateKPI';
import { BiTrendingDown, BiTrendingUp } from 'react-icons/bi';
import { MdTrendingFlat } from 'react-icons/md';

function TrendPrev({data, options}) {
    if (!data || (data && data.length < 2)) return (<p className="prev-trend">&nbsp;</p>);
    
    let previous = data[data.length-2]['amount'];
    let last = data[data.length-1]['amount'];
    let diff = (last-previous)/previous;

    return (
        <Tooltip title={data[data.length-2]['label']} placement="bottom">
        <p className="prev-trend">
            { (diff > 0) ? <BiTrendingUp className="icon-trend-up"/> : (diff < 0) ? <BiTrendingDown className="icon-trend-down"/> : <MdTrendingFlat className="icon-trend-0"/> }
            {formatUnit(previous,options.unit)} ({formatUnit((last-previous)/previous*100,'percentage')})
        </p>
        </Tooltip>
    );
}

export default TrendPrev;
