import React from 'react';
import BarChartHorizontal from './barChartHorizontal.jsx';
import BarChartVertical from './barChartVertical';
import BarChartStacked from './barChartStacked';
import BarChartGrouped from './barChartGrouped';
import LineChart from './lineChart';
import LineChartMulti from './lineChartMulti';
import PolarAreaChart from './polarAreaChart';
import RadarChart from './radarChart';
import ScatterChart from './scatterChart';
import BubbleChart from './bubbleChart';
import MultitypeChart from './multitypeChart';
import PieChart from './pieChart';
import DoughnutChart from './doughnutChart';
import TotalNumber from './totalNumber';

const charts = {
    'BarChartHorizontal':BarChartHorizontal,
    'BarChartVertical':BarChartVertical,
    'BarChartStacked':BarChartStacked,
    'BarChartGrouped':BarChartGrouped,
    'LineChart':LineChart,
    'LineChartMulti':LineChartMulti,
    'PolarAreaChart':PolarAreaChart,
    'RadarChart':RadarChart,
    'ScatterChart':ScatterChart,
    'BubbleChart':BubbleChart,
    'MultitypeChart':MultitypeChart,
    'PieChart':PieChart,
    'DoughnutChart':DoughnutChart,
    'TotalNumber': TotalNumber
};

function createChart(c,d,o) {
    return React.createElement(charts[c], {data: d, labelx: o.x, labely: o.y, max_n:o.max_n, unit: o.unit, formula: o.formula});
}

export function chart(graph, data, options={}, branches=null) {
    if (!(graph in charts)) graph = 'TotalNumber';

    // Filter data on selected branches
    //if (branches && data) data = _.filter(data, o => { return branches.includes(o.branch) } );

    //console.log(graph, _.sumBy(data, function(x) { return parseFloat(x['net_sales_amount']); }) ,'-', data);

    return createChart(graph, data, options);
}
