import React from 'react';
import Translation from '../../services/translationService';

function SaveButton({saveButton}) {
    const { label, btn, div, validate, submit } = saveButton;
    const btnclass = btn ? `btn btn-${btn}` : "btn btn-success mw-100";
    const divclass = div ? `d-grid gap-2 ${div}` : "d-grid gap-2";

    return (
        <div className={divclass}><button disabled={validate()} type="submit" onClick={submit} className={btnclass}><Translation>{label}</Translation></button></div>
    );
}

export default SaveButton;
