import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import auth from './authService';

function init() {
    Sentry.init({
        dsn: "https://5c8c699fb6e74933ab6a43e22a48f676@sentry-v2.dataprofitdev.com/13",
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: process.env.REACT_APP_ENV || 'development?'
    });
}

function log(error) {
    console.log('logService:', error?.response?.data || error?.message || error);
    
    // If token is expired or status = 400 do not send Sentry
    if (!((error.response) && ((error.response.data === 'Token expired.') || (error.response.status === 401) || (error.response.status === 400)) )) {
        
        let user = auth.getCurrentUser();
        if (user) Sentry.setUser({ email: user.email });
        
        if (error && error.response) {
            Sentry.setExtra('error.data', error.response.data);
            Sentry.setExtra('error.status', error.response.status);
            Sentry.setExtra('error.statusText', error.response.statusText);
            if (error.response.request) Sentry.setExtra('error.requestURL', error.response.request.responseURL);
        }
        
        Sentry.captureException(error);
    }
}

const logger = {
    init,
    log
};

export default logger;

// Usage:
// import logger from './services/logService';
// try {
//     throw "Test error";
// } catch (e) {
//     logger.log(e);
// }