import React, { useState, useEffect, useContext } from 'react';
import { Calendar } from 'react-date-range';
import { nl } from 'react-date-range/src/locale/index';
import DateContext from '../../context/dateContext';

const Datepicker = () => {
    const dateContext = useContext(DateContext);
    const [datepick, setDatepick] = useState( new Date() );

    
    useEffect(() => {
      dateContext.changeDaterange(datepick,datepick);
    }, [datepick]);

    return (
      <Calendar
        date={datepick}
        onChange={date => {
          setDatepick(date);
        }}
        weekStartsOn={1}
        locale={nl}
        direction="horizontal"
      />
    );
}

export default Datepicker;
