import React from 'react';
import Translation from '../../../services/translationService';

const InputDisabled = ({ name, label, ...rest }) => {
    return ( 
        <div className="mb-3">
            <label htmlFor={name} className="form-label"><Translation>{label}</Translation></label>
            <input {...rest} name={name} id={name} className="form-control" placeholder="Disabled input" disabled />
        </div>
     );
}
 
export default InputDisabled;
