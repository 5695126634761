import http from './httpService';

const apiUrl = "/usergroups";

function userUrl(id) {
    return `${apiUrl}/${id}`;
}

export function getUsergroups() {
    return http.get(apiUrl);
}

export function getUsergroup(id) {
    return http.get(`${apiUrl}/${id}`);
}

export function updateUsergroup({id, data}) {
    const body = {...data};
    
    return http.put(userUrl(id), body);
}

export function saveUsergroup({data}) {
    const body = {...data};
    
    return http.post(apiUrl, body);
}

export function deleteUsergroup(id) {
    return http.delete(userUrl(id) );
}
