import React from 'react';
import Translation from '../../services/translationService';

const InputCheckbox = ({ name, value, label, onChange, id }) => {
    return ( 
        <React.Fragment>
            { label ? <label className="form-label"><Translation>{label}</Translation></label> : null }
            <div className="form-check mb-3">
                <input className="form-check-input" type="checkbox" checked={value} id="flexCheckChecked" onChange={(e) => onChange(e,name, id)} />
            </div>
        </React.Fragment>
     );
}
 
export default InputCheckbox;
