import React, { Component } from 'react';
import PageTitle from './common/PageTitle';
import { getUsers, deleteUser } from '../services/userService';
import { toastSuccess, toastError } from '../services/toastService';
import logger from '../services/logService';
import Translation from '../services/translationService';
import TableForm from './common/tableForm';

class ManageUsers extends Component {
    state = {
        users: [],
        sortColumn: { column: 'email', order: 'asc' },
        search: ''
    }

    async componentDidMount() {
        const { data: users } = await getUsers();
        this.setState({ users });
    }

    handleSort = sortColumn => {
        this.setState({ sortColumn });
    }

    onSearch = search => {
        this.setState({ search });
    }

    handleDelete = async (id) => {
        const originalUsers = this.state.users;
        const user = originalUsers.filter(user => user._id === id)[0];
        
        if (window.confirm(`'${user.email}' verwijderen?`)) {
            // OK
            this.setState({ users: originalUsers.filter(user => user._id !== id) });
            try {
                await deleteUser(id);
                toastSuccess(<Translation>delete-success</Translation>,1500);
            }
            catch (ex) {
                if (ex.response && ex.response.status === 404) {
                    toastError(<Translation>delete-error</Translation>);
                } else {
                    logger.log(ex);
                }
                this.setState({ users: originalUsers });
            }
            return;
        } else {
            // CANCEL
            return;
        }
    }

    render() {
        const { users, sortColumn, search } = this.state;

        return (
            <main className="container-fluid overflow-auto">
                <PageTitle title="manage-users" search={search} onSearch={this.onSearch} addButton={'new'} />
                <TableForm data={users} keyid={"_id"} columns={[{'datalabel':'email','colname':'email', 'type':'text'},{'datalabel':'full_name','colname':'name', 'type':'text'},{'datalabel':'role_name','colname':'role', 'type':'text'},{'datalabel':'user_groups','colname':'usergroups', 'type':'object-usergroups'},{'datalabel':'last_login','colname':'last_login', 'type':'date'},{'datalabel':'is_active','colname':'status', 'type':'boolean-status'},{'datalabel':'_id','colname':'action', 'type':'link-user'}]} sortColumn={sortColumn} onSort={this.handleSort} onDelete={this.handleDelete} search={search} />
            </main>
        );
    }
}

export default ManageUsers;
