import React from 'react';
import _ from 'lodash';
import { formatText } from '../../services/calculateService';

// data = collection (array with objects)
// columns = collection, and the objects should contain keys 'colname','datalabel','type'
// keyid = keyid for every row

function TableBody({data, columns, keyid, sortBy}) {
    let sorted = [...data];
    
    if (sortBy) {
        let data0 = data.map(x => { return {...x, 'sortByABCDEFGH': parseFloat(x[sortBy])}; });
        sorted = _.orderBy(data0, ['sortByABCDEFGH'], ['desc']);
    }

    return (
        <tbody>
            {
                sorted.map((row,irow) =>
                    <tr key={row[keyid] || irow}>
                        {columns.map( col => <td key={`${keyid || irow}-${col.datalabel}`}>{formatText(row[col.datalabel],col.type)}</td> )}
                    </tr>
                )
            }
        </tbody>
    );
}

export default TableBody;
