import React from 'react';
import { Navigate, Link } from "react-router-dom";
import Joi from 'joi-browser';
import Form from './common/form';
import auth from '../services/authService';
import logger from '../services/logService';
import Translation from '../services/translationService';

class LoginForm extends Form {
    state = {
        data: { username: '', password: ''},
        errors: {}
    };

    schema = {
        username: Joi.string().required().label('E-mail'),
        password: Joi.string().required().label('Wachtwoord')
    };

    doSubmit = async () => {
        try {
            const { data } = this.state;
            await auth.login(data.username, data.password);
            
            window.location = "/";
        }
        catch (ex) {
            if (ex.response) { //&& ex.response.status === 400) {
                const errors = {...this.state.errors};
                errors.username = ex.response.data.replace("email","E-mail");
                this.setState({ errors });
            } else {
                console.log(ex);
                logger.log(ex);
            }
        }
    };

    render() {
        if (auth.getCurrentUser()) return <Navigate to="/" />;

        return (
            <main className="container-fluid">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="card card-dp card-login">
                            <div className="card-body center-dp">
                                <h2><Translation>login</Translation></h2>
                                <form onSubmit={this.handleSubmit} className="my-3">
                                    {this.renderInput2("username", "email", "e-mail")}
                                    {this.renderInput2("password", "password", "password")}
                                    {this.renderButton("log-in","primary mt-2")}
                                </form>
                                    <Link id="p-forgot" to="/forgot-password"><Translation>password-forgot?</Translation></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default LoginForm;
