import http from './httpService';

const apiUrl = "/chains";

function userUrl(id) {
    return `${apiUrl}/${id}`;
}

export function getChains() {
    return http.get(apiUrl);
}

export function getChain(id) {
    return http.get(`${apiUrl}/${id}`);
}

export function updateChain({id, data}) {
    const body = {...data};
    
    return http.put(userUrl(id), body);
}

export function saveChain({data}) {
    const body = {...data};
    
    return http.post(apiUrl, body);
}

export function deleteChain(id) {
    return http.delete(userUrl(id) );
}
