import React from 'react';
import TableHeader from './tableFormHeader';
import TableBody from './tableFormBody';

const TableForm = ({ data, columns, keyid, sortColumn, onSort, onDelete, search }) => {
    return ( 
        <div className="col-lg-12">
            <div className="card card-dp overflow-x-auto">
                <div className="card-body">
                    <table className="table table-form table-hover-3">
                        <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
                        <TableBody data={data} columns={columns} sortColumn={sortColumn} onDelete={onDelete} keyid={keyid} search={search} />
                    </table>
                    
                </div>
            </div>
        </div>
    );
}
 
export default TableForm;
