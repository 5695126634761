import React from 'react';
import { Link } from 'react-router-dom';
import { IoStatsChartSharp, IoStatsChartOutline, IoBusinessSharp } from 'react-icons/io5';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import Translation from '../services/translationService';
import _ from 'lodash';

function Menu({ dashboards=[] }) {
    dashboards = _.sortBy( _.filter(dashboards, 'is_active') , 'title' );
    let pdbs = _.filter(dashboards, { type: 'personal' });
    let cdbs1 = _.filter(dashboards, { type: 'company' });
    let cdbs2 = _.filter(dashboards, { type: 'custom' });
    let sdbs = _.filter(dashboards, { type: 'standard' });

    return (
        <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasWithBackdrop" aria-labelledby="offcanvasWithBackdropLabel">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasWithBackdropLabel">Check. Improve. Profit</h5>
                <button type="button" className="btn-close text-reset btn-no-outline" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="menu-body">
                <ul className="list-group">
                    
                    <h5 className="menu-h5"><IoStatsChartOutline className="me-2 pb-1" /><Translation>p-dbs</Translation></h5>
                    {pdbs.length ? pdbs.map(d => <li className="menu-li" key={d._id}><span data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop"><Link className="dropdown-item menu-a" to={`/dashboards/${d._id}`}>{d.title}</Link></span></li> ) : <p className="no-dbs"><Translation>no-dbs</Translation></p>}
                    
                    <h5 className="menu-h5"><IoBusinessSharp className="me-2 pb-1" /><Translation>c-dbs</Translation></h5>
                    {cdbs1.map(d => <li className="menu-li" key={d._id}><span data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop"><Link className="dropdown-item menu-a" to={`/${d.path ? d.path : 'dashboards'}/${d._id}`}>{d.title}</Link></span></li> ) }
                    {cdbs2.map(d => <li className="menu-li" key={d._id}><span data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop"><Link className="dropdown-item menu-a" to={`/${d.path ? d.path : 'dashboards'}/${d._id}`}>{d.title}</Link></span></li> ) }
                    {cdbs1.length + cdbs2.length ? null : <p className="no-dbs"><Translation>no-dbs</Translation></p>}

                    {sdbs.length ? <h5 className="menu-h5"><IoStatsChartSharp className="me-2 pb-1" /><Translation>s-dbs</Translation></h5> : null}
                    {sdbs.length ? sdbs.map(d => <li className="menu-li" key={d._id}><span data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop"><Link className="dropdown-item menu-a" to={`/${d.path ? d.path : 'dashboards'}/${d._id}`}>{d.title}</Link></span></li> ) : null}

                    <h5 className="menu-h5"><FaMapMarkedAlt className="me-2 pb-1" /><Translation>gis-map</Translation></h5>
                    <li className="menu-li" key="map-gismap"><span data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop"><Link className="dropdown-item menu-a" to="/dashboards1/map"><Translation>gismap</Translation></Link></span></li>
                    
                    <h5 className="menu-h5"><HiOutlineDocumentReport className="me-2" /><Translation>reports</Translation></h5>
                    <li className="menu-li" key="report-overview"><span data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop"><Link className="dropdown-item menu-a" to="/reports"><Translation>overview</Translation></Link></span></li>
                    
                    <span className="menu-h5"></span>
                </ul>
            </div>
        </div>
    );
}

export default Menu;
