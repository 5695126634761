import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: false,
      text: 'Chart.js Bar Chart',
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          let show = context.dataset.show[context.dataIndex];
          return show ? show : context.formattedValue;
        }
      }
    }
  },
  interaction: {
    intersect: false,
    mode: 'x'
  }
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data_origineel = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => Math.floor(Math.random() * 1000)),
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Dataset 2',
      data: labels.map(() => Math.floor(Math.random() * 1000)),
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

export default function BarChartVertical({ data, label='label', amount='amount' }) {
  let data1 = {
    labels: data.map((x) => x[label]),
    datasets: [
      {
      data: data.map((x) => parseFloat(x[amount])),
      show: data.map((x) => x['show'] ),
      borderColor: `rgb(53, 162, 235)`,
      backgroundColor: `rgba(53, 162, 235, 0.7)`,
      // borderColor: `rgb(${color_sel})`,
      // backgroundColor: `rgba(${color_sel}, 0.5)`,
      }
    ]
  };

  return <Bar className="chart" options={options} height={225} data={data1} />;
}
