import React from 'react';
import _ from 'lodash';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: 'bottom',
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
    tooltip: {
      callbacks: {
          title: function(context) {
            return context[0].dataset.showtitle ? context[0].dataset.showtitle[context[0].dataIndex] : context[0].label;
          }
      }
    }
  },
  interaction: {
    intersect: false,
  },
  scales: {
    x: {
      display: true,
      ticks: { display: false, padding: 3 },
      grid: { tickLength: 0, borderWidth: 0 }
    },
    y: {
      display: true,
      ticks: { display: true, padding: 5 },
      grid: { tickLength: 0, borderWidth: 0 }
    }
  },
  elements: {
    line: {
        tension: 0.3
    }
  }
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Trend',
      data: labels.map(() => Math.floor(Math.random() * 1000)),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    // {
    //   label: 'Dataset 2',
    //   data: labels.map(() => Math.floor(Math.random() * 1000)),
    //   borderColor: 'rgb(53, 162, 235)',
    //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
    // },
  ],
};

function createData(data) {
  return {
    labels: _.map(data, 'label'),
    datasets: [
      {
        data: _.map(data, (x) => parseFloat(x.amount)),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
    ]
  };
}

function createData2(data) {
  return {
    data: _.map(data, (x) => parseFloat(x.amount)),
    showtitle: _.map(data, 'label'),
    borderColor: 'rgb(255,180,180)', // 'rgb(53, 162, 235)',
    backgroundColor: 'rgba(255,180,180, 0.5)',
    borderDash: [4, 4]
  };
}

export default function LineChart({data, data2}) {
  let optionsin = _.cloneDeep(options);
  if (!(data && data.length)) optionsin.scales.y.ticks.display = false;

  let dataout = createData(data);
  if (data2) {
    let dataout2 = createData2(data2);
    dataout.datasets.push(dataout2);
  }
  
  return <Line options={optionsin} data={dataout} />;
}
