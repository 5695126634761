import http from './httpService';

const apiUrl = "/regions";

function userUrl(id) {
    return `${apiUrl}/${id}`;
}

export function getRegions() {
    return http.get(apiUrl);
}

export function getRegion(id) {
    return http.get(`${apiUrl}/${id}`);
}

export function updateRegion({id, data}) {
    const body = {...data};
    
    return http.put(userUrl(id), body);
}

export function saveRegion({data}) {
    const body = {...data};
    
    return http.post(apiUrl, body);
}

export function deleteRegion(id) {
    return http.delete(userUrl(id) );
}
