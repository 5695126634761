import React from 'react';
import { toastSuccess, toastError } from '../services/toastService';
import logger from '../services/logService';
import Joi from 'joi-browser';
import Form from './common/form';
import PageTitle from './common/PageTitle';
import { getAdminDashboard, updateAdminDashboard, saveAdminDashboard } from '../services/dashboardService';
import Translation from '../services/translationService';
import _ from 'lodash';

class AdminDashboardForm extends Form {
    state = {
        data: { title:'', widgets: [], is_active: true },
        kpis: [],
        graphs: [],
        widths: [],
        heights: [],
        errors: {}
    }

    schema = {
        title: Joi.string().min(1).max(255).required().label('Titel'),
        widgets: Joi.array().label('Widgets'),
        is_active: Joi.boolean().required().label('Active')
    };
    
    async componentDidMount() {
        const dbId = window.location.href.split('managepersonaldashboards/')[1];

        try {
            const { data } = await getAdminDashboard(dbId);
            if (dbId === "new") {
                this.setState({ kpis: data.kpis, graphs: data.graphs, widths: data.widths, heights: data.heights });
            } else {
                this.setState({ id: dbId, kpis: data.kpis, graphs: data.graphs, widths: data.widths, heights: data.heights, data: { title:data.title, widgets: _.sortBy(data.widgets, ['id','title']), is_active: data.is_active } });
            }
        }
        catch (ex) {
            if (ex.response) toastError(ex.response.data);
        }
    }

    doSubmit = async () => {
        if (window.location.href.split('managepersonaldashboards/')[1] === 'new') {
            try {
                await saveAdminDashboard(this.state);
                window.location = "/managepersonaldashboards";
            }
            catch (ex) {
                if (ex.response) {
                    toastError(ex.response.data);
                }
                logger.log(ex);
            }
        } else {
            try {
                await updateAdminDashboard(this.state);
                toastSuccess(<Translation>saved-success</Translation>);
            }
            catch (ex) {
                if (ex.response) {
                    toastError(ex.response.data);
                }
                logger.log(ex);
            }
        }
    }

    render() {
        return (
            <main className="container-fluid">
                <PageTitle title="p-dbs" saveButton={{label: 'save', div: "d-md-flex justify-content-md-end", validate: this.validate, submit: this.handleSubmit}} />
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-dp">
                            <div className="card-body">
                                <form onSubmit={this.handleSubmit} >
                                    {this.renderInput("title", "title", "text")}
                                    {this.renderCheckbox("is_active", "active")}
                                    {this.renderWidgets("widgets", "widgets")}
                                    {this.renderButton("save")}
                                </form>
                            </div>
                        </div>
                            <table className="table table-explanation">
                                <tbody>
                                    <tr><th><Translation>expl-entry</Translation></th><td></td></tr>
                                    <tr><td><Translation>order</Translation></td><td><Translation>i-order</Translation></td></tr>
                                    <tr><td><Translation>kpi</Translation></td><td><Translation>i-kpi</Translation></td></tr>
                                    <tr><td><Translation>title</Translation></td><td><Translation>i-title</Translation></td></tr>
                                    <tr><td><Translation>width</Translation></td><td><Translation>i-width</Translation></td></tr>
                                    <tr><td><Translation>height</Translation></td><td><Translation>i-height</Translation></td></tr>
                                    <tr><td><Translation>max-display-n</Translation></td><td><Translation>i-max-display-n</Translation></td></tr>
                                    <tr><td><Translation>trend</Translation></td><td><Translation>i-trend</Translation></td></tr>
                                    <tr><td><Translation>trend-hour</Translation></td><td><Translation>i-trend-hour</Translation></td></tr>
                                    <tr><td><Translation>action</Translation></td><td><Translation>i-action</Translation></td></tr>
                                </tbody>
                            </table>
                    </div>
                </div>
            </main>
        );
    }
}

export default AdminDashboardForm;
