import React from 'react';

const InputText = ({ name, type, ...rest }) => {    
    return ( 
        <div className="height-100">
            <textarea className="input-text" {...rest} name={name} id={name}></textarea>
        </div>
     );
}
 
export default InputText;
