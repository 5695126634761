import React from 'react';
import DashboardTitle from '../common/dashboardTitle';
import Report from './report';
import Translation from '../../services/translationService';
import _ from 'lodash';

function Reports(props) {
    let reports = _.sortBy( props.reports , 'name' );
    return (
        <main className="container-fluid">
            <DashboardTitle title={'reports'} />
            {reports && props.reports.length ? reports.map(x => <Report key={x._id} data={x} startDate={props.startDate} endDate={props.endDate} branches={props.branches}/>) : <div className="col-lg-12"><div className="card card-dp"><div className="card-body"><p><Translation>no-reports</Translation></p></div></div></div>}
        </main>
    );
}

export default Reports;
