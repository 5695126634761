import React, { useContext } from 'react';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker, Popup, LayersControl, TileLayer, LayerGroup } from 'react-leaflet';
import IconColor from './icon';
import { t } from '../../../services/translationService';
import LanguageContext from '../../../context/languageContext';

const center = [52.152199, 5.485682];

function Gismap({ locations }) { 
    let { lng } = useContext(LanguageContext);
    return (
        <MapContainer center={center} zoom={8}>
            <LayersControl position="bottomright">
                <LayersControl.BaseLayer checked name={t('map-color',lng)}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap-bijdragers</a>'
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name={t('map-gray',lng)}>
                    <TileLayer
                        url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy;<a href="https://carto.com/attribution">CARTO</a>'
                    />
                </LayersControl.BaseLayer>
                <LayersControl.Overlay checked name={t('branches',lng)}>
                    <LayerGroup>
                        {locations.map(l => l.lat ? <Marker key={l._id} position={[l.lat,l.lon]} icon={IconColor('red')}><Popup><b>{t('branch',lng)}</b>: {l.label}</Popup></Marker> : null)}   
                    </LayerGroup>
                </LayersControl.Overlay>
            </LayersControl>
        </MapContainer>
    );
}

export default Gismap;
