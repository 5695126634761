import { useContext } from 'react';
import _ from 'lodash';
import LanguageContext from '../context/languageContext';

const translations = {
    "login": {"nl": "Inloggen", "en": "Login", "de":"Anmelden"},
    "log-in": {"nl": "Log in", "en": "Log in", "de":"Anmelden"},
    "logout": {"nl": "Uitloggen", "en": "Logout", "de":"Abmelden"},
	"branches": {"nl": "Locaties", "en": "Branches", "de":"Branches"},
    "branch": {"nl": "Locatie", "en": "Branch", "de":"Branch"},
    "chains": {"nl": "Ketens", "en": "Chains", "de":"Ketten"},
    "chain": {"nl": "Keten", "en": "Chain", "de":"Kette"},
    "formulas": {"nl": "Formules", "en": "Formulas", "de":"Formeln"},
    "formula": {"nl": "Formule", "en": "Formula", "de":"Formel"},
	"regions": {"nl": "Regio’s", "en": "Regions", "de":"Regionen"},
    "region": {"nl": "Regio", "en": "Region", "de":"Region"},
    "profile": {"nl": "Profiel", "en": "Profile", "de":"Profil"},
    "p-dbs": {"nl": "Mijn dashboards", "en": "My dashboards", "de":"Meine Dashboards"},
    "s-dbs": {"nl": "Dataprofit dashboards", "en": "Dataprofit dashboards", "de":"Dataprofit Dashboards"},
    "dbs": {"nl": "dashboards", "en": "dashboards", "de":"Dashboards"},
    "no-dbs": {"nl": "Geen dashboards", "en": "No dashboards", "de":"Keine Dashboards"},
    "gis-map": {"nl": "Kaart", "en": "Map", "de":"Karte"},
    "gismap": {"nl": "GISkaart", "en": "GISmap", "de":"GISKarte"},
    "map-gray": {"nl": "Grijze kaart", "en": "Gray map", "de":"Graue Karte"},
    "map-color": {"nl": "Gekleurde kaart", "en": "Colored map", "de":"Farbige Karte"},
    "reports": {"nl": "Rapporten", "en": "Reports", "de":"Berichte"},
    "report": {"nl": "Rapport", "en": "Report", "de":"Bericht"},
    "reportmail": {"nl": "Rapporten mailen", "en": "Report mailing", "de":"Bericht versenden"},
    "usergroups": {"nl": "Gebruikersgroepen", "en": "User groups", "de":"Benutzergruppen"},
    "usergroup": {"nl": "Gebruikersgroep", "en": "User group", "de":"Benutzergruppe"},
    "users": {"nl": "Gebruikers", "en": "Users", "de":"Benutzer"},
    "user": {"nl": "Gebruiker", "en": "User", "de":"Benutzer"},
    "username": {"nl": "Gebruikersnaam", "en": "Username", "de":"Nutzername"},
    "language": {"nl": "Taal", "en": "Language", "de":"Sprache"},
    "overview": {"nl": "Overzicht", "en": "Overview", "de":"Überblick"},
    "openingtime": {"nl": "Openingstijd", "en": "Opening time", "de":"Öffnungszeit"},
    "closingtime": {"nl": "Sluitingstijd", "en": "Closing time", "de":"Geschäftsschluss"},
    "vestaboards": {"nl": "Vestaboards", "en": "Vestaboards", "de":"Vestaboards"},
    "vestaboard": {"nl": "Vestaboard", "en": "Vestaboard", "de":"Vestaboard"},
    "vestaboard-id": {"nl": "Vestaboard ID", "en": "Vestaboard ID", "de":"Vestaboard ID"},
    "vestaboards-n": {"nl": "Aantal Vestaboards", "en": "Number of Vestaboards", "de":"Anzahl der Vestaboards"},
    "vestaboard-products": {"nl": "Vestaboard producten", "en": "Vestaboard products", "de":"Vestaboard Produkte"},
    "products": {"nl": "Producten", "en": "Products", "de":"Produkte"},
    "products-n": {"nl": "Aantal producten", "en": "Number of products", "de":"Anzahl der Produkte"},
    "label": {"nl": "Label", "en": "Label", "de":"Label"},
    "weigh": {"nl": "Gewogen", "en": "Weighed", "de":"Gewogen"},
    "display": {"nl": "Weergeven", "en": "Display", "de":"Anzuzeigen"},
    "display-name": {"nl": "Weergavenaam", "en": "Display name", "de":"Anzeigename"},
    "product": {"nl": "Product", "en": "Product", "de":"Produkt"},
    "tab": {"nl": "Tabblad", "en": "Tab", "de":"Tab"},
    "pricingrules": {"nl": "Prijsregels", "en": "Pricing rules", "de":"Preisregeln"},

    "manage-groups": {"nl": "Beheer gebruikersgroepen", "en": "Manage user groups", "de":"Benutzergruppen verwalten"},
    "manage-users": {"nl": "Beheer gebruikers", "en": "Manage users", "de":"Benutzer verwalten"},
    "manage-chains": {"nl": "Beheer ketens", "en": "Manage chains", "de":"Ketten verwalten"},
    "manage-formulas": {"nl": "Beheer formules", "en": "Manage formulas", "de":"Formeln verwalten"},
    "manage-regions": {"nl": "Beheer regio's", "en": "Manage regions", "de":"Regionen verwalten"},
    "manage-p-dbs": {"nl": "Beheer Mijn dashboards", "en": "Manage My dashboards", "de":"Meine Dashboards verwalten"},
    "manage-c-dbs": {"nl": "Beheer XX dashboards", "en": "Manage XX dashboards", "de":"XX Dashboards verwalten"},
    "manage-reportmail": {"nl": "Beheer rapporten mailen", "en": "Manage report mailing", "de":"Bericht versenden verwalten"},
    "manage-vestaboards": {"nl": "Beheer Vestaboards", "en": "Manage Vestaboards", "de":"Vestaboards verwalten"},
    "manage-vestaboardproducts": {"nl": "Beheer Vestaboard producten", "en": "Manage Vestaboard products", "de":"Vestaboard Produkte verwalten"},
    "new-p-dashboard": {"nl": "Nieuw dashboard", "en": "New dashboard", "de":"Neues Dashboard"},

    "all": {"nl": "Alle", "en": "All", "de":"Alle"},
    "no-notifications": {"nl": "Geen nieuwe notificaties.", "en": "No new notifications.", "de":"Keine neuen Benachrichtigungen."},
    "no-reports": {"nl": "Geen rapporten beschikbaar.", "en": "No reports available.", "de":"Keine Berichte verfügbar."},
    "name": {"nl": "Naam", "en": "Name", "de":"Name"},

    "password": {"nl": "Wachtwoord", "en": "Password", "de":"Passwort"},
    "passwordnew": {"nl": "Nieuw wachtwoord", "en": "New password", "de":"Neues Passwort"},
    "passwordrepeat": {"nl": "Herhaal wachtwoord", "en": "Repeat password", "de":"Passwort wiederholen"},
    "email": {"nl": "E-mail", "en": "Email", "de":"E-Mail"},
    "save": {"nl": "Opslaan", "en": "Save", "de":"Speichern"},
    "search": {"nl": "Zoeken", "en": "Search", "de":"Suche"},
    "active": {"nl": "Actief", "en": "Active", "de":"Aktiv"},
    "title": {"nl": "Titel", "en": "Title", "de":"Titel"},
    "status": {"nl": "Status", "en": "Status", "de":"Status"},
    "action": {"nl": "Actie", "en": "Action", "de":"Aktion"},
    "receivers": {"nl": "Ontvangers", "en": "Receivers", "de":"Empfänger"},
    "description": {"nl": "Omschrijving", "en": "Description", "de":"Bezeichnung"},
    "auto-reset-password": {"nl": "Automatisch wachtwoord herstel", "en": "Automatically reset password", "de": "Passwort automatisch zurücksetzen"},
    "role": {"nl": "Rol", "en": "Role", "de":"Rolle"},
    "widgets": {"nl": "Widgets", "en": "Widgets", "de":"Widgets"},
    "order": {"nl": "Volgorde", "en": "Order", "de":"Reihenfolge"},
    "kpi": {"nl": "KPI", "en": "KPI", "de":"KPI"},
    "width": {"nl": "Breedte", "en": "Width", "de":"Breite"},
    "height": {"nl": "Hoogte", "en": "Height", "de":"Höhe"},
    "max-display-n": {"nl": "Max weergave aantal", "en": "Max display number", "de":"Max Anzeigenummer"},
    "period": {"nl": "Periode", "en": "Period", "de":"Zeitspanne"},
    "html-message": {"nl": "HTML bericht", "en": "HTML message", "de":"HTML Botschaft"},
    "text-message": {"nl": "Tekst bericht", "en": "Text message", "de":"Text Botschaft"},
    "subject": {"nl": "Onderwerp", "en": "Subject", "de":"Thema"},
    "filename": {"nl": "Bestandsnaam", "en": "File name", "de":"Dateiname"},
    "receivers-explanation": {"nl": "Ontvangers (gebruik komma tussen verschillende e-mailadressen)", "en": "Receivers (use comma between email addresses)", "de":"Empfänger (Verwenden Sie Kommas zwischen E-Mail-Adressen)"},
    "hour": {"nl": "Uur", "en": "Hour", "de":"Stunde"},
    "timezone": {"nl": "Tijdzone", "en": "Timezone", "de":"Zeitzone"},
    "weekdays": {"nl": "Weekdagen", "en": "Weekdays", "de":"Wochentag"},
    "monthdays": {"nl": "Dagen in de maand", "en": "Days in the month", "de":"Tage im Monat"},
    "selected": {"nl": "Geselecteerd", "en": "Selected", "de":"Ausgewählt"},
    "selectall": {"nl": "alles selecteren", "en": "select all", "de":"alle wählen"},
    "deselectall": {"nl": "alles deselecteren", "en": "deselect all", "de":"alle abwählen"},
    "linked-users": {"nl": "Gekoppelde gebruikers", "en": "Linked users", "de":"Verknüpfte Benutzer"},
    "apply": {"nl": "Toepassen", "en": "Apply", "de":"Bewerben"},
    "day": {"nl": "Dag", "en": "Day", "de":"Tag"},
    "week": {"nl": "Week", "en": "Week", "de":"Woche"},
    "month": {"nl": "Maand", "en": "Month", "de":"Monat"},
    "year": {"nl": "Jaar", "en": "Year", "de":"Jahr"},
    "trend": {"nl": "Trend", "en": "Trend", "de":"Trend"},
    "no-trend": {"nl": "Geen trend beschikbaar", "en": "No trend available", "de":"Kein Trend vorhanden"},
    "trend-hour": {"nl": "t/m huidig uur", "en": "until current hour", "de":"bis zur aktuellen Stunde"},
    "last_login": {"nl": "Laatste inlog", "en": "Last login", "de":"letzte Anmeldung"},

    "expl-entry": {"nl": "Toelichting invulvelden", "en": "Explanation entry fields", "de":"Erläuterung Eingabefelder"},
    "i-order": {"nl": "Volgorde waarin de widgets in het dashboard weergegeven worden", "en": "Order in which the widgets are displayed in the dashboard", "de":"Reihenfolge, in der die Widgets im Dashboard angezeigt werden"},
    "i-kpi": {"nl": "Weergegeven KPI", "en": "Displayed KPI", "de":"Angezeigter KPI"},
    "i-title": {"nl": "Eigen gekozen titel die wordt weergegeven in de widget", "en": "Own chosen title that is displayed in the widget", "de":"Eigener gewählter Titel, der im Widget angezeigt wird"},
    "i-width": {"nl": "Breedte van de widget, waarbij 12 de totale breedte van het scherm is, 6 de helft, 3 een kwart, etc.", "en": "Width of the widget, where 12 is the total width of the screen, 6 is half, 3 is a quarter, etc.", "de":"Breite des Widgets, wobei 12 die Gesamtbreite des Bildschirms ist, 6 die Hälfte, 3 ein Viertel usw."},
    "i-height": {"nl": "Hoogte van de widget, kies 1 bij een enkel getal widget en 3 is standaard hoogte voor een grafiek of getal widget incl trend", "en": "Height of the widget, choose 1 for a single number widget and 3 is the standard height for a graph or number widget including trend", "de":"Höhe des Widgets, wählen Sie 1 für ein einzelnes Zahlen-Widget und 3 ist die Standardhöhe für ein Diagramm oder Zahlen-Widget einschließlich Trend"},
    "i-max-display-n": {"nl": "Het maximale aantal weergegeven elementen bij bepaalde grafieken. Voorbeeld: vul 20 in bij de KPI 'Omzet per product' om de top 20 producten weer te geven.", "en": "The maximum number of elements displayed on certain charts. Example: Enter 20 for the KPI 'Revenue per product' to display the top 20 products.", "de":"Die maximale Anzahl von Elementen, die in bestimmten Diagrammen angezeigt werden. Beispiel: Geben Sie 20 für den KPI „Umsatz pro Produkt“ ein, um die Top-20-Produkte anzuzeigen."},
    "i-trend": {"nl": "Mogelijkheid tot weergeven trend van de afgelopen 6 vergelijkbare periodes, indien mogelijk bij gekozen KPI", "en": "Possibility to display the trend of the past 6 comparable periods, if possible with the chosen KPI", "de":"Möglichkeit, den Trend der letzten 6 vergleichbaren Perioden anzuzeigen, wenn möglich mit dem gewählten KPI"},
    "i-action": {"nl": "Toevoegen of verwijderen van een widget", "en": "Add or remove a widget", "de":"Fügen Sie ein Widget hinzu oder entfernen Sie es"},
    "i-trend-hour": {"nl": "Indien aangegevinkt, wordt de trend voor vandaag, deze week, deze maand en dit jaar weergegeven t/m het huidige uur", "en": "When checked, the trend for today, this week, this month and this year is displayed up to the current hour", "de":"Wenn diese Option aktiviert ist, wird der Trend für heute, diese Woche, diesen Monat und dieses Jahr bis zur aktuellen Stunde angezeigt"},
    
    "password-req": {"nl": "Wachtwoordvereisten (minstens)", "en": "Password requirements (at least)", "de":"Passwortanforderungen (mindestens)"},
    "password-forgot?": {"nl": "Wachtwoord vergeten?", "en": "Forgot your password?", "de":"Passwort vergessen?"},
    "password-forgot": {"nl": "Wachtwoord vergeten", "en": "Forgot your password", "de":"Passwort vergessen"},
    "password-change": {"nl": "Wachtwoord wijzigen", "en": "Change password", "de":"Passwort ändern"},
    "password-change-info": {"nl": "We hebben een verificatie code verstuurd via de e-mail. Gebruik die code hieronder om je wachtwoord te wijzigen.", "en": "We have sent a verification code via email. Use that code below to change your password.", "de":"Wir haben einen Bestätigungscode per E-Mail gesendet. Verwenden Sie diesen Code unten, um Ihr Passwort zu ändern."},
    "send": {"nl": "Verzenden", "en": "Send", "de":"Senden"},
    "change": {"nl": "Wijzig", "en": "Change", "de":"Veränderung"},
    "back-login": {"nl": "Terug naar inloggen", "en": "Back to login", "de":"Zurück zur Anmeldung"},
    "verification-code": {"nl": "Verificatie code", "en": "Verification code", "de":"Bestätigungscode"},

    "number": {"nl": "nummer", "en": "number", "de":"Nummer"},
    "characters": {"nl": "tekens", "en": "characters", "de":"Figuren"},
    "s-characters": {"nl": "speciaal leesteken", "en": "special character", "de":"Sonderzeichen"},
    "uppercase": {"nl": "hoofdletter", "en": "uppercase letter", "de":"Großbuchstabe"},
    "lowercase": {"nl": "kleine letter", "en": "lowercase letter", "de":"Kleinbuchstabe"},

    "saved-success": {"nl": "Succesvol opgeslagen.", "en": "Saved successfully.", "de":"Erfolgreich gespeichert."},
    "delete-success": {"nl": "Succesvol verwijderd.", "en": "Deleted successfully.", "de":"Erfolgreich entfernt."},
    "delete-error": {"nl": "Verwijderen niet mogelijk.", "en": "Deletion is not possible.", "de":"Löschen ist nicht möglich."},
    "changes-made": {"nl": "Wijzigingen doorgevoerd.", "en": "Changes made.", "de":"Änderungen vorgenommen."},
    "no-changes": {"nl": "Geen wijzigingen.", "en": "No changes.", "de":"Keine Änderungen."},
    "invalid-report": {"nl": "Ongeldig rapport!", "en": "Invalid report!", "de":"Ungültiger Bericht!"},
    "invalid-request": {"nl": "Ongeldig verzoek!", "en": "Invalid request!", "de":"Ungültiger Anfrage!"},
    "unexpected-error": {"nl": "Onverwachte fout opgetreden.", "en": "An unexpected error occurred.", "de":"Ein unerwarteter Fehler ist aufgetreten."},
    "no-branch": {"nl": "Geen locatie geselecteerd.", "en": "No branch selected.", "de":"Kein Branch ausgewählt."},

    "monday": {"nl": "Maandag", "en": "Monday", "de":"Montag"},
    "tuesday": {"nl": "Dinsdag", "en": "Tuesday", "de":"Dienstag"},
    "wednesday": {"nl": "Woensdag", "en": "Wednesday", "de":"Mittwoch"},
    "thursday": {"nl": "Donderdag", "en": "Thursday", "de":"Donnerstag"},
    "friday": {"nl": "Vrijdag", "en": "Friday", "de":"Freitag"},
    "saturday": {"nl": "Zaterdag", "en": "Saturday", "de":"Samstag"},
    "sunday": {"nl": "Zondag", "en": "Sunday", "de":"Sonntag"},

    "january": {"nl": "Januari", "en": "January", "de":"Januar"},
    "february": {"nl": "Februari", "en": "February", "de":"Februar"},
    "march": {"nl": "Maart", "en": "March", "de":"März"},
    "april": {"nl": "April", "en": "April", "de":"April"},
    "may": {"nl": "Mei", "en": "May", "de":"Mai"},
    "june": {"nl": "Juni", "en": "June", "de":"Juni"},
    "july": {"nl": "Juli", "en": "July", "de":"Juli"},
    "august": {"nl": "Augustus", "en": "August", "de":"August"},
    "september": {"nl": "September", "en": "September", "de":"September"},
    "october": {"nl": "Oktober", "en": "October", "de":"Oktober"},
    "november": {"nl": "November", "en": "November", "de":"November"},
    "december": {"nl": "December", "en": "December", "de":"Dezember"},

    "today": {"nl": "Vandaag", "en": "Today", "de":"Heute"},
    "yesterday": {"nl": "Gisteren", "en": "Yesterday", "de":"Gestern"},
    "this-week": {"nl": "Deze week", "en": "This week", "de":"Dieser Woche"},
    "last-week": {"nl": "Vorige week", "en": "Last week", "de":"Letzte Woche"},
    "this-month": {"nl": "Deze maand", "en": "This month", "de":"Diesen Monat"},
    "last-month": {"nl": "Vorige maand", "en": "Last month", "de":"Letzte Monat"},
    "this-year": {"nl": "Dit jaar", "en": "This year", "de":"Dieses Jahr"},

    "revenue_total": {"nl": "Omzet netto totaal", "en": "Revenue net total", "de":"Gesamtumsatz netto"},
    "corrections": {"nl": "Correcties bruto", "en": "Corrections gross", "de":"Korrekturen Brutto"},
    "checks": {"nl": "Bonnen", "en": "Checks", "de":"Kassenbon"},
    "check_avg": {"nl": "Bon gemiddelde (netto omzet)", "en": "Check average (net revenue)", "de":"Kassenbon Durchschnitt (Nettoumsatz)"},
    "covers": {"nl": "Couverts", "en": "Covers", "de":"Gedecke"},
    "cover_avg": {"nl": "Couvert gemiddelde (netto omzet)", "en": "Cover average (net revenue)", "de":"Gedecke Durchschnitt (Nettoumsatz)"},
    "labour_clocked": {"nl": "Personeel ingeklokt", "en": "Employees clocked in", "de":"Mitarbeiter eingetaktet"},
    "labour_hours": {"nl": "Personeel totaal gewerkte uren", "en": "Employees total worked hours", "de":"Mitarbeiter gearbeitete Stunden"},
    "productivity": {"nl": "Productiviteit", "en": "Productivity", "de":"Produktivität"},
    "labour_costs": {"nl": "Personeelskosten", "en": "Labour costs", "de":"Personalkosten"},
    "labour_costs_p": {"nl": "Personeelskosten (%)", "en": "Labour costs (%)", "de":"Personalkosten (%)"},
    "revenue_branches": {"nl": "Omzet per locatie", "en": "Revenue per branch", "de":"Umsatz pro branch"},
    "revenue_revenue_centers": {"nl": "Omzet per Revenue Center", "en": "Revenue per Revenue Center", "de":"Umsatz pro Revenue Center"},
    "revenue_product_group": {"nl": "Omzet per product groep", "en": "Revenue per product group", "de":"Umsatz pro Produktgruppe"},
    "revenue_product_group_click": {"nl": "Omzet per product groep doorklikbaar", "en": "Revenue per product group clickable", "de":"Umsatz pro Produktgruppe doorklikbaar"},
    "revenue_product": {"nl": "Omzet per product", "en": "Revenue per product", "de":"Umsatz pro Produkt"},
    "revenue_product_total": {"nl": "Product omzet totaal", "en": "Product revenue total", "de":"Gesamten Produktumsatz"},
    "revenue_group_total": {"nl": "Revenue Group omzet totaal", "en": "Revenue Group total", "de":"Gesamten Revenue Group umsatz"},
    "revenue_hours": {"nl": "Omzet per uur", "en": "Revenue per hour", "de":"Umsatz pro Stunde"},
    "revenue_dayparts": {"nl": "Omzet per dagdeel", "en": "Revenue per daypart", "de":"Umsatz pro Tagesteil"},
    "product_group_count": {"nl": "Productgroep aantallen", "en": "Product group count", "de":"Produktgruppennummern"},
    "product_count": {"nl": "Product aantallen", "en": "Product count", "de":"Produktnummern"},
    "sales_planning": {"nl": "Sales planning per dag", "en": "Sales planning per day", "de":"Verkaufsplanung pro Tag"},
    "sales_planning_tot": {"nl": "Sales planning totaal", "en": "Sales planning total", "de":"Verkaufsplanung insgesamt"},
    "sales_planning_rg": {"nl": "Sales planning per Revenue Group", "en": "Sales planning per Revenue Group", "de":"Verkaufsplanung pro Revenue Group"},
    "revenue_group_restaurant": {"nl": "Omzet Restaurant", "en": "Revenue Restaurant", "de":"Umsatz Restaurant"},
    "revenue_group_banqueting": {"nl": "Omzet Banqueting", "en": "Revenue Banqueting", "de":"Umsatz Bankett"},
    "revenue_group_hotel": {"nl": "Omzet Hotel", "en": "Revenue Hotel", "de":"Umsatz Hotel"},
    "covers_branches": {"nl": "Couverts per locatie", "en": "Covers per branch", "de":"Gedecke pro Branch"},
    "checks_branches": {"nl": "Bonnen per locatie", "en": "Checks per branch", "de":"Kassenbon pro Branch"},
    "checks_avg_branches": {"nl": "Bonn gemiddelde per locatie", "en": "Check average per branch", "de":"Kassenbon Durchschnitt pro Branch"},
    "corrections_branches": {"nl": "Correcties bruto per locatie", "en": "Corrections gross per branch", "de":"Korrekturen Brutto pro Branch"},
    "cover_avg_branches": {"nl": "Couvert gemiddelde (netto omzet) per locatie", "en": "Cover average (net revenue) per branch", "de":"Gedecke Durchschnitt (Nettoumsatz) pro Branch"},
    "revenue_gross_total": {"nl": "Omzet bruto totaal", "en": "Revenue gross total", "de":"Gesamtumsatz brutto"},
    "paid_net_total": {"nl": "Omzet afgerekend netto totaal", "en": "Revenue paid net total", "de":"Gesamtumsatz abgerechneter netto"},
    "net_with_covers": {"nl": "Omzet met couverts netto totaal", "en": "Revenue with covers net total", "de":"Gesamtumsatz netto mit Gedecke"},
    "net_without_covers": {"nl": "Omzet zonder couverts netto totaal", "en": "Revenue without covers net total", "de":"Gesamtumsatz netto ohne Gedecke"},
    "checks_with_covers": {"nl": "Bonnen met couverts", "en": "Checks with covers", "de":"Kassenbon mit Gedecke"},
    "checks_without_covers": {"nl": "Bonnen zonder couverts", "en": "Checks without covers", "de":"Kassenbon ohne Gedecke"},
    "check_avg_with_covers": {"nl": "Bon gemiddelde (netto omzet met couverts)", "en": "Check average (Net revenue with covers)", "de":"Kassenbon Durchschnitt (Nettoumsatz mit Gedecke)"},
    "check_avg_without_covers": {"nl": "Bon gemiddelde (netto omzet zonder couverts)", "en": "Check average (Net revenue without covers)", "de":"Kassenbon Durchschnitt (Nettoumsatz ohne Gedecke)"},
    "check_avg_gross": {"nl": "Bon gemiddelde (bruto omzet)", "en": "Check average (gross revenue)", "de":"Kassenbon Durchschnitt (Bruttoumsatz)"},
    "cover_avg_with_covers": {"nl": "Couvert gemiddelde (netto omzet met couverts)", "en": "Cover average (Net revenue with covers)", "de":"Gedecke Durchschnitt (Nettoumsatz mit Gedecke)"},
    "cover_n_avg": {"nl": "Couvert aantal gemiddeld", "en": "Cover number average", "de":"Gedecke Anzahl Durchschnitt"},
    "check_duration": {"nl": "Bon duur gemiddeld", "en": "Check duration average", "de":"Kassenbon Dauer Durchschnitt"},
    "corrections_hours": {"nl": "Correcties bruto per uur", "en": "Corrections gross per hour", "de":"Korrekturen Brutto pro Stunde"},
    "labour_emp_total_hours": {"nl": "Personeel totaal gewerkte uren", "en": "Employees total worked hours", "de":"Mitarbeiter gearbeitete Stunden"},
    "labour_emp_sickness": {"nl": "Personeel totale ziekte uren", "en": "Employees sicknesshours", "de":"Mitarbeiter Krankheitsstunden"},
    "labour_emp_absence": {"nl": "Personeel afwezigheidspercentage (obv geplande uren)", "en": "Employees absence rate (based on planned hours)", "de":"Mitarbeiter Abwesenheitsrate (basierend auf geplanten Stunden)"},
    "labour_emp_absences": {"nl": "Personeel ziekte per medewerker (obv geplande uren)", "en": "Employees sickness per employee (based on planned hours)", "de":"Mitarbeiter Krankheit pro Mitarbeiter (basierend auf geplanten Stunden)"},
    "labour_emp_hours": {"nl": "Personeel geplande en geklokte uren per medewerker", "en": "Employees planned and clocked hours per employee", "de":"Mitarbeiter Stunden pro Mitarbeiter"},
    "cover_avg_with_covers_branches": {"nl": "Couvert gemiddelde (netto omzet met couverts) per locatie", "en": "Cover average (Net revenue with covers) per branch", "de":"Gedecke Durchschnitt (Nettoumsatz mit Gedecke) pro Branch"},
    "productivity_branches": {"nl": "Productiviteit per locatie", "en": "Productivity per branch", "de":"Produktivität pro Branch"},
    "labour_costs_p_branches": {"nl": "Personeelskosten (%) per locatie", "en": "Labour costs (%) per branch", "de":"Personalkosten (%) pro Branch"},
    "labour_costs_branches": {"nl": "Personeelskosten per locatie", "en": "Labour costs per branch", "de":"Personalkosten pro Branch"},
    "labour_clocked_branches": {"nl": "Personeel ingeklokt per locatie", "en": "Employees clocked in per branch", "de":"Mitarbeiter eingetaktet pro Branch"},
    "check_avg_branches": {"nl": "Bon gemiddelde (netto omzet) per locatie", "en": "Check average (net revenue) per branch", "de":"Kassenbon Durchschnitt (Nettoumsatz) pro Branch"},
    "check_avg_gross_branches": {"nl": "Bon gemiddelde (bruto omzet) per locatie", "en": "Check average (gross revenue) per branch", "de":"Kassenbon Durchschnitt (Bruttoumsatz) pro Branch"},
    "corrections_net": {"nl": "Correcties netto", "en": "Corrections net", "de":"Korrekturen Netto"},
    "corrections_p_branches": {"nl": "Correcties bruto (%) per locatie", "en": "Corrections gross (%) per branch", "de":"Korrekturen Brutto (%) pro Branch"},
    "corrections_discounts": {"nl": "Correcties bruto vs Kortingen bruto", "en": "Corrections gross vs Discounts gross", "de":"Korrekturen Brutto vs Rabatte Brutto"},
    "corrections_net_branches": {"nl": "Correcties netto per locatie", "en": "Corrections net per branch", "de":"Korrekturen Netto pro Branch"},
    "revenue_same_time": {"nl": "Omzet netto tot huidige tijd", "en": "Revenue net to current time", "de":"Umsatz netto zum aktuellen Zeitpunkt"},
    "revenue_same_hour": {"nl": "Omzet netto t/m huidige uur", "en": "Revenue net to current hour", "de":"Umsatz netto zum aktuellen Stunde"},
    "payment_types": {"nl": "Betaalmethoden", "en": "Payment types", "de":"Bezahlmöglichkeiten"},
    "hr_absence": {"nl": "Personeel verzuimpercentage", "en": "Employees absence rate", "de":" Mitarbeiter Abwesenheitsrate"},
    "hr_emp_n_sick": {"nl": "Personeel aantal ziekmeldingen per medewerker", "en": "Employees number of sick leave reports per employee", "de":"Mitarbeiter Anzahl der Krankmeldungen pro Mitarbeiter"},
    "hr_perm_emp_n_sick": {"nl": "Personeel aantal ziekmeldingen per vaste medewerker", "en": "Employees number of sick leave reports per permanent employee", "de":"Mitarbeiter Anzahl der Krankmeldungen pro Festangestellte Mitarbeiter"},
    "hr_emp_sickness": {"nl": "Personeel ziekte uren per medewerker", "en": "Employees sicknesshours per employee", "de":"Mitarbeiter Krankheitsstunden pro Mitarbeiter"},
    "hr_hours_ratio": {"nl": "Personeel verdeling van de gewerkte uren voor vaste tov flex medewerkers", "en": "Employees distribution of worked hours for permanent versus flexible employees", "de":"Mitarbeiter Verteilung der geleisteten Arbeitsstunden zwischen festangestellten und flexiblen Mitarbeitern"},
    "hr_n_sick": {"nl": "Personeel aantal ziekmeldingen", "en": "Employees number of sick leave reports", "de":"Mitarbeiter Anzahl der Krankmeldungen"},
    "hr_perm_n_sick": {"nl": "Personeel aantal ziekmeldingen van vaste medewerkers", "en": "Employees number of sick leave reports of permanent employees", "de":"Mitarbeiter Anzahl der Krankmeldungen von Festangestellte Mitarbeiter"},
    "hr_perm_emp_sick": {"nl": "Personeel ziekte uren per vaste medewerker", "en": "Employees sicknesshours per permanent employee", "de":"Mitarbeiter Krankheitsstunden pro Festangestellte Mitarbeiter"},
    "hr_perm_category": {"nl": "Personeel ziekte categoriën van vaste medewerkers", "en": "Employees sick categories of permanent employees", "de":"Mitarbeiter Kategorien von Personalkrankheiten von Festangestellte Mitarbeiter"},
    "hr_permanent_absence": {"nl": "Personeel verzuimpercentage vaste medewerkers", "en": "Employees absence rate permanent employee", "de":" Mitarbeiter Abwesenheitsrate Festangestellte Mitarbeiter"},
    "hr_permanent_combi_absence": {"nl": "Personeel verzuimpercentage = ziekte uren / totale uren voor vaste medewerkers", "en": "Employees absence rate = sicknesshours divided by the total hours for permanent employee", "de":"Mitarbeiter Abwesenheitsrate = Krankheitsstunden dividiert durch Gesamtstunden für Festangestellte Mitarbeitern"},
    "hr_permanent_combi_hours": {"nl": "Personeel totale uren vaste medewerkers", "en": "Employees total hours permanent employee", "de":"Mitarbeiter Gesamtstunden Festangestellte Mitarbeiter"},
    "hr_permanent_hours": {"nl": "Personeel gewerkte uren vaste medewerkers", "en": "Employees worked hours permanent employee", "de":"Mitarbeiter gearbeitete Stunden Festangestellte Mitarbeiter"},
    "hr_permanent_sick": {"nl": "Personeel ziekte uren vaste medewerkers", "en": "Employees sicknesshours permanent employee", "de":"Mitarbeiter Krankheitsstunden Festangestellte Mitarbeiter"},
    "hr_sick": {"nl": "Personeel ziekte uren", "en": "Employees sicknesshours", "de":"Mitarbeiter Krankheitsstunden"},
    
    
    "i-revenue_total": {"nl": "Netto omzet totaal", "en": "Net revenue total", "de":"Gesamtumsatz netto"},
    "i-corrections": {"nl": "Correcties bruto bedrag", "en": "Corrections gross amount", "de":"Korrekturen Bruttobetrag"},
    "i-checks": {"nl": "Aantal bonnen", "en": "Number of checks", "de":"Anzahl der Kassenbon"},
    "i-check_avg": {"nl": "Netto omzet gedeeld door het aantal bonnen", "en": "Net revenue divided by the number of checks", "de":"Nettoumsatz dividiert durch die Anzahl der Kassenbon"},
    "i-covers": {"nl": "Aantal couverts", "en": "Number of covers", "de":"Anzahl der Gedecke"},
    "i-cover_avg": {"nl": "Totale netto omzet gedeeld gedeeld door aantal couverts", "en": "Total net revenue divided by the number of covers", "de":"Nettoumsatz dividiert durch die Anzahl der Gedecke"},
    "i-labour_clocked": {"nl": "Medewerkers ingeklokt", "en": "Employees clocked in", "de":"Mitarbeiter eingetaktet"},
    "i-labour_hours": {"nl": "Gewerkte uren", "en": "Worked hours", "de":"Gearbeitete Stunden"},
    "i-productivity": {"nl": "Productiviteit = netto omzet gedeeld door het aantal gewerkte uren", "en": "Productivity = net revenue divided by the worked hours", "de":"Produktivität = Nettoumsatz dividiert durch die gearbeitete Stunden"},
    "i-labour_costs": {"nl": "Personeelskosten", "en": "Labour costs", "de":"Personalkosten"},
    "i-labour_costs_p": {"nl": "Percentage personeelskosten van de netto omzet", "en": "Percentage labour costs of the net revenue", "de":"Anteil der Personalkosten am Nettoumsatz"},
    "i-revenue_branches": {"nl": "Netto omzet per locatie", "en": "Net revenue per branch", "de":"Nettoumsatz pro branch"},
    "i-revenue_revenue_centers": {"nl": "Netto omzet per Revenue Center", "en": "Net revenue per Revenue Center", "de":"Nettoumsatz pro Revenue Center"},
    "i-revenue_product_group": {"nl": "Netto omzet per product groep", "en": "Net revenue per product group", "de":"Nettoumsatz pro Produktgruppe"},
    "i-revenue_product_group_click": {"nl": "Netto omzet per product groep doorklikbaar naar onderliggende producten", "en": "Net revenue per product group clickable to underlying products", "de":"Nettoumsatz pro Produktgruppe anklickbar zu den zugrunde liegenden Produkten"},
    "i-revenue_product": {"nl": "Netto omzet per product", "en": "Net revenue per product", "de":"Nettoumsatz pro Produkt"},
    "i-revenue_product_total": {"nl": "Product omzet totaal", "en": "Product revenue total", "de":"Gesamten Produktumsatz"},
    "i-revenue_group_total": {"nl": "Revenue Group omzet totaal", "en": "Revenue Group total", "de":"Gesamten Revenue Group umsatz"},
    "i-revenue_hours": {"nl": "Netto omzet per uur", "en": "Net revenue per hour", "de":"Nettoumsatz pro Stunde"},
    "i-revenue_dayparts": {"nl": "Netto omzet per dagdeel", "en": "Net revenue per daypart", "de":"Nettoumsatz pro Tagesteil"},
    "i-product_group_count": {"nl": "Productgroep aantallen, waarvan de prijs groter is dan 0.", "en": "Product group count, whose price is greater than 0.", "de":"Produktgruppennummern, deren Preis größer als 0 ist."},
    "i-product_count": {"nl": "Product aantallen, waarvan de prijs groter is dan 0.", "en": "Product count, whose price is greater than 0.", "de":"Produktnummern, deren Preis größer als 0 ist."},
    "i-sales_planning": {"nl": "Sales planning per dag", "en": "Sales planning per day", "de":"Verkaufsplanung pro Tag"},
    "i-sales_planning_tot": {"nl": "Sales planning totaal", "en": "Sales planning total", "de":"Verkaufsplanung insgesamt"},
    "i-sales_planning_rg": {"nl": "Sales planning per Revenue Group", "en": "Sales planning per Revenue Group", "de":"Verkaufsplanung pro Revenue Group"},
    "i-revenue_group_restaurant": {"nl": "Netto omzet Restaurant", "en": "Net revenue Restaurant", "de":"Nettoumsatz Restaurant"},
    "i-revenue_group_banqueting": {"nl": "Netto omzet Banqueting", "en": "Net revenue Banqueting", "de":"Nettoumsatz Bankett"},
    "i-revenue_group_hotel": {"nl": "Netto omzet Hotel", "en": "Net revenue Hotel", "de":"Nettoumsatz Hotel"},
    "i-covers_branches": {"nl": "Aantal couverts per locatie", "en": "Number of covers per branch", "de":"Anzahl der Gedecke pro Branch"},
    "i-checks_branches": {"nl": "Bonnen per locatie", "en": "Checks per branch", "de":"Kassenbon pro Branch"},
    "i-corrections_branches": {"nl": "Correcties bruto bedrag per locatie", "en": "Corrections gross amount per branch", "de":"Korrekturen Bruttobetrag pro Branch"},
    "i-cover_avg_branches": {"nl": "Couvert gemiddelde per locatie", "en": "Cover average per branch", "de":"Gedecke Durchschnitt pro Branch"},
    "i-revenue_gross_total": {"nl": "Bruto omzet totaal", "en": "Gross revenue total", "de":"Gesamtumsatz brutto"},
    "i-paid_net_total": {"nl": "Afgerekende netto omzet totaal", "en": "Paid net revenue total", "de":"Abgerechneter Gesamtumsatz netto"},
    "i-net_with_covers": {"nl": "Netto omzet met couverts totaal", "en": "Revenue with covers net total", "de":"Gesamtumsatz netto mit Gedecke"},
    "i-net_without_covers": {"nl": "Netto omzet zonder couverts totaal", "en": "Revenue without covers net total", "de":"Gesamtumsatz netto ohne Gedecke"},
    "i-checks_with_covers": {"nl": "Aantal bonnen met couverts", "en": "Number of checks with covers", "de":"Kassenbon mit Gedecke"},
    "i-checks_without_covers": {"nl": "Aantal bonnen zonder couverts", "en": "Number of checks without covers", "de":"Kassenbon ohne Gedecke"},
    "i-check_avg_with_covers": {"nl": "Netto omzet met couverts gedeeld door het aantal bonnen met couverts", "en": "Net revenue with covers divided by the number of checks with covers", "de":"Nettoumsatz mit Gedecke dividiert durch die Anzahl der Kassenbon mit Gedecke"},
    "i-check_avg_without_covers": {"nl": "Netto omzet zonder couverts gedeeld door het aantal bonnen zonder couverts", "en": "Net revenue without covers divided by the number of checks without covers", "de":"Nettoumsatz ohne Gedecke dividiert durch die Anzahl der Kassenbon ohne Gedecke"},
    "i-check_avg_gross": {"nl": "Bruto omzet gedeeld door het aantal bonnen", "en": "Gross revenue divided by the number of checks", "de":"Bruttoumsatz dividiert durch die Anzahl der Kassenbon"},
    "i-cover_avg_with_covers": {"nl": "Netto omzet met couverts gedeeld door het aantal couverts", "en": "Net revenue with covers divided by the number of covers", "de":"Nettoumsatz mit Gedecke dividiert durch die Anzahl der Gedecke"},
    "i-cover_n_avg": {"nl": "Aantal couvert gedeeld door het aantal bonnen met couverts", "en": "Number of covers divided by the number of checks with covers", "de":"Anzahl der Gedecke dividiert durch die Anzahl der Kassenbon mit Gedecke"},
    "i-check_duration": {"nl": "Gemiddelde duur van een bon", "en": "Average duration of a check", "de":"Durchschnittliche Dauer einer Kassenbon"},
    "i-corrections_hours": {"nl": "Correcties bruto bedrag per uur", "en": "Corrections gross amount per hour", "de":"Korrekturen Bruttobetrag pro Stunde"},
    "i-labour_emp_total_hours": {"nl": "Gewerkte uren", "en": "Worked hours", "de":"Gearbeitete Stunden"},
    "i-labour_emp_sickness": {"nl": "Ziekte uren", "en": "Sicknesshours", "de":"Krankheitsstunden"},
    "i-labour_emp_absence": {"nl": "Afwezigheidspercentage = ziekte uren (obv geplande uren) gedeeld door gewerkte uren", "en": "Absence rate = sicknesshours (based on planned hours) divided by the worked hours", "de":"Abwesenheitsrate = Krankheitsstunden (basierend auf geplanten Stunden) dividiert durch gearbeitete Stunden"},
    "i-labour_emp_absences": {"nl": "Ziekte uren per medewerker (obv geplande uren)", "en": "Sicknesshours per employee (based on planned hours)", "de":"Krankheitsstunden pro Mitarbeiter (basierend auf geplanten Stunden)"},
    "i-labour_emp_hours": {"nl": "Geplande en geklokte uren per medewerker", "en": "Planned and worked hours per employee", "de":"Geplante und gearbeitete Stunden pro Mitarbeiter"},
    "i-cover_avg_with_covers_branches": {"nl": "Netto omzet met couverts gedeeld door het aantal couverts per locatie", "en": "Net revenue with covers divided by the number of covers per branch", "de":"Nettoumsatz mit Gedecke dividiert durch die Anzahl der Gedecke pro Branch"},
    "i-productivity_branches": {"nl": "Productiviteit = netto omzet gedeeld door het aantal gewerkte uren per locatie", "en": "Productivity = net revenue divided by the worked hours per branch", "de":"Produktivität = Nettoumsatz dividiert durch die gearbeitete Stunden pro Branch"},
    "i-labour_costs_p_branches": {"nl": "Percentage personeelskosten van de netto omzet per locatie", "en": "Percentage labour costs of the net revenue per branch", "de":"Anteil der Personalkosten am Nettoumsatz pro Branch"},
    "i-labour_costs_branches": {"nl": "Personeelskosten per locatie", "en": "Labour costs per branch", "de":"Personalkosten pro Branch"},
    "i-labour_clocked_branches": {"nl": "Aantal ingeklokte medewerkers per locatie", "en": "Number of employees clocked in per branch", "de":"Anzahl der Mitarbeiter eingetaktet pro Branch"},
    "i-check_avg_branches": {"nl": "Netto omzet gedeeld door het aantal bonnen per locatie", "en": "Net revenue divided by the number of checks per branch", "de":"Nettoumsatz dividiert durch die Anzahl der Kassenbon pro Branch"},
    "i-check_avg_gross_branches": {"nl": "Bruto omzet gedeeld door het aantal bonnen per locatie", "en": "Gross revenue divided by the number of checks per branch", "de":"Bruttoumsatz dividiert durch die Anzahl der Kassenbon pro Branch"},
    "i-corrections_net": {"nl": "Correcties netto bedrag", "en": "Corrections net amount", "de":"Korrekturen Nettobetrag"},
    "i-corrections_p_branches": {"nl": "Percentage correcties bruto bedrag van de bruto omzet per locatie", "en": "Percentage corrections gross amount of the gross revenue per branch", "de":"Anteil der Korrekturen Brutto am Bruttoumsatz pro Branch"},
    "i-corrections_discounts": {"nl": "Verhouding correcties bruto en kortingen bruto", "en": "Ratio corrections gross and discounts gross", "de":"Ratio Korrekturen Brutto und Rabatte Brutto"},
    "i-corrections_net_branches": {"nl": "Correcties netto bedrag per locatie", "en": "Corrections net amount per branch", "de":"Korrekturen Nettobetrag pro Branch"},
    "i-revenue_same_time": {"nl": "Netto omzet tot huidige tijd (beschikbaar voor de afgelopen 90 dagen)", "en": "Net revenue to current time (available for the past 90 days)", "de":"Umsatz netto zum aktuellen Zeitpunkt (verfügbar für die letzten 90 Tage)"},
    "i-revenue_same_hour": {"nl": "Netto omzet t/m huidige uur", "en": "Net revenue up to current hour", "de":"Umsatz netto zum aktuellen Stunde"},
    "i-payment_types": {"nl": "Betaalmethoden", "en": "Payment types", "de":"Bezahlmöglichkeiten"},
    "i-hr_absence": {"nl": "Verzuimpercentage = ziekte uren / gewerkte uren", "en": "Absence rate = sicknesshours divided by the worked hours", "de":"Abwesenheitsrate = Krankheitsstunden dividiert durch gearbeitete Stunden"},
    "i-hr_emp_n_sick": {"nl": "Aantal ziekmeldingen per medewerker", "en": "Number of sick leave reports per employee", "de":"Anzahl der Krankmeldungen pro Mitarbeiter"},
    "i-hr_emp_sickness": {"nl": "Ziekte uren per medewerker", "en": "Sicknesshours per employee", "de":"Krankheitsstunden pro Mitarbeiter"},
    "i-hr_hours_ratio": {"nl": "Verdeling van de gewerkte uren voor vaste tov flex medewerkers", "en": "Distribution of worked hours for permanent versus flexible employees", "de":"Verteilung der geleisteten Arbeitsstunden zwischen festangestellten und flexiblen Mitarbeitern"},
    "i-hr_n_sick": {"nl": "Aantal ziekmeldingen", "en": "Number of sick leave reports", "de":"Anzahl der Krankmeldungen"},
    "i-hr_perm_emp_sick": {"nl": "Ziekte uren per vaste medewerker", "en": "Sicknesshours per permanent employee", "de":"Krankheitsstunden pro Festangestellte Mitarbeiter"},
    "i-hr_permanent_absence": {"nl": "Verzuimpercentage = ziekte uren / gewerkte uren voor vaste medewerkers", "en": "Absence rate = sicknesshours divided by the worked hours for permanent employee", "de":"Abwesenheitsrate = Krankheitsstunden dividiert durch gearbeitete Stunden für Festangestellte Mitarbeitern"},
    "i-hr_permanent_combi_absence": {"nl": "Verzuimpercentage = ziekte uren / totale uren voor vaste medewerkers", "en": "Absence rate = sicknesshours divided by the total hours for permanent employee", "de":"Abwesenheitsrate = Krankheitsstunden dividiert durch Gesamtstunden für Festangestellte Mitarbeitern"},
    "i-hr_permanent_combi_hours": {"nl": "Totale uren vaste medewerkers = gewerkte + ziekte + verlof uren", "en": "Total hours permanent employee = worked + sickness + free hours", "de":"Gesamtstunden Festangestellte Mitarbeiter = gearbeitet + Krankheits + Freie Stunden"},
    "i-hr_permanent_hours": {"nl": "Gewerkte uren vaste medewerkers", "en": "Worked hours permanent employee", "de":"Gearbeitete Stunden Festangestellte Mitarbeiter"},
    "i-hr_permanent_sick": {"nl": "Ziekte uren vaste medewerkers", "en": "Sicknesshours permanent employee", "de":"Krankheitsstunden Festangestellte Mitarbeiter"},
    "i-hr_sick": {"nl": "Ziekte uren", "en": "Sicknesshours", "de":"Krankheitsstunden"},
    "i-hr_perm_emp_n_sick": {"nl": "Aantal ziekmeldingen per vaste medewerker", "en": "Number of sick leave reports per permanent employee", "de":"Anzahl der Krankmeldungen pro Festangestellte Mitarbeiter"},
    "i-hr_perm_n_sick": {"nl": "Aantal ziekmeldingen van vaste medewerkers", "en": "Number of sick leave reports of permanent employees", "de":"Anzahl der Krankmeldungen von Festangestellte Mitarbeiter"},
    "i-hr_perm_category": {"nl": "Ziekte uren van vaste medewerkers per categorie", "en": "Sick hours of permanent employees per category", "de":"Krankheitsstunden von Festangestellte Mitarbeiter pro Kategorie"},

    "planned_hours": {"nl": "Geplande uren", "en": "Planned hours", "de":"Geplante Stunden"},
    "total_hours": {"nl": "Gewerkte uren", "en": "Worked hours", "de":"Gearbeitete Stunden"},
    
    "available-year": {"nl": "Beschikbaar per jaar", "en": "Available per year", "de":"Verfügbar pro Jahr"},
    "available-week": {"nl": "Beschikbaar per week", "en": "Available per week", "de":"Verfügbar pro Woche"},
    "available-all": {"nl": "Beschikbaar voor alle periodes", "en": "Available for all periods", "de":"Verfügbar für alle Perioden"},
    "added-first-save": {"nl": "Kunnen worden toegevoegd na de eerste keer opslaan", "en": "Can be added after the first save", "de":"Kann nach dem ersten Speichern hinzugefügt werden"},
}

function Translation({children}) {
    let { lng, clientshort } = useContext(LanguageContext);
    let y = _.get(translations, `${children}.${lng}`);
    
    if (clientshort && children === 'c-dbs') y = `${clientshort.toUpperCase()} ${_.get(translations, `dbs.${lng}`)}`;
    if (clientshort && children === 'manage-c-dbs') y = y.replace("XX",clientshort.toUpperCase());
    if (clientshort === 'rce' && children === 'manage-chains') y = _.get(translations, `manage-formulas.${lng}`);
    if (clientshort === 'rce' && children === 'chains') y = _.get(translations, `formulas.${lng}`);
    if (clientshort === 'rce' && children === 'chain') y = _.get(translations, `formula.${lng}`);

    if (y) return y;
    return children || '';
}

export function t(key,lng=null) {
    var browserlng = navigator.language || navigator.userLanguage || 'nl';
    if (!lng) lng = browserlng.split('-')[0];

    let key1 = `${key}`.toLowerCase();
    let y = _.get(translations, `${key1}.${lng}`);
    if (y) return y;

    return key;
}

export default Translation;

// RETURNS COMPONENT
// import Translation from '../services/translationService';
// <Translation>login</Translation>
// <Translation>{label}</Translation>

// RETURNS STRING
// import { t } from '../../services/translationService';
// import LanguageContext from '../../context/languageContext';
// let { lng } = useContext(LanguageContext);
// ${t('search',lng)}`
