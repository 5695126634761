import React, { useContext } from 'react';
import { t } from '../../services/translationService';
import LanguageContext from '../../context/languageContext';

const InputPlaceholder = ({ name, label, error, ...rest }) => {
    let { lng } = useContext(LanguageContext);
    return ( 
        <div className="mb-3">
            <input {...rest} name={name} id={name} placeholder={t(label,lng)} className="form-control inputfloat-dp btn-no-outline" />
            {error && <p className="p-login">{error}</p>}
        </div>
     );
}
 
export default InputPlaceholder;
